import React, { useState, FC, useContext, useEffect, useMemo, useRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography, WithStyles } from "@material-ui/core"
import { translate } from "utils/i18n";
import { renderConfirmDUSTI } from "containers/DUTI/utils";
import { LiteralsContext } from "containers/shared/literals";
import CheckBox from "./DUTI/check-box";
import { /*IDocumentoAtib,*/ TDocSource } from "gateways/documentos.interface";
import { IDocumentoAtib, INotario } from "gateways/duti.interface";


import { TTipoDoc } from "gateways/duti.interface";
import { TTipoModelo } from "containers/DUTI/steps/types";
import { TModeloDocSource } from "gateways/model.new.interface";
import AttachFileComponent from "./attach-file-component";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import { DocumentosGateway } from "gateways/documentos.gateway";
import DUTIGateway from "gateways/duti.gateway";
import ModelGateway from "gateways/model.new.gateway";
import IoC from "contexts/ioc.context";
import { AlertsContext } from "contexts/alerts.context";
import InputSearch from "./DUTI/input-search";
import { DocumentsUtils } from "utils/documents";
import usePage from "hooks/page.hook";
import Icon from "@mdi/react";
import { Icon as Icon2 } from 'atomic/atoms/icon/icon';
import { mdiEyeCircleOutline, mdiTrashCan } from "@mdi/js";
import { BLUE } from "containers/shared/colors";
import FullLoading from "./full-loading";
import GenericInputDate from "./generic-input-date";
import GenericTypedInputNumber from "./generic-input-number/typed-input-number";
import GenericInputText from "./generic-input-text";
import duti_styles, { duti_colors } from "containers/DUTI/styles";
import { validateNumProtocolo } from "containers/DUTI/steps/DatosTransmisionInfoTransmision";
import GenericInputSelect, { TSelect } from "./generic-input-select";
import { ContenidosGateway } from "gateways/contenido.gateway";

const useStyles = makeStyles(() => ({
    row: {
        display:'flex',
        flexDirection:'row',
        flexWrap: 'wrap',
        alignItems:'center'
    },
    input580: {
        width: 580
    },
    btnContainer: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-end',
        flexGrow:1
    }
}));
const useStylesDuti = makeStyles(duti_styles);

interface IFieldsInfo {
    fecha: Date | undefined;
    anyo: number| undefined;
    numeroProtocolo: string |undefined;
    nifNotario: string | undefined;
    notario: string | undefined;
    //nrd: string | undefined;
    //idDocEscritura: string
}
type IFieldsErr = Record<keyof IFieldsInfo, boolean>
const EMPTY_FIELDS:IFieldsInfo={
    fecha: undefined,
    anyo: undefined,
    numeroProtocolo: undefined,
    nifNotario: undefined,
    notario: undefined
}
const EMPTY_ERR_FIELDS:IFieldsErr={
    fecha: false,
    anyo: false,
    numeroProtocolo: false,
    nifNotario: false,
    notario: false
}

interface IProps {
    open: boolean;
    buttonAdjuntar: {
        componentIG: string;
        term: string
    }
    // Props AttachFileComponent
    docs: IDocumentoAtib[]
    saveDocsAttached: (docs: IDocumentoAtib[]) => void
    docSource: TDocSource | TModeloDocSource | 'abono-nacimientos' |'duti';
    // if source -> TModeloDocSource
    idReferencia?: string,
    idTipoApartado?:string,
    labelType?: 'default' |'text'
    // if source -> duti
    idDeclaracion?: number;
    tipoDocDUTI?: TTipoDoc;
    tipoModeloDUTI?: TTipoModelo;

    onClose: () => void;
    allowDownload?:boolean;
    allowDelete?:boolean
}

const ModalAdjuntarEscrPublica : FC<IProps>= ({
    open, buttonAdjuntar, docs,docSource,idDeclaracion,idReferencia,idTipoApartado,tipoDocDUTI,tipoModeloDUTI, saveDocsAttached, onClose,
    allowDelete = true, allowDownload=true
}) => {
    const terms = useContext(LiteralsContext);
    const classes = useStyles();
    const classesDuti = useStylesDuti();

    const [pageState, ] = usePage();

    const ioc = useContext(IoC);
    const documentGateway = useMemo(() =>ioc.get(DocumentosGateway) as DocumentosGateway, [ioc]);
    const modelGateway = useMemo(() =>ioc.get(ModelGateway) as ModelGateway, [ioc]);
    const abonoNacimientoGateway = useMemo(() =>ioc.get(AbonoNacimientoGateway) as AbonoNacimientoGateway, [ioc]);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const contenidosGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);
    
    const [,alertsDispatch] = useContext(AlertsContext);
    const componentMounted = useRef(true)

    // Doc adjuntado por el usuario
    const [localDocs, setLocalDocs] = useState<{docs: IDocumentoAtib[], tipo:'adj'|'nrd'|'not'|undefined}>({docs:[], tipo:undefined})
    const [localNRD, setLocalNRD]= useState<string|undefined>(undefined)
    const [localFields, setLocalFields] = useState< IFieldsInfo>(EMPTY_FIELDS)
    const [errors, setErrors] = useState<IFieldsErr>(EMPTY_ERR_FIELDS)
    const [notarios, setNotarios] = useState<TSelect[]>([])
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState('')


    const showDoc = async( id: string) => {
        try {
            setLoading(true)
            DocumentsUtils.downloadDoc(id);
            setLoading(false)
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global', 'can_not_show_attached_doc', terms),
                    variant: 'error',
                }
            });
        }finally {
            setLoading(false);
        }     
    }

    const searchByNRD = async () => {
        try {
            if (localNRD && localNRD !== "") {
                setLoading(true);
                const result = await modelGateway.getEscrituraPublicaByNRD(localNRD);
                if(!result.escritura){
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Plusvalias', 'search_by_nrd_not_found', terms),
                            variant: 'error',
                        }
                    });
                }
                if (result.info && result.escritura) {
                    setLocalDocs({docs: [{...result.escritura, NRD: localNRD}], tipo: 'nrd'});
                    setLocalNRD(undefined)
                } else {
                    setLocalDocs({docs: [], tipo: undefined});
                }
            } else {
                setLocalDocs({docs: [], tipo: undefined});
            }
        } catch (error) {
            //setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'search_by_nrd_err', terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
        }
    }

    const searchByFieldEscrPub = async() => {
        try {
            setLoading(true)
            if(Object.values(errors).includes(true)){
                setLocalDocs(c=> ({...c, docs: [], tipo: undefined}))
                return
            }
            const escritura = docSource === 'sede-plusvalias' ?
                    await dutiGateway.getEscrituraPublicaNoAuth(
                    localFields.fecha, 
                    localFields.anyo,
                    localFields.numeroProtocolo, 
                    localFields.nifNotario,
                ): await dutiGateway.getEscrituraPublica(
                    localFields.fecha, 
                    localFields.anyo,
                    localFields.numeroProtocolo, 
                    localFields.nifNotario,
                )
      
            if(escritura && escritura.IdDocumento){
                setLocalDocs(c=> ({...c, docs: [escritura], tipo: 'not'}))
                setLocalFields(EMPTY_FIELDS)
            } else{
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI', 'search_by_dtNotarial_not_found', terms),
                        variant: 'error',
                    }
                });
            }
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', (error as Error).message, terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false)
        }
    }

    const handleAttachEscrByNRD = async (doc: IDocumentoAtib) => {
        try {
            // relacionar escritura 
            let done = false
            if(docSource ==='sede-plusvalias'){
                if(idReferencia && idTipoApartado){
                    // sede-plusvalia => insertModeloDocumento
                    done = await modelGateway.asociarModeloDocuemntos(idReferencia, idTipoApartado, doc.IdDocumento)
                } 
            } else if(docSource ==='duti'){
                if(idDeclaracion && tipoDocDUTI){
                    // duti => adjuntarDocumento
                    done = await dutiGateway.asociarDocumento(idDeclaracion, tipoDocDUTI,doc.IdDocumento, doc.NRD && doc.NRD !== '' ? doc.NRD: null)
                }
            } else {
                done = true
            }

            if(!done){ throw new Error('')}

            return done
        } catch (err) { 
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'error_asociar_escritura', terms),
                    variant: 'error',
                }
            });
            return false
        } finally {
            setLoading(false);
        }
    }

    const handleSave = async () => {
        if(localDocs.tipo && localDocs.docs.length>0){
            let allDone = false
            if(localDocs.tipo === 'nrd' || localDocs.tipo === 'not'){
                allDone = await handleAttachEscrByNRD(localDocs.docs[0])
            } 
            if(localDocs.tipo === 'adj'){ 
                allDone = true
            }
            if(allDone){
                saveDocsAttached(localDocs.docs)
                setLocalDocs(curr => ({...curr, docs:[], tipo: undefined}))
                setLocalNRD(undefined);
                onClose();
            }
            
        } else {
            saveDocsAttached([]);
            setLocalNRD(undefined);
            setLocalDocs({docs:[],tipo:undefined});
            onClose();
        }
    }

    const onDelete = async( id: string ) => {
        try {
            //console.log('onDelete modalEscrPubl ', id)
            setLoading(true)
            let result= false
            if(docSource === 'sede-plusvalias' && idReferencia && idTipoApartado){
                result= await modelGateway.deleteDocModelo(idReferencia, id, idTipoApartado);
            } else if (docSource === 'abono-nacimientos'){
                result= await abonoNacimientoGateway.deleteDoc(id);
            } else if(docSource === 'duti' && idDeclaracion){
                result= await dutiGateway.eliminarDocumento(idDeclaracion, id)
            } else {
                result= await documentGateway.deleteDocAtib(id)
            }
        
            if(!result){ throw new Error('not-delete') }

            const newArr = docs.filter(d => d.IdDocumento !== id);
            saveDocsAttached(newArr);
            setLocalDocs(curr => ({...curr, docs:newArr, tipo: newArr.length>0 ? curr.tipo : undefined}))
            
           
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global', 'can_not_delete_doc', terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
        }
    }

    const onDeleteAsociacion = async( id: string ) => {
        try {
            // console.log('onDeleteAsociadion modalEscrPubl ', id)
            setLoading(true)
            let result= false
            if(docSource === 'sede-plusvalias' && idReferencia && idTipoApartado){
                result= await modelGateway.deleteModeloDocumento(idReferencia, id, idTipoApartado);
            /*} else if (docSource === 'abono-nacimientos'){
                result= await abonoNacimientoGateway.deleteDoc(id); */
            } else if(docSource === 'duti' && idDeclaracion){
                result= await dutiGateway.eliminarDocumento(idDeclaracion, id)
            } else {
                result= true
            }
        
            if(!result){ throw new Error('not-delete') }

            const newArr = docs.filter(d => d.IdDocumento !== id);
            saveDocsAttached(newArr);
            setLocalDocs(curr => ({...curr, docs:newArr, tipo: newArr.length>0 ? curr.tipo : undefined}))
           
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global', 'can_not_delete_doc', terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
        }
    }

    const handleClose = async () => {
        // si hay localDocs eliminar
        if(localDocs.docs.length>0){
            if( localDocs.tipo==='adj'){
                await onDelete( localDocs.docs[0].IdDocumento)
            }
            if((localDocs.tipo === 'nrd' && ['duti','sede-plusvalias'].includes(docSource)) ){
                await onDeleteAsociacion( localDocs.docs[0].IdDocumento)
            }
        }
        setLocalNRD(undefined);
        setLocalDocs({docs:[],tipo:undefined});
        onClose();
    }

    // console.log('localDocs', localDocs)
    useEffect(()=> {
        (async() => {
            const notarios = await dutiGateway.searchNotarios("")
            const text = await contenidosGateway.getContent('info_modal_idEscrituraAnterior',{})
            if(componentMounted.current){
                setLocalDocs({docs:[], tipo:undefined}); 
                setLocalNRD(undefined);
                const opt = notarios.map(n => ({id:n.nifNotario, nombre: n.notario}))
                const opt_alfabetico = opt.sort((a, b) => a.nombre.localeCompare(b.nombre));
                //console.log('opt_alfabetico ', opt_alfabetico)
                setNotarios(opt_alfabetico);
                setText(text[0].contenido);
            }
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
        
    },[])

    return (
        <Dialog open={open} maxWidth='lg'>
            <DialogTitle>
                <Typography style={{fontSize: 25, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>
                    {translate("DUTI", "idEscrituraAnterior", terms)}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <FullLoading loading={loading}/>
                
                <div dangerouslySetInnerHTML={{ __html:  text?? 'Oops ! Lost MSG_DUSTI'}} style={{ textAlign: "justify", textJustify: "inter-word", fontSize: 16 }}></div>
                               
                <Grid container direction="row" spacing={1} style={{flexGrow:1}}>
                    <Grid item xs={6}>
                        <AttachFileComponent
                            key={'idDocAnteriorAdquisicion'}
                            label={translate(buttonAdjuntar.componentIG,buttonAdjuntar.term, terms)}
                            docs={localDocs.tipo == 'adj' ? localDocs.docs: []}
                            saveDocsAttached={(docs: IDocumentoAtib[]) =>{ 
                                setLocalDocs({docs: docs, tipo:'adj'})
                                setLocalNRD(undefined);
                                if(Object.values(localFields).map(v =>  v!==undefined && v!== '').includes(true) || Object.values(errors).includes(true)){
                                    setLocalFields(EMPTY_FIELDS);
                                    setErrors(EMPTY_ERR_FIELDS);
                                }
                            }}
                            multiple={false}
                            allowDelete = {allowDelete}
                            allowDownload = {allowDownload}
                            docSource={docSource}
                            idDeclaracion={idDeclaracion}
                            tipoDocDUTI={tipoDocDUTI}
                            tipoModeloDUTI={tipoModeloDUTI}
                            idTipoApartado={idTipoApartado}
                            idReferencia={idReferencia}
                            disabled={(localDocs.docs.length>0)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography >{translate('DUTI', 'text_modal_nrd', terms)}</Typography>
                        <div style={{marginTop: 10}}>
                            {/** Buscar por NRD */}
                            <InputSearch
                                fieldTerm='search_nrd'
                                value={localNRD}
                                disabled={!('duti'=== docSource || 'sede-plusvalias'=== docSource) || (localDocs.docs.length>0)}
                                forceUpperCase={true}
                                className={classesDuti.input360}
                                onChangeValue={(v:string|undefined, err: boolean) => {
                                    setLocalNRD(v);
                                    if(Object.values(localFields).map(v =>  v!==undefined && v!== '').includes(true) || Object.values(errors).includes(true)){
                                        setLocalFields(EMPTY_FIELDS);
                                        setErrors(EMPTY_ERR_FIELDS);
                                    }
                                    
                                }}                        
                                onSearch={searchByNRD } //() => searchByNRD(localNRD)}
                            />
                            {/**Buscar por campos escritura */}
                            <div className={classesDuti.borderPlusval}> 
                                <div className={classesDuti.divTextBorderPlusval}>
                                    <Typography className={classesDuti.textBorderPlusval}>{translate('DUTI','datos_notariales',terms)}</Typography>
                                </div> 
                                <div className={classes.row}>
                                    <GenericInputDate 
                                        componentIG='DUTI'
                                        labelTerm="fecha"
                                        disabled={!('duti'=== docSource || 'sede-plusvalias'=== docSource) || localDocs.docs.length>0}
                                        value={localFields?.fecha}
                                        onChangeValue={(v: Date| undefined, err:boolean) =>{ 
                                            setLocalFields(curr =>({...curr, fecha: v}))
                                            setErrors(curr => ({...curr, fecha: err}))
                                            localNRD && setLocalNRD(undefined);
                                        }}
                                        maxDate={ new Date() }
                                        required={true}
                                        error={errors.fecha}
                                        //className={classesDuti.input360}
                                    />
                                    <GenericTypedInputNumber
                                        type="year"
                                        componentIG='DUTI'
                                        labelTerm={'anyo'}
                                        disabled={!('duti'=== docSource || 'sede-plusvalias'=== docSource) || localDocs.docs.length>0}
                                        value={localFields.anyo}
                                        onChangeValue={(v:number|undefined, err:boolean) =>{ 
                                            setLocalFields(curr => ({...curr, anyo: v}))
                                            setErrors(curr => ({...curr, anyo: err}))
                                            localNRD && setLocalNRD(undefined);
                                        }}
                                        error={errors.anyo}
                                        required
                                        className={classesDuti.input100}
                                    />
                                    <GenericInputText
                                        componentIG='DUTI'
                                        labelTerm={'numeroProtocolo'}
                                        value={localFields.numeroProtocolo}
                                        disabled={!('duti'=== docSource || 'sede-plusvalias'=== docSource) || localDocs.docs.length>0}
                                        onChangeValue={(v:string|undefined, err: boolean) =>{ 
                                            setLocalFields(curr => ({...curr, numeroProtocolo: v}))
                                            setErrors(curr => ({...curr, numeroProtocolo: err}))
                                            localNRD && setLocalNRD(undefined);
                                        }}
                                        required={true}
                                        error={errors.numeroProtocolo}
                                        extraValidation={(v: string) => validateNumProtocolo(v,terms)}
                                        className={classesDuti.input200}
                                    />
                                    <GenericInputSelect
                                        componentIG='DUTI'
                                        labelTerm={'notario'}
                                        value={notarios.find(n => n.id === localFields.nifNotario)}
                                        disabled={!('duti'=== docSource || 'sede-plusvalias'=== docSource) || localDocs.docs.length>0}
                                        options={notarios}
                                        onChangeValue={(v:TSelect|undefined, err: boolean) =>{ 
                                            setLocalFields(curr => ({...curr, notario: v?.nombre, nifNotario: v?.id}))
                                            setErrors(curr => ({...curr, notario: err}))
                                            localNRD && setLocalNRD(undefined);
                                        }}
                                        required={true}
                                        error={errors.notario}
                                        className={classes.input580}
                                    />
                                </div>

                                <div className={classes.btnContainer}>
                                    <Button 
                                        variant="contained"
                                        color="primary"
                                        disabled={!('duti'=== docSource || 'sede-plusvalias'=== docSource) || localDocs.docs.length>0}
                                        onClick={searchByFieldEscrPub}
                                        startIcon={<Icon2 name="search"  size={1}  color={duti_colors.white}/>}
                                    >
                                        {translate('DUTI', 'buscar_por_dt_notariales', terms)}
                                    </Button>
                                </div>
                            </div>

                            { (localDocs.tipo === 'nrd' || localDocs.tipo === 'not') && localDocs.docs.length> 0 &&
                            <div style={{display:'flex', flexDirection: 'column', alignItems:'flex-start'}}>
                                <Typography variant="body1" style={{marginRight: 10, marginLeft: 10,marginTop:5}}>
                                    {translate('DUTI', 'escritura_found', terms)} {(pageState.jwp !== null) && allowDownload ? '. '+translate('DUTI', 'escritura_can_download', terms) : ''}:
                                </Typography>
                                <div style={{display:'flex', flexDirection: 'row', alignItems:'flex-start'}}>
                                    <Typography variant="body1" style={{marginRight: 10, marginLeft: 10,marginTop:5}}>{localDocs.docs[0].NombreFichero}</Typography>
                                    {/**Permitimos descargar siempre que estemos logueados y allowDownload lo permita */}
                                    { (pageState.jwp !== null) && allowDownload && localDocs && localDocs.tipo !== 'not' &&
                                        <IconButton 
                                            onClick={() => { 
                                                showDoc(localDocs.docs[0].IdDocumento)
                                            }} 
                                            style={{padding:5, marginRight: 5, marginLeft:5}}
                                        >
                                            <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} />
                                        </IconButton>
                                    }
                                    {allowDelete && 
                                        <IconButton onClick={ () => onDeleteAsociacion(localDocs.docs[0].IdDocumento) } style={{padding:5, marginRight: 5, marginLeft:5}}>
                                            <Icon path={mdiTrashCan} size={1} color='red'  />
                                        </IconButton>
                                    }
                                </div>

                            </div>
                            }

                        </div>

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions >
                <Grid container direction='row' alignItems="center" justify="space-between" style={{paddingRight: 20, paddingLeft: 20}}>
                    <Grid item>
                        <Button variant="contained" style={{marginRight: 10}} 
                            onClick={handleClose}
                        >
                            {translate('Global','Cancelar', terms)}
                        </Button>
                        <Button variant="contained"  color="primary" onClick={handleSave}>
                            {translate('Global','Continuar', terms)}
                        </Button>
                    </Grid>
                </Grid>
                
            </DialogActions>
        </Dialog>
    )
}

export default ModalAdjuntarEscrPublica;