import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import dayjs from "dayjs";
import { Icon } from '@mdi/react';
import { mdiCalendarMonth, mdiCreditCard, mdiFileDocument, mdiPrinter } from '@mdi/js';
import { mdiClockOutline } from '@mdi/js';
import { LiteralsContext } from 'containers/shared/literals';
import IoC from 'contexts/ioc.context';
import DUTIGateway from 'gateways/duti.gateway';
import { IDeclaracionJustificantes, IModeloDeclaracion, TTipoDoc } from 'gateways/duti.interface';
import Term from 'components/term';
import dustiOK from '../../../resources/dusti/DustiOK.png';
import dustiPendi from '../../../resources/dusti/DustiPendi.png';
import dustiCAD from '../../../resources/dusti/DustiCadu.png';
import AEATLogo from '../../../resources/dusti/AEAT_listado2.jpg';
import CatastroLogo from '../../../resources/dusti/Catastro_listado.jpg';
import AtibLogo from '../../../resources/dusti/ATIB_listado.jpg';


import { AlertsContext } from 'contexts/alerts.context';
import { download } from 'utils/download';
import { translate } from 'utils/i18n';
import FullLoading from 'components/full-loading';
import duti_styles from '../styles';
import LabelWithInfo from 'components/DUTI/label-with-info';
import { BLUE } from 'containers/shared/colors';
import moment from 'moment';
import { idDeclaracionAsRefDusti } from '../utils';
import { CustomError } from 'utils/custom.error';
import usePage from 'hooks/page.hook';

const useStyles = makeStyles(duti_styles);

interface IProps {
    idDeclaracion: number;
    setShouldBlock: (v: boolean) => void;
}

const JustificantesDusti: FC<IProps> = ({ idDeclaracion, setShouldBlock }) => {
    const [loading, setLoading] = useState(false);
    const [declaracion, setDeclaracion] = useState<IDeclaracionJustificantes>();
    const [modelo600, setModelo600] = useState<IModeloDeclaracion>();
    const [modelo080, setModelo080] = useState<IModeloDeclaracion>();
    const [modelos081, setModelos081] = useState<IModeloDeclaracion[]>();
    const [modelo082, setModelo082] = useState<IModeloDeclaracion>();

    const [aeat, setAeat] = useState(false);
    const [aeatErr, setAeatErr] = useState<{error:boolean, msg?: string}>({error:false, msg: undefined});
    const [tasas, setTasas] = useState(false);
    const [tasasErr, setTasasErr] = useState<{error:boolean, msg?: string}>({error:false, msg: undefined});
    const [ibi, setIbi] = useState(false);
    const [ibiErr, setIbiErr] = useState<{error:boolean, msg?: string}>({error:false, msg: undefined});

    const [datosPresentacion, setDatosPresentacion] = useState<{ idExpediente: number, estadoExpediente: string}>();
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    
    // Global states
    const theme = useTheme();
    const classes = useStyles(theme);
    const terms = useContext(LiteralsContext);

    // Services
    const ioc = useContext(IoC);
    const gateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);

    // Events
    const handlePrint = async (tipoDocumento: TTipoDoc) => {
        setLoading(true);

        try {
            const documento = await gateway.print(idDeclaracion, tipoDocumento);

            download(documento.blob, alertsDispatch,
                translate('DUTI', 'No ha sido posible descargar el justificante', terms),
                translate('DUTI', 'Imprimir', terms), documento.fileName);
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_dusti', terms),
                        variant: "error"
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handlePayModelo = async (idModelo: string) => {
        setLoading(true);

        try {
            const rootHost = window.location.pathname.indexOf('/cva')> -1 
                ? window.location.origin + "/cva"
                :window.location.origin;
            const urlPago = await gateway.getUrlPagoModelo(idModelo);

            if (urlPago) {
                window.open(`${rootHost}${urlPago}`, '_blank');
            }
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_dusti', terms),
                        variant: "error"
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handlePayModelos = async () => {
        setLoading(true);

        try {
            const rootHost = window.location.pathname.indexOf('/cva')> -1 
                ? window.location.origin + "/cva"
                :window.location.origin;
            
            const urlPago = await gateway.getUrlPagoModelos(idDeclaracion);

            if (urlPago) {
                window.open(`${rootHost}${urlPago}`, '_blank');
            }
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_dusti', terms),
                        variant: "error"
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handlePresentar = async (idExpediente: number) => {
        const rootHost = window.location.pathname.indexOf('/cva')> -1 
                ? window.location.origin + "/cva"
                :window.location.origin;
        const urlPresentacion = `${rootHost}/tributos/presentaciones/expediente/${idExpediente}`;

        if (urlPresentacion) {
            window.open(urlPresentacion, '_blank');
        }
    };

    const handlePrintAll = async () => {
        try {
            const documento = await gateway.printAll(idDeclaracion);

            download(documento.blob, alertsDispatch,
                translate('DUTI', 'No ha sido posible descargar los justificantes', terms),
                translate('DUTI', 'Imprimir', terms), documento.fileName);
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_dusti', terms),
                        variant: "error"
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const renderText = (term: string, bold:boolean, color?:string, align?: 'center'|'right'|'left') => {
        return (
            <Typography className={bold ? classes.boldText: undefined} 
                style={{alignSelf: align ?? 'center', flex:1, color: color ?? undefined }}
            >
                {translate("DUTI", term, terms)}
            </Typography>
        )
    }
    const renderAsText = (value:string, bold:boolean) => {
        return (
            <Typography className={ bold ? classes.boldText: undefined} >
                {value}
            </Typography>
        )
    }

    // Effects
    useEffect(() => {
        (async() => {
            setLoading(true);

            try {
                const declaracionJustificantes = await gateway.getDeclaracionJustificantes(idDeclaracion);
                if (declaracionJustificantes) {
                    setDeclaracion(declaracionJustificantes);
                    setModelo600(declaracionJustificantes.modelos.find(m => m.idTipoModelo === "600"));
                    setModelo080(declaracionJustificantes.modelos.find(m => m.idTipoModelo === "080"));
                    setModelos081(declaracionJustificantes.modelos.filter(m => m.idTipoModelo === "081"));
                    setModelo082(declaracionJustificantes.modelos.find(m => m.idTipoModelo === "082"));
                    setAeat(declaracionJustificantes.aeat.ok)
                    setAeatErr({error:declaracionJustificantes.aeat.err, msg: declaracionJustificantes.aeat.msgErr})
                    setIbi(declaracionJustificantes.ibi.ok)
                    setIbiErr({error:declaracionJustificantes.ibi.err, msg: declaracionJustificantes.ibi.msgErr})
                    setTasas(declaracionJustificantes.tasas.ok)
                    setTasasErr({error:declaracionJustificantes.tasas.err, msg: declaracionJustificantes.tasas.msgErr})
                    if (declaracionJustificantes.estadoExpediente) {
                        setDatosPresentacion({
                            estadoExpediente: declaracionJustificantes.estadoExpediente,
                            idExpediente: declaracionJustificantes.idExpediente,
                        });
                    }
                }
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [gateway, idDeclaracion]);

    return (
        <Grid container direction='column' /* style={{padding:20}}*/>
            <FullLoading loading={ loading } animation={{type:'intricom',name:'DUSTI'}}  />
            <div style={{
                borderBottom: 'solid', borderBottomWidth:1, borderColor:'#d3d3d3',
                paddingRight: 16,
                paddingLeft: 16,
                paddingTop: 12,
                paddingBottom: 10,


            }}>
                <LabelWithInfo fieldTerm={"Resumen"}  moreInfo={{template: 'mas_info_resumen_title',size:'xs'}}
                    style={{
                        fontSize: '1.5rem',
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        lineHeight: 1.334,
                        letterSpacing: '0em',
                    }} 
                />

            </div>
            <Grid item container direction='row' style={{padding:20}} >
                {/**Info DUSTI--------------------------------------------------------------------- */}
                <Grid item xs={12} md={12} lg={4} container alignContent='flex-start'>
                    <Grid item xs={6} md={7} lg={12}>
                        <div className={classes.column}>
                            <Grid container direction='row' style={{marginBottom: 10}}>
                                <Grid item xs={12} md={12} lg={4}>
                                    {renderText('idDeclaracion', false)}
                                </Grid>
                                <Grid item xs={12} md={12} lg={8}>
                                    {renderAsText(idDeclaracionAsRefDusti(idDeclaracion) ?? "", true)}
                                </Grid>
                            </Grid>


                            <Grid container direction='row' style={{marginBottom: 10}}>
                                <Grid item xs={12} md={12} lg={4}>
                                    {renderText('fechaAlta', false)}
                                </Grid>
                                <Grid item xs={7} md={5} lg={4} container direction='row'>
                                    <Icon path={mdiCalendarMonth}
                                        size={'25px'}
                                        color={"grey"}
                                        style={{marginRight:5}}
                                    />
                                    {renderAsText(
                                        declaracion?.fechaAlta ? dayjs(declaracion?.fechaAlta).format("DD/MM/YYYY"): "" 
                                        , true)
                                    }
                                </Grid>
                                <Grid item xs={5} md={7} lg={4} container direction='row'>
                                    <Icon path={mdiClockOutline}
                                        size={'25px'}
                                        color={"grey"}
                                        style={{marginRight:5, marginLeft: 20}}
                                    />                                   
                                    {renderAsText( 
                                        declaracion?.fechaAlta ? moment.utc(declaracion.fechaAlta).format("HH:mm") : "" 
                                        , true) 
                                    }
                                </Grid>
                            </Grid>

                            <Grid container direction='row' style={{marginBottom: 50}} >
                                <Grid item xs={12} md={12} lg={4} >
                                    {renderText( 
                                        declaracion?.estadoDeclaracion === "CAD" ? 'fechaCaducidad' : 'fechaFinalizacion'
                                        ,false
                                    )}
                                </Grid>
                                <Grid item xs={7} md={5} lg={4} container direction='row'>
                                    <Icon path={mdiCalendarMonth}
                                        size={'25px'}
                                        color={"grey"}
                                        style={{marginRight:5}}
                                    />
                                    {declaracion?.estadoDeclaracion === "CAD" ?
                                        renderAsText(
                                            declaracion?.fechaCaducidad 
                                            ? dayjs(declaracion.fechaCaducidad).format("DD/MM/YYYY") : " - " 
                                            , true)
                                        :
                                        renderAsText(
                                            declaracion?.fechaFinalizacion 
                                            ? dayjs(declaracion.fechaFinalizacion).format("DD/MM/YYYY") : " - " 
                                            , true)
                                    }
                                </Grid>
                                <Grid item xs={5} md={7} lg={4} container direction='row'>
                                    <Icon path={mdiClockOutline}
                                        size={'25px'}
                                        color={"grey"}
                                        style={{marginRight:5, marginLeft: 20}}
                                    />                                   
                                    {renderAsText( 
                                        declaracion?.fechaFinalizacion 
                                            ? moment.utc(declaracion.fechaFinalizacion).format("HH:mm")  : " - " 
                                        , true) 
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={5} lg={12} container alignContent='flex-start' style={{paddingRight: 20, paddingTop:10, paddingBottom:10}}>
                        <Grid xs={12} style={{marginBottom: 10}}>
                            {declaracion && declaracion.fechaFinalizacion 
                                    ? declaracion?.estadoDeclaracion === "CAD" 
                                        ? renderText("justificantes_resumen_caducado", true, "#ea1e1e" )
                                        : renderText("justificantes_resumen_finalizado", true, "#b8d5c6" )
                                    : renderText("justificantes_resumen_finalizado_pendiente", true, "#25b8ed" )
                            }
                        </Grid>
                        <Grid xs={12}>
                            {declaracion && declaracion.fechaFinalizacion 
                                ? declaracion?.estadoDeclaracion === "CAD"
                                    ? <img width={'100%'} height={'100%'} style={{objectFit:'scale-down'}} src={dustiCAD} alt='declaración CAD' />
                                    : <img width={'100%'} height={'100%'} style={{objectFit:'scale-down'}} src={dustiOK} alt='declaración OK'  />
                                : <img width={'100%'} height={'100%'} style={{objectFit:'scale-down'}} src={dustiPendi} alt='declaración Pendiente' />
                            }
                        </Grid>
                        

                       
                        
                    </Grid>

                </Grid>
                {/**Documentos  ------------------------------------------------------------------- */}
                <Grid item xs={12} md={12} lg={8} container direction='column' style={{paddingLeft: 15}}>
                    <Grid item>
                        <Typography 
                            style={{
                                fontSize: '1.5rem',
                                fontFamily: "Roboto",
                                fontWeight:'bolder',
                                lineHeight: 1.334,
                                letterSpacing: '0em',
                                paddingBottom: 10
                            }}
                        >
                            {translate("DUTI",'title_documentos_generados',terms)}
                        </Typography>
                    </Grid>
                    {/** Listado Documentos  ---- */}
                    {/** 1- ATIB   */}
                    <Grid item container className={classes.sectionJustif}> 
                        <Grid lg={3}>
                            <img className={classes.logoTramites} src={AtibLogo} alt = "logo-atib"/>
                        </Grid>
                        <Grid lg={9}>
                            <div style={{width: '100%'}}>
                                <Grid item className={classes.rowJustif}>
                                    {renderText("tramite_dusti_atib", false)}
                                    <div className={classes.btnContainer}>
                                        <Button 
                                            variant="outlined" color='primary' size="small"
                                            classes={{startIcon: classes.starIcon}}
                                            startIcon= { <Icon path={mdiPrinter} size={1} color={BLUE} /> }   
                                            onClick={() => handlePrint("Justificante_Dusti")}
                                        >
                                            <Term component="DUTI" text={"Imprimir"} />
                                        </Button>
                                    </div>
                                    
                                </Grid>
                                {datosPresentacion && 
                                    <Grid item className={classes.rowJustif} >
                                        { renderText("justificante_presentacion_expediente", false)}
                                        <div className={classes.btnContainer}>
                                            <Button 
                                                disabled={declaracion?.estadoDeclaracion === "CAD" && datosPresentacion.estadoExpediente !== "PRE"}
                                                variant={datosPresentacion.estadoExpediente === "PRE"  ? "outlined": "contained"} 
                                                color='primary' 
                                                size="small"
                                                classes={{startIcon: classes.starIcon}}
                                                startIcon= {
                                                    datosPresentacion.estadoExpediente === "PRE" 
                                                    ? <Icon path={mdiPrinter} size={1} color={BLUE} />
                                                    : <Icon path={mdiFileDocument} size={1} color={'#fff'} />  
                                                }   
                                                onClick={() => (
                                                    datosPresentacion.estadoExpediente === "PRE" 
                                                    ? handlePrint("Justificante_Presentacion_Exp")
                                                    : handlePresentar(datosPresentacion.idExpediente)
                                                )}
                                            >
                                                <Term component="DUTI" text={datosPresentacion.estadoExpediente === "PRE"  ? "Imprimir" : "Presentar"} />
                                            </Button>
                                        </div>
                                    </Grid>
                                }
                                { modelo600 &&
                                    <Grid item className={classes.rowJustif}>
                                        { renderText("M600_title", false)}
                                        <div className={classes.btnContainer}>
                                            <Button 
                                                disabled={declaracion?.estadoDeclaracion === "CAD" && modelo600.estado !== "FIN" }
                                                variant={modelo600.estado === "FIN"  ? "outlined": "contained"} color='primary' size="small"
                                                classes={{startIcon: classes.starIcon}}
                                                startIcon= { 
                                                    modelo600.estado === "FIN" 
                                                    ? <Icon path={mdiPrinter} size={1} color={BLUE} /> 
                                                    : <Icon path={ mdiCreditCard} size={1} color={'#fff'} /> 
                                                }   
                                                onClick={() => (
                                                modelo600.estado === "FIN" 
                                                    ? handlePrint("Modelo600")
                                                    : handlePayModelo(modelo600.idModeloATIB)    
                                                )}
                                            >
                                                <Term component="DUTI" text={ modelo600.estado === "FIN" ? "Imprimir": "Pagar"} />
                                            </Button>
                                        </div>
                                    </Grid>
                                }
                               
                            </div>
                        </Grid>
                    </Grid>
                    

                    {/**  AYUNTAMIENTO   */}
                    <Grid item container className={classes.sectionJustif}> 
                        <Grid lg={3}>
                            { declaracion && (modelo080 || modelos081 || modelo082 ||tasas) &&
                                <img className={classes.logoTramites} src={`https://www.atib.es/App_Themes/LogosMuni/${declaracion.codigoMunicipio}.png`} alt = "logo-muni"/>
                            }
                            
                        </Grid>
                        <Grid lg={9}>
                            <div style={{width: '100%'}}>
                                
                                 { modelo080 &&
                                    <Grid item className={classes.rowJustif}>
                                        { renderText("M080_title", false)}
                                        <div className={classes.btnContainer}>
                                            <Button 
                                                variant="outlined" color='primary' size="small"
                                                classes={{startIcon: classes.starIcon}}
                                                startIcon= { <Icon path={mdiPrinter} size={1} color={BLUE} /> } 
                                                onClick={() => handlePrint("Modelo080")}
                                            >
                                                <Term component="DUTI" text={"Imprimir"} />
                                            </Button>
                                        </div>
                                    </Grid>
                                }
                                { modelos081 && modelos081.length > 0 &&
                                    <Grid item className={classes.rowJustif}>
                                        { renderText("M081_title", false)}
                                        <div className={classes.btnContainer}>
                                            <Button disabled={ declaracion?.estadoDeclaracion === "CAD" && modelos081[0].estado !== "FIN" }
                                                variant={modelos081[0].estado === "FIN" ? "outlined": "contained"} 
                                                color='primary' size="small" 
                                                classes={{startIcon: classes.starIcon}}
                                                startIcon= { 
                                                    modelos081[0].estado === "FIN" 
                                                        ? <Icon path={mdiPrinter} size={1} color={BLUE} /> 
                                                        : <Icon path={ mdiCreditCard} size={1} color={'#fff'} /> 
                                                } 
                                                onClick={() => (
                                                    modelos081[0].estado === "FIN" 
                                                    ? handlePrint("Modelo081")
                                                    : handlePayModelos()
                                                )}
                                            >
                                                <Term component="DUTI" text={ modelos081[0].estado === "FIN" ? "Imprimir": "Pagar"} />
                                            </Button>
                                        </div>
                                    </Grid>
                                }
                                { modelo082 &&
                                    <Grid item className={classes.rowJustif}>
                                        { renderText("M082_title", false)}
                                        <div className={classes.btnContainer}>
                                            <Button 
                                                variant="outlined" color='primary' size="small"
                                                classes={{startIcon: classes.starIcon}}
                                                startIcon= { <Icon path={mdiPrinter} size={1} color={BLUE} /> } 
                                                onClick={() => handlePrint("Modelo082")}
                                            >
                                                <Term component="DUTI" text={"Imprimir"} />
                                            </Button>
                                        </div>
                                    </Grid>
                                }
                                {tasas &&
                                    <Grid item className={classes.rowJustif}>
                                        <div style={{display:'flex', flexDirection:'column', marginLeft: 10}}>
                                            {renderText("tramite_dusti_comunicacion_tasas_inmueble", false, undefined, 'left')}
                                            {tasasErr.error && renderText(`error_TASAS${tasasErr.msg ? '_'+tasasErr.msg : ''}`, false, 'red', 'left')}
                                        </div>
                                        {!tasasErr.error && 
                                            <div className={classes.btnContainer}>
                                                <Button 
                                                    variant="outlined" color='primary' size="small"
                                                    classes={{startIcon: classes.starIcon}}
                                                    startIcon= { <Icon path={mdiPrinter} size={1} color={BLUE} /> }                
                                                    onClick={() => handlePrint("Justificante_Alteracion_Tasas")}
                                                >
                                                    <Term component="DUTI" text={"Imprimir"} />
                                                </Button>
                                            </div>
                                        }
                                    </Grid>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    
                    
                    {/**  AEAT   */}
                    {aeat && 
                        <Grid item container className={classes.sectionJustif}> 
                            <Grid item lg={3}>
                                <img className={classes.logoTramites} src={AEATLogo} alt = "logo-aeat"/>
                            </Grid>
                            <Grid item lg={9}>
                                <div style={{width: '100%'}}>
                                    <Grid item className={classes.rowJustif}>
                                        <div style={{display:'flex', flexDirection:'column', marginLeft: 10}}>
                                            {renderText("tramite_dusti_comunicacion_AEAT", false, undefined, 'left')}
                                            {aeatErr.error && renderText(`error_AEAT${aeatErr.msg ? '_'+aeatErr.msg : ''}`, false, 'red', 'left')}
                                        </div>
                                        {!aeatErr.error && 
                                            <div className={classes.btnContainer}>
                                                <Button 
                                                    variant="outlined" color='primary' size="small"
                                                    classes={{startIcon: classes.starIcon}}
                                                    startIcon= { <Icon path={mdiPrinter} size={1} color={BLUE} /> }                
                                                    onClick={() => handlePrint("Justificante_Cambio_Domicilio")}
                                                >
                                                    <Term component="DUTI" text={"Imprimir"} />
                                                </Button>
                                            </div>
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {/**  CATASTRO   */}
                    { ibi &&
                        <Grid item container className={classes.sectionJustif} > 
                            <Grid lg={3}>
                                <img className={classes.logoTramites} src={CatastroLogo} alt= "logo-aeat"/>
                            </Grid>
                            <Grid lg={9}>
                                <div style={{width: '100%'}}>
                                    <Grid item className={classes.rowJustif}>
                                        <div style={{display:'flex', flexDirection:'column', marginLeft: 10}}>
                                            {renderText("tramite_dusti_comunicacion_catastro", false, undefined, 'left')}
                                            {ibiErr.error && renderText(`error_IBI${ibiErr.msg ? '_'+ibiErr.msg : ''}`, false, 'red', 'left')}
                                        </div>
                                        {!ibiErr.error && 
                                            <div className={classes.btnContainer}>
                                                <Button 
                                                    variant="outlined" color='primary' size="small"
                                                    classes={{startIcon: classes.starIcon}}
                                                    startIcon= { <Icon path={mdiPrinter} size={1} color={BLUE} /> }   
                                                    onClick={() => handlePrint("Justificante_Alteracion_Catastro")}
                                                >
                                                    <Term component="DUTI" text={"Imprimir"} />
                                                </Button>
                                            </div>
                                        }
                                        
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    }

                    <Grid item style={{marginTop: 20, alignSelf:'flex-end'}}>
                        <Button 
                            variant="outlined" color='primary' size="small"
                            classes={{startIcon: classes.starIcon}}
                            startIcon= { <Icon path={mdiPrinter} size={1} color={BLUE} /> }   
                            onClick={() => handlePrintAll()}
                        >
                            <Term component="DUTI" text={"IMPRIMIR TODOS"} />
                        </Button>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
        
    );
}

export default JustificantesDusti;
