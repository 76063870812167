import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";

import { TCalculo, TErrorsCalcPlusvalia, TOperacion, TOperacionErr } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";

import { cloneDeep } from "lodash";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";

import Modal from "components/modal";
import LabelWithInfo from "components/DUTI/label-with-info";
import FormCalculoPLV from "./formCalculoPLV";
import OperacionPLV from "./operacionPLV";
import CheckBox from "components/DUTI/check-box";
import { DocumentosGateway } from "gateways/documentos.gateway";
import ModelGateway from "gateways/model.new.gateway";
import IoC from "contexts/ioc.context";
import FullLoading from "components/full-loading";
import { AlertsContext } from "contexts/alerts.context";

type TErrors = Record<keyof Omit<TCalculo,'operaciones'>,boolean > & Record<keyof Pick<TCalculo,'operaciones'>,TOperacionErr[]>
const EMPTY_ERR: TErrors = {
    numOperaciones: false,
    porcentaje: false,
    edadUsufructuaria:false,
    tipoDerecho:false,
    operaciones: [] as TOperacionErr[],
    declaracionResponsable: false,
    idGestion: false,
    idGestionSujeto: false,
    modelo: false,
}

const EMPTY_OPERACION: TOperacion = {
    fechaAnteriorAdquisicion: undefined,
    porcentajeAnteriorAdquisicion: 0,
    importeAnteriorAdquisicion: undefined,
    idDocAnteriorAdquisicion: undefined
}
const EMPTY_OP_ERR: TOperacionErr = {
    fechaAnteriorAdquisicion: false,
    porcentajeAnteriorAdquisicion: false,
    importeAnteriorAdquisicion: false,
    idDocAnteriorAdquisicion: false
}
type TProps = {
    calculo: TCalculo;
    setCalculo: (v: TCalculo) => void;
    setSujetoInfoErr: (v: any) => void;    
    onCancel: () => void;    
    tipoModelo: "080" | "081" | "082";
    fechaTransmision: Date;
    idRef: string;
    apartado: string;
    infoVend: { nif:string, nomApe: string, titularidad: number };
    nifSujPasivo: string;
    hideImporte: boolean;    
} & WithStyles<typeof styles>

const CalcPlusvaliaAddCalculo : FC<TProps>= ({classes, calculo, setCalculo, setSujetoInfoErr, onCancel, apartado, idRef,tipoModelo, fechaTransmision, infoVend, nifSujPasivo, hideImporte }) => {
    const terms= useContext(LiteralsContext)
    const [, alertsDispatch] = useContext(AlertsContext);
    
    //Gateways
    const ioc = useContext(IoC);
    const documentosGateway: DocumentosGateway = useMemo(() => ioc.get(DocumentosGateway), [ioc]);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    
    const [loading, setLoading]= useState(false) 
    const [showModalCalc, setShowModalCalc]= useState(false)
    const [showPorcentTotalErr, setShowPorcentTotalErr]= useState(false)
    const [showAllOrAnyImportErr, setShowAllOrAnyImportErr]= useState(false)
    const [showReqDocsErr, setShowReqDocsErr]= useState(false)
    const [requiredDocs, setRequiredDocs]= useState(false)
    const [errorCalcPLV, setErrorCalcPLV]= useState(false) 

    const [localInfo, setLocalInfo]= useState<TCalculo>({} as TCalculo)
    const [localInfoErr, setLocalInfoErr]= useState<TErrors>(EMPTY_ERR)

    const allAttached = useMemo(() => {
        if(requiredDocs ){
            const allOK =  localInfo ? 
                localInfo.operaciones.map( 
                    op => (op.idDocAnteriorAdquisicion !== undefined && op.idDocAnteriorAdquisicion !== "")
                )
                : [false]
            return allOK.includes(false) ? false: true
        } else {
            return false
        }

    },[requiredDocs, localInfo])

    
    const deleteDocs = async (docs: string[]) => {
       //console.log('deleteDocs ')
        const allOK = await Promise.all( 
            docs.map(async (id) => await documentosGateway.deleteDocAtib(id))
        )
    }

    
    // desde modal Calular si no se requiren documentos
    const autoSave =  () => { 
        setLoading(true)
        // No requiere adjuntar documentos
        // Limpiamos los idDocs que puedira haber al inicio o en los últimos calculos
        const idsInit = calculo.operaciones.map( op => op.idDocAnteriorAdquisicion).filter((idDoc): idDoc is string => !!idDoc);
        const idsCurrent = localInfo.operaciones.map( op => op.idDocAnteriorAdquisicion).filter((idDoc): idDoc is string => !!idDoc);
        idsCurrent.forEach(idDoc => {
            if(!idsInit.includes(idDoc)){ idsInit.push(idDoc) }
        });
        if(idsInit.length>0){
            deleteDocs(idsInit);
        }
        setCalculo(localInfo);
        setLoading(false);
    }

    const onSave = () => { 
        setLoading(true)
        // Validate 
        let err = requiredDocs 
        ? (!allAttached || localInfo.declaracionResponsable !== true)
        : false;
        
        if(!err){
            const idsInit = calculo.operaciones.map( op => op.idDocAnteriorAdquisicion)
            const ids = localInfo.operaciones.map( op => op.idDocAnteriorAdquisicion)
            const diffIds = idsInit.map((idIni,idx) => idIni && idIni !== ids[idx] ? idIni : undefined).filter((idDoc): idDoc is string => !!idDoc);
            // Limpiamos los idDocs que han cambiado
            deleteDocs(diffIds)
            setCalculo(localInfo);
            setLocalInfoErr(EMPTY_ERR)
            setLocalInfo({} as TCalculo)
        } else {
            if(!allAttached) {setShowReqDocsErr(true)}
            if(localInfo.declaracionResponsable !== true){
                setLocalInfoErr(curr =>({...curr, declaracionResponsable:true}))
            }
        }
        setLoading(false)
       
    }

    const onBack = () => {
        setLocalInfoErr(EMPTY_ERR)
        setLocalInfo({} as TCalculo)
        onCancel()
    }

    const onBackOperaciones = () => {
        setLoading(true)
        const docs= localInfo.operaciones.map(op => op.idDocAnteriorAdquisicion).filter((idDoc): idDoc is string => !!idDoc);
        if(docs.length>0){ 
            deleteDocs(docs)
        }
        const newOP = localInfo.operaciones.map(op => ({...op, idDocAnteriorAdquisicion: undefined }))
        setRequiredDocs(false)
        setLocalInfo(curr => ({...curr, 
            modelo: undefined,
            operaciones: newOP,
            declaracionResponsable: false
        }))
        setLoading(false)
    }

    const onCheckInfoToCalculate= async () => {
        const newErr = cloneDeep(localInfoErr.operaciones)
        let sumaPorcent=0
        const withImport:boolean[] = []
        const checkedOp = localInfo.operaciones.map((op,idx) =>{
            newErr[idx]={
                fechaAnteriorAdquisicion: op.fechaAnteriorAdquisicion === undefined,
                porcentajeAnteriorAdquisicion: op.porcentajeAnteriorAdquisicion === undefined || op.porcentajeAnteriorAdquisicion === 0,
                importeAnteriorAdquisicion: false,
                idDocAnteriorAdquisicion: localInfoErr.modelo && requiredDocs ? (op.idDocAnteriorAdquisicion === undefined || op.idDocAnteriorAdquisicion ==="") :false
            }
            
            if(Object.values(newErr[idx]).includes(true)){
                return false
            } else{
                sumaPorcent=sumaPorcent+op.porcentajeAnteriorAdquisicion
                withImport.push(op.importeAnteriorAdquisicion !== undefined && op.importeAnteriorAdquisicion > 0) 
                return true
            }
        })
        const errImport = checkedOp.includes(false) 
            ? false 
            : (withImport.length> 0 && withImport.includes(true) && withImport.includes(false))? true: false
        
        if(!(checkedOp.includes(false)) && (sumaPorcent === 100) && !errImport && nifSujPasivo){
            // ALL OK
            if(tipoModelo === "081"){                
                const estadoAutoliquidacion = await modelGateway.checkEstadoAutoliquidacion(idRef, nifSujPasivo, localInfo.porcentaje, localInfo.tipoDerecho?.id);
                if (estadoAutoliquidacion.ok) {
                    const result = await modelGateway.calcularPlusvalia(localInfo,idRef,infoVend.nif,nifSujPasivo)
                    if(result.ok) {
                        setLocalInfo({...result.data});
                    } else {
                        setErrorCalcPLV(true);
                    }
                    setShowModalCalc(true);
                } else {
                    alertsDispatch({ 
                        type: 'show-alert',
                        payload: {
                            message: translate('Plusvalias', estadoAutoliquidacion.literal, terms, [estadoAutoliquidacion.idModelo]),
                            variant: "warning",
                            hasCustomAction: true,
                        }
                    });
                }
            } else if (tipoModelo === "080") {
                const reqDocs = localInfo.operaciones && localInfo.operaciones.length > 0 
                                && localInfo.operaciones[0].importeAnteriorAdquisicion 
                                && localInfo.operaciones[0].importeAnteriorAdquisicion > 0 ? true : false;
                setRequiredDocs(reqDocs);
                if(!reqDocs){ 
                    autoSave();
                }
            } else {
                autoSave();
            }
        } else {
            if(sumaPorcent !== 100) {
                setShowPorcentTotalErr(true);
            }
            if(errImport) {
                setShowAllOrAnyImportErr(errImport);
            }
            if (!nifSujPasivo) {
                setSujetoInfoErr((curr: any) => ({...curr, nifSujetoPasivo: true }));
            }

            setLocalInfoErr(curr => ({...curr,
                operaciones: newErr
            }));
        }

    }

    useEffect(() => {
        setLocalInfo(calculo)
        const EMPTY_INFO_ERR: TErrors = {
            ...EMPTY_ERR, 
            operaciones: Array(calculo.operaciones.length).fill({...EMPTY_OP_ERR})
        }
        setLocalInfoErr(EMPTY_INFO_ERR)
    }, [calculo])
   
    return (
        <div style={{marginLeft: 10}}>
            <FullLoading loading={loading} />
            <Grid container direction='column' >
                <Grid item container direction="row" justify="space-between" className={classes.headerDerecho}>
                    <Grid item xs={4} className={[classes.row].join(' ')}>
                        <Typography className={classes.infotext}>{translate('Global','derechoTransmision', terms)}</Typography>
                        <Typography className={classes.infotext} style={{paddingRight:5 , paddingLeft: 5}}>-</Typography>
                        <Typography className={classes.infotext}><b>{localInfo.tipoDerecho?.nombre}</b></Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.row}>
                        <Typography className={classes.infotext}>{translate('Plusvalias','numOperacionesShort2', terms)}: <b>{localInfo.numOperaciones}</b></Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.row}>
                        <Typography className={classes.infotext}>{translate('DUTI','porcentajeTitularidad', terms)}: <b>{localInfo.porcentaje} %</b></Typography>
                    </Grid>

                    {localInfo.edadUsufructuaria ?                     
                        <Grid item xs={4} className={classes.row}>
                        <Typography className={classes.infotext}>
                            {translate('Plusvalias',localInfo.tipoDerecho?.id === 'usufructo-temporal' ? "duracionUsufructo": "edadUsufructuaria", terms)}: <b>{localInfo.edadUsufructuaria} {translate('Plusvalias','anyos', terms).toLowerCase()}</b>
                        </Typography>
                        </Grid>
                        : null
                    }

                </Grid>

                <Grid item>
                    <LabelWithInfo type="text" fieldTerm={'text_calc_operaciones'} componentIG={"Plusvalias"} moreInfo={{template: 'plusvalias_info_calc_operaciones',size:'xs'}} />
                    <div style={{minHeight: 30, marginBottom:5, width: '100%'}}>
                        {showPorcentTotalErr && 
                            <Typography color='error'>
                                {translate('DUTI','operaciones_percentaje_not_sum_100',terms)}
                            </Typography>
                        }   
                        {showAllOrAnyImportErr && 
                            <Typography color="error">
                                {translate('DUTI','operaciones_not_all_importe_anterior',terms)}
                            </Typography>
                        }
                        {showReqDocsErr && 
                        <Typography color="error">
                            {translate('Plusvalias','operaciones_not_all_doc_anterior',terms)}
                        </Typography>
                        }
                    </div>
                    <div style={{width: '100%'}}>
                        {localInfo.operaciones?.map( (op, idx) => {
                            return (
                                <OperacionPLV 
                                    key={idx}
                                    renderType={'form'}
                                    initialValue={{...op}}
                                    errors={localInfoErr.operaciones[idx]}
                                    onChange= {(v: TOperacion, vErr: TOperacionErr, resetModelo:boolean) => {
                                        setShowPorcentTotalErr(false)
                                        setShowAllOrAnyImportErr(false)
                                        setShowReqDocsErr(false)
                                        const newOp = cloneDeep(localInfo.operaciones)
                                        const newOpErr = cloneDeep(localInfoErr.operaciones)
                                        newOp[idx]=v
                                        newOpErr[idx]=vErr
                                        setLocalInfo(curr => resetModelo
                                            ? ({...curr, operaciones:newOp, modelo: undefined, declaracionResponsable: undefined })
                                            : ({...curr, operaciones:newOp, declaracionResponsable: undefined })
                                        )
                                        setLocalInfoErr(curr => ({...curr, operaciones:newOpErr}))
                                    }}
                                    maxDate= {fechaTransmision}
                                    hideImporte={hideImporte}
                                    showAttach={requiredDocs}
                                    idReferencia={idRef}
                                    apartado={apartado}
                                />
                            )
                        })
                        }
                    </div>
                    {requiredDocs && 
                        <div style={{width: '100%', marginTop:10, marginBottom: 20}}>
                            <CheckBox disabled={ !allAttached } 
                                fieldTerm={'declaracionResponsable'}
                                value={localInfo?.declaracionResponsable ?? false} 
                                onChangeValue={(v:boolean) => { 
                                    setLocalInfoErr(curr => ({...curr, declaracionResponsable: (requiredDocs && !v )? true : false}))
                                    setLocalInfo(curr => ({...curr, declaracionResponsable: v}))
                                }}
                                error={localInfoErr.declaracionResponsable}
                                errorMsg={translate('DUTI', 'requiere_declaracionResponsable',terms)}
                            />   
                        </div>
                    }
                </Grid>
                {localInfo.modelo === undefined && !requiredDocs &&
                    <Grid item style={{width: '100%', marginTop:10, marginBottom: 20}}>
                        <Button 
                            style={{marginRight:10}}
                            variant="contained" 
                            onClick={onBack}
                        >
                            {translate('Global', 'Atras', terms)}
                        </Button>
                        <Button 
                            
                            variant="contained" color="primary"
                            onClick={onCheckInfoToCalculate}
                        >
                            {translate('Global', 'btnCalcular', terms)}
                        </Button>          
                    </Grid>
                }
                { ((localInfo.idGestion !== undefined && localInfo.modelo !== undefined) || (requiredDocs)) &&
                    <Grid item style={{width: '100%', marginTop:10, marginBottom: 20}}>
                        <Button 
                            style={{marginRight:10}}
                            variant="contained" 
                            onClick={onBackOperaciones}
                        >
                            {translate('Global', 'Atras', terms)}
                        </Button>
                        <Button 
                            variant="contained" color="primary"
                            onClick={onSave}
                        >
                            {translate('DUTI', 'btn_confirmar', terms)}
                        </Button>          
                    </Grid>
                }
            </Grid>

            <Modal
                fullWidth
                maxWidth="md"
                open={showModalCalc}
                title={translate('DUTI','metodo_calculo_m081',terms).toUpperCase()}
                renderContent={() => (
                    <FormCalculoPLV 
                        info={localInfo}  
                        infoVend={infoVend}  
                        error={errorCalcPLV}
                        onConfirm={() => {
                            setShowModalCalc(false)
                            const reqDocs=(
                                localInfo.modelo &&
                                localInfo.modelo.importeMetodoReal !== null &&
                                ( localInfo.modelo.importeMetodoReal < localInfo.modelo.importeMetodoObjetivo 
                                  || (localInfo.modelo.importeMetodoReal === localInfo.modelo.importeMetodoObjetivo && localInfo.modelo.importeMetodoObjetivo===0)
                                )
                            ) ? true : false;
                            setRequiredDocs(reqDocs)
                            if(!reqDocs){ 
                                autoSave() 
                            }
                        }}  
                        onCancel={() => { 
                            setShowModalCalc(false)
                            setLocalInfo(curr=> ({...curr, modelo: undefined}))
                        }} 
                    />
                )}
            />
        </div>
    )
}

const styles =  (theme: Theme): StyleRules => ({
    infotext: {
        marginRight: 20
    },
    row:{
        display: 'flex',
        flexDirection: 'row'
    },
    headerDerecho: {
        borderBottom: 'black solid 2px ',
        marginTop: 15,
        marginRight: 10
    },
})

export default withLiterals(['DUTI','Plusvalias', 'Global'])(withStyles(styles)(CalcPlusvaliaAddCalculo))