import { FC,  useContext,  useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { StepComponentProps } from "components/generic-stepper/generic-step-component";
import ButtonsStepper from "containers/tributos-autonomicos-locales/componentesAsistente/shared/buttonsStepper";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import useCommonStyles from './styles'
import DirectionATIB, {EMPTY_DIRECTION, EMPTY_DIRECTION_ERRORs,EMPTY_DISABLED, TDirectionATIB, TDirectionFlags} from "components/generic-sujeto/DirectionATIB";
import { TDireccionRepresentante, TRepresentante } from "./types";
import GenericPanelExpand from "components/generic-panel-expand";
import { translate } from "utils/i18n";
import NifNomApe, { TNifNomApe } from "components/generic-sujeto/NifNomApe";
import Contacto, { TContact } from "components/generic-sujeto/Contacto";
import IoC from "contexts/ioc.context";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import { camposToObject } from "../utils";
import { AlertsContext } from "contexts/alerts.context";
import { initial } from "lodash";
import FullLoading from "components/full-loading";
import GenericStepSchema from "components/generic-stepper/generic-step-schema";
import usePage from "hooks/page.hook";
import { TExtraValidation } from "components/generic-input-text";

type DireccionRepresentanteProps = StepComponentProps  & WithStyles<typeof styles> 

const DireccionRepresentante: FC<DireccionRepresentanteProps> = ({classes, idRef, apartado, onBack,onNext,onCancel, isFirst, isLast, sujColegATIB}) => { 
    //const CommonClasses= useCommonStyles();
    const terms=useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
    const [pageState, pageDispatcher] = usePage()
    const componentMounted = useRef(true);
    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]);

    const [loading, setLoading]= useState(false);

    const EMPTY_REPRE: TRepresentante= {nif:undefined, nomApe:undefined, email:undefined, telefono: undefined} 
    const [direccion_representante, setDireccionRepresentante]= useState<TDireccionRepresentante>({ dirNotificacion: EMPTY_DIRECTION, representante: undefined})
    const [errNifNomApe,setErrNifNomApe]= useState(false);
    //const [dirErr, setDirErr]= useState<TDirectionFlags>(EMPTY_DIRECTION_ERRORs)
    // -- Memos    ---------------------------------------------------------------------------------------------------------------------------
    const esRepresentacion = useMemo(() => {
        if(pageState.jwp && pageState.jwp.nifRepresentante){
            return ({action: pageState.jwp.representante ?? false, info: { nif: pageState.jwp.nifRepresentante, nomApe: pageState.jwp.fullnameRepresentante}})
        } else if(pageState.jwp && !pageState.jwp.nifRepresentante && sujColegATIB){
            return ({action: true, info: null})
        } else {
            return ({action: false, info: null})
        }
    },[pageState])

    const disableNEXT = useMemo(() => {
        console.log('errNifNomApe',errNifNomApe, direccion_representante.representante )
        const dirOK = true
            // direccion_representante.dirNotificacion.municipio 
            // && direccion_representante.dirNotificacion.nombreVia && direccion_representante.dirNotificacion.numero
            // &&  direccion_representante.dirNotificacion.cp 
        const repreOK= direccion_representante.representante 
                && direccion_representante.representante.nif && direccion_representante.representante.nif!==''
                && direccion_representante.representante.nomApe && direccion_representante.representante.nomApe!== ''
                && direccion_representante.representante.telefono 
                && direccion_representante.representante.email
                 && !errNifNomApe
        return dirOK && repreOK ? false : true
    },[direccion_representante, errNifNomApe])

    // -- Functions  -------------------------------------------------------------------------------------------------------------------------
    const handleNext = async () => {
        try {
            setLoading(false)
            if(!idRef){ throw new Error('not-idRef')}
            const response = await abonoNacimientoGateway.saveStep(idRef,'direccion_representante',direccion_representante)
            if(response.saved && response.referencia){
                onNext(response.referencia)
            }else{
                if(response.error){
                    alertsDispatch({
                        type:'show-alert',
                        payload: {
                            variant:'error',
                            message: translate('AbonoNacimiento',response.error,terms) + translate('AbonoNacimiento','direccion_representante',terms)
                        }
                    })
                } else {throw new Error ('ErrorSaveStep')}
            }
            
        } catch (error) {
            alertsDispatch({
                type:'show-alert',
                payload: {
                    variant:'error',
                    message: translate('AbonoNacimiento','ErrorSaveStep',terms)+ translate('AbonoNacimiento','direccion_representante',terms)
                }
            })
            
        } finally {
            setLoading(false)
        }
    }

    // -- Effects  ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                //  INICIALIZACIÓN
                const campos =  await abonoNacimientoGateway.getCamposStep('direccion_representante')
                const emptyObject= camposToObject(campos)
                let initVal = {...emptyObject, dirNotificacion: EMPTY_DIRECTION} as TDireccionRepresentante
                //console.log('emptyObject ', emptyObject)
                if( !idRef ){ throw new Error('not-idRef')}
                    // consultar datos BBDD
                    const response =  await abonoNacimientoGateway.getApartadosByRef(idRef)
                    //console.log(idRef, 'emptyObject ', emptyObject, response)
                
                    // consultar datos BBDD
                    initVal= response.direccion_representante ?? initVal
                    if(!initVal.representante || initVal.representante.nif === undefined){
                        initVal ={ 
                            ...initVal,
                            representante: {
                                email:undefined,
                                telefono:undefined,
                                nif: pageState?.jwp?.nifRepresentante ?? undefined, 
                                nomApe: pageState?.jwp?.fullnameRepresentante ??undefined
                            }
                        }
                    }
                    //console.log('initVal', initVal)
                 
                if(componentMounted.current){
                    setDireccionRepresentante(initVal)
                }

            } catch(err){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('AbonoNacimiento','ErrorLoadingStep',terms) 
                    }
                })
            } finally {
                setLoading(false)
            }
            
        })();

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
        
    },[idRef, apartado]);
    
    return (

        <GenericStepSchema
            isFirst={isFirst}
            isLast={isLast}
            onNext={() => {
                //console.log('hi from CPrueba/on-next');
                handleNext();
            } }
            disabledNext={disableNEXT}
            onBack={onBack}
            disabledBack={false}
            onExit={onCancel}
            forceExit={false} 
            idRef={idRef} 
            allowContinuarMasTarde={false}
            withRecoverRefContinuarMasTardeModal={false}
            withContinuarMasTardeModal={true}
        >
            
            
            <FullLoading loading={loading}/>
            {/**Componente  */}
            <Grid container direction="column" alignItems="center" justify="flex-start">
                {/* <Grid item className={[classes.fullWidth, classes.column].join(' ')}>
                    <GenericPanelExpand
                        expandable={false}
                        initialPosition='expanded'
                        renderHeader={() => <Typography>{translate('AbonoNacimiento','panel_dir_notificacion',terms)}</Typography> }
                        renderContent={() => {
                            return(
                                <DirectionATIB 
                                    direction={direccion_representante.dirNotificacion}
                                    setDirection={ (d:TDirectionATIB) => {
                                        setDireccionRepresentante(curr => ({...curr, dirNotificacion: d}))
                                    }}
                                    dirErrors={dirErr}
                                    setDirErrors={setDirErr}
                                    disabled={{
                                        ...EMPTY_DISABLED,
                                        pais: true,
                                        //provincia: true,
                                    }}
                                /> 
                            )
                        }}
                    />
                </Grid> */}
                <Grid item className={[classes.fullWidth, classes.column].join(' ')}>
                    <GenericPanelExpand
                        expandable={false}
                        initialPosition='expanded'
                        renderHeader={() =>
                        <div style={{display:'inline-flex', alignItems: 'center', justifyContent:'space-between', width: '100%'}}>
                        <Typography>{translate('AbonoNacimiento','panel_representante',terms)}</Typography> 

                        { esRepresentacion.action && 
                            <Button variant="outlined" color="inherit" 
                                className={classes.button}
                                disabled={esRepresentacion.info === null}
                                onClick={()=>{ 
                                    if(esRepresentacion.info) {
                                        setDireccionRepresentante(curr => ({
                                            ...curr,
                                            representante: curr.representante 
                                                ? {...curr.representante,nif: esRepresentacion.info.nif, nomApe: esRepresentacion.info.nomApe}
                                                : {...EMPTY_REPRE,nif: esRepresentacion.info.nif, nomApe: esRepresentacion.info.nomApe}
                                        }))
                                        setErrNifNomApe(false)
                                    }
                                }}
                            >
                                {translate('Global','chargeUserRepreLogged', terms)}
                            </Button> 
                        }
                        
                    </div>
                    }
                        renderContent={() => {
                            return(
                                <Grid container direction="row" alignItems="center" justify="space-between">
                                    <Grid item className={classes.row}>
                                        <NifNomApe
                                            nif={direccion_representante.representante?.nif} 
                                            nomApe={direccion_representante.representante?.nomApe} 
                                            onChange={(key: keyof TNifNomApe, value: string|undefined, err: boolean) => {
                                                setDireccionRepresentante(curr => ({
                                                    ...curr, 
                                                    representante: curr.representante
                                                    ? {...curr.representante,[key]: value}
                                                    : {...EMPTY_REPRE, [key]: value}
                                                }))
                                                setErrNifNomApe(err)
                                            }}
                                            required={{nif: true, nomApe:true}}
                                            extraNifValidation={(nif: string)=>{
                                                let out: TExtraValidation = {error:false, error_msg:undefined, componentIG:'GenericComponents'}
                                                if( esRepresentacion.info?.nif && nif !== esRepresentacion.info?.nif){
                                                    out ={error:true, error_msg:'not_logguedRepre', componentIG:'AbonoNacimiento'}
                                                }
                                                return out
                                            }}
                                        />
                                    </Grid>
                                    <Grid item className={classes.row}>
                                        <Contacto 
                                            telefono={direccion_representante.representante?.telefono} 
                                            email={direccion_representante.representante?.email} 
                                            onChange={(key: keyof TContact, value: string|undefined, err: boolean) => {
                                                setDireccionRepresentante(curr => ({
                                                    ...curr, 
                                                    representante: curr.representante
                                                    ? {...curr.representante,[key]: value}
                                                    : {...EMPTY_REPRE, [key]: value}
                                                }))
                                            }}
                                            required={ esRepresentacion.action
                                                ? {telefono: true, email: true}
                                                : {telefono: false, email:false}
                                            }  
                                            prefixedTLF={true}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }}
                    />
                </Grid>

                
            </Grid>
           
        </GenericStepSchema>

       
    )
}


const styles = (theme: Theme): StyleRules => ({
    fullWidth: {
        width: "100%"
    },
    row: {
        display: 'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    column: {
        display: 'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    spaceBetween:{
        justifyContent:'space-between'
    }

});

export default withLiterals(['Global', 'Tramites','AbonoNacimiento','GenericCompoonents' ])(withStyles(styles)(DireccionRepresentante))

