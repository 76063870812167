import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { Grid, Button, makeStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, useTheme, Divider, FormControl } from "@material-ui/core";
import Term from '../../../components/term';
import { IMultas, IFilterData, IMultasRemesa } from 'gateways/tramites.interfaces';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import MultasDetail from './multas.detail';
import LabelField from 'components/label-field';
import { mdiCircle } from '@mdi/js';
import moment from 'moment';
import DocumentosDetail from './documentos.detail';
import { translate } from 'utils/i18n';

const useStyles = makeStyles((theme) => ({
    rounded: {
        borderRadius: 8,
        margin: '16px 0'
    },
    main: {
        width: '90%',
        marginLeft: '5%'
    },
    botonIdentificacion: {
        display: 'flex',
        alignItems: 'center',
    }
}));

interface IProps {
    isMultaRemesa:boolean;
    multa: IMultas | IMultasRemesa;
    filterData: IFilterData | null;
    mensajeAclaratorio?: string;
    handleIdentificar: (multa: IMultas | IMultasRemesa) => void;
    isRentACar: boolean;
    handleNotAllowedDocuments: () => void;
    idReciboAIdentificar?: number;
}


const MultasGrid: FC<IProps> = ({ isMultaRemesa, multa, filterData, children, mensajeAclaratorio, handleIdentificar, isRentACar, handleNotAllowedDocuments, idReciboAIdentificar }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [openDownload, setOpenDownload] = useState(false);
    const estado_PenAnuCob = useMemo(() => {
        if(isMultaRemesa){
            const m = multa as IMultasRemesa
            if(m.anulada){
                return 'anulada'
            } else if(m.cobrada){
                return 'cobrada'
            } else{
                 return 'pendiente'
            }
        } else{
            return null
        }
         
    }, [multa, isMultaRemesa])

    const handleOpenDetail = useCallback((event: React.MouseEvent) => {
        if (multa.identificable) {
            event.stopPropagation();
            setOpenDetail(true);
        }
    }, [multa.identificable]);
    const handleCloseDetail = useCallback(() => setOpenDetail(false), []);
    const handleOpenDownload = useCallback((event: React.MouseEvent) => {
        if (multa.estado === 'Identificada') {
            event.stopPropagation();
            setOpenDownload(true);
        }
    }, [multa]);
    const handleCloseDownload = useCallback(() => setOpenDownload(false), []);
    const handleChange = () => {
        const expandedTemp = expanded;
        setExpanded(!expandedTemp);
    }

    useEffect(() => {
        if (idReciboAIdentificar === multa.idRecibo) {
            setOpenDetail(true);
        }
    }, [idReciboAIdentificar, multa.idRecibo, setOpenDetail])

    return (
        <>
            <ExpansionPanel className={[classes.rounded].join(' ')} square expanded={expanded} onChange={handleChange}>
                <ExpansionPanelSummary
                    aria-controls="panel-multa-content"
                    id="panel-multa-header"
                    expandIcon={
                        <Icon path={mdiChevronDown}
                            size={1}
                            color='#000'
                        />
                    }
                >

                    <Grid container alignItems='center' justify='center' >
                        {multa.identificable
                            ?
                            <Grid item xs={12} sm={2} className={classes.botonIdentificacion}>
                                <FormControl>
                                    <Button
                                        variant="outlined"
                                        onClick={handleOpenDetail}
                                        color="primary"
                                        size="small"
                                    >
                                        <Term component="Tramites" text="Identificar" />
                                    </Button>
                                </FormControl>
                            </Grid>
                            :
                            multa.estado === 'Identificada' ?
                                <Grid item xs={12} sm={2} className={classes.botonIdentificacion}>
                                    <FormControl>
                                        <Button
                                            variant="outlined"
                                            onClick={handleOpenDownload}
                                            color="primary"
                                            size="small"
                                        >
                                            <Term component="Tramites" text="Documentacion" />
                                        </Button>
                                    </FormControl>
                                </Grid>
                                : <Grid item xs={12} sm={2}/>
                        }
                        <Grid item xs={12} sm={1}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="matricula" />}
                                    text={multa.matricula}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}  style={{alignSelf: 'center'}}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="referenciaRecibo" />}
                                    text={multa.referenciaRecibo}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="fechaNotificacion" />}
                                    text={multa.fechaNotificacion ? moment(multa.fechaNotificacion).format('DD/MM/YYYY') : ''}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <LabelField
                                    label={<Term component="Tramites" text="fechaLimite" />}
                                    text={multa.fechaLimite ? moment(multa.fechaLimite).format('DD/MM/YYYY') : ''}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <FormControl>
                                <div>
                                    {
                                        multa.identificable
                                            ?
                                            <>
                                                <Icon path={mdiCircle}
                                                    size={0.5}
                                                    color='lightgreen'
                                                />
                                                <b> <Term component="Tramites" text="Identificable" /></b>
                                                <div style={{ marginLeft: 15 }}>
                                                    {
                                                        multa.esOpcional ?
                                                            <Term component="Tramites" text="Opcional" />
                                                            :
                                                            <Term component="Tramites" text="Obligatoria" />
                                                    }
                                                </div>
                                            </>
                                            :
                                            multa.estado === 'Fuera de plazo' ?
                                                <div style={{ marginTop: 10 }}>
                                                    <Icon path={mdiCircle}
                                                        size={0.5}
                                                        color='red'
                                                    />
                                                    <b> <Term component="Tramites" text="FueraPlazo" /></b>
                                                </div>
                                                :
                                                <div style={{ marginTop: 10 }}>
                                                    <Icon path={mdiCircle}
                                                        size={0.5}
                                                        color='gray'
                                                    />
                                                    <b> <Term component="Tramites" text="Identificada" /></b>
                                                </div>
                                    }
                                </div>
                            </FormControl>
                        </Grid>
                        {estado_PenAnuCob && 
                            <Grid item xs={12} sm={1}>
                                <LabelField
                                    label={<Term component="Tramites" text="estado" />}
                                    text={<Term component='Tramites' text={estado_PenAnuCob} />}
                                />
                            </Grid>
                        }
                    </Grid>
                </ExpansionPanelSummary>
                <Divider
                    className={classes.main} />
                <ExpansionPanelDetails>
                    {children}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <MultasDetail
                onClose={handleCloseDetail}
                open={openDetail}
                multa={multa}
                mensajeAclaratorio={mensajeAclaratorio !== undefined ? mensajeAclaratorio : ''}
                handleIdentificado={handleIdentificar}
                isRentACar={isRentACar}
                handleNotAllowedDocuments={handleNotAllowedDocuments}
            />

            <DocumentosDetail
                onClose={handleCloseDownload}
                open={openDownload}
                multa={multa}
                mensajeAclaratorio={mensajeAclaratorio !== undefined ? mensajeAclaratorio : ''}
            />
        </>
    );
}

export default MultasGrid;