import React, { FC, useEffect, useState, useContext, useMemo, Fragment } from "react";
import usePage from "hooks/page.hook";
import { RouteComponentProps } from "react-router";
import IoC from "contexts/ioc.context";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { CardContent, Card } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import Carrousel from 'bootstrap/js/dist/carousel';
import 'index.css';



interface IParams {
    landingName: string;
    pageTitle?: string;
    pageSubtitle?: string;
    icon?: string;
}

type Props = RouteComponentProps;

/** Incrusta una página de HTML
 * Definición del contenido en IntriGest/contenidos => page o modal 
 * el SOURCE del contenido debe seguir la estructura que se muestra en => https://intricom.atlassian.net/wiki/spaces/DW/pages/1467482119/Plantilla+HTML+-+Accordition+-+Contenidos+SEDE 
 * */

const ContenidoWithCarousel: FC<Props & IParams> = ({ match, landingName, pageTitle, pageSubtitle, icon }) => {
    const [state] = usePage();
    const [, pageDispatcher] = usePage();
    const [body, SetBody] = useState('');
    const [title, SetTitle] = useState('');

    // sevices
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(ContenidosGateway) as ContenidosGateway, [ioc]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                title: pageTitle ?? title,
                text: pageSubtitle,
                icon: icon ?? null,
            },
            menu: true,
        });
    }, [pageDispatcher, title, pageTitle, pageSubtitle, icon]);

    useEffect(() => {
        const handleGetLanding = async (landingName: string, idioma: string) => {
            console.log('landingName', landingName, idioma)
            const landing = await gateway.getContent(landingName, idioma);
            console.log('result ', landing)
            const bodyAux = landing.find((x: { campo: string; contenido: string }) => {
                return x.campo === 'body';
            })
            const titleAux = landing.find((x: { campo: string; contenido: string }) => {
                return x.campo === 'title';
            })
            if (titleAux !== undefined || titleAux != null) {
                SetTitle(titleAux.contenido);
            }
            if (bodyAux !== undefined || bodyAux != null) {
                SetBody(bodyAux.contenido);
            }
        }
        handleGetLanding(landingName, state.lang);
    }, [state.lang, gateway, landingName]);

    useEffect(() => {
        // Asegurando que todos los elementos de acordeón se inicialicen
        // Reemplaza 'example' con el id de tu acordeón si es diferente
        var carouselExample = document.getElementById('carouselExample');
        if (carouselExample) {
          const carousel = new Carrousel(carouselExample, {
            interval: 5000,  // Intervalo entre las imágenes en milisegundos
            wrap: true       // Activar para que se reinicie al llegar al final
          });
          carousel.cycle();
        }
      }, [body, state.lang]);


    return (
        <Fragment>
            <Card style={{ display:'flex', flexGrow:1, margin: '0px 15px 0px 15px', textAlign: 'justify', padding: 20, width: '100%'}}>
                <CardContent style={{flexGrow:1, paddingTop:16, paddingLeft:0, paddingRight:0}}>
                    <div dangerouslySetInnerHTML={{ __html: body }}  />
                </CardContent>
            </Card>
        </Fragment>
    );
};

export default ContenidoWithCarousel;