import { TExtraValidation } from "components/generic-input-select";
import { REGEX_REF_CATASTRAL_1, REGEX_REF_CATASTRAL_2, REGEX_REF_CATASTRAL_3, REGEX_REF_CATASTRAL_DISEMINADOS } from "containers/DUTI/utils";
import { translate } from "utils/i18n";

export const validateRefCatastral = (ref: string) => {
    const isOK1 = REGEX_REF_CATASTRAL_1.test(ref);
    const isOK2 = REGEX_REF_CATASTRAL_2.test(ref);
    const isOK3 = REGEX_REF_CATASTRAL_3.test(ref);
    const isOK4 = REGEX_REF_CATASTRAL_DISEMINADOS.test(ref);

    return({
        error: (isOK1 || isOK2 || isOK3 || isOK4) ? false: true, 
        error_msg_term: 'err_format_ref_catastral',
        component_ig: 'Global',
    })
}