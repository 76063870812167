import React, { useState, useEffect, FC, useContext, useCallback, useMemo } from "react";
import { mdiShieldPlus } from "@mdi/js";
import Term from "components/term";
import usePage from "hooks/page.hook";
import { RouteComponentProps } from "react-router-dom";
import { IRenderComponent, ITipoExpediente, IDetalleExpediente } from "gateways/tramites.interfaces";
import { CircularProgress, makeStyles, Button, Typography } from "@material-ui/core";
import ExpedienteCard from './expediente-card';
import { LiteralsContext } from "containers/shared/literals";
import { TramitesGateway } from "gateways/tramites.gateway";
import IoC from "contexts/ioc.context";
import PresentacionDialog from "./presentacion-modal";
import { translate } from "utils/i18n";
import { DocumentsUtils } from "utils/documents";
import { AlertsContext } from "contexts/alerts.context";
import GenericCheckbox from "components/generic-checkbox";
import GenericInputText from "components/generic-input-text";

const useStyles = makeStyles({
    root: {
        flex: 1,
        margin: '0 1rem'
    },
    expediente: {
        marginBottom: '1rem'
    },
    componenteBaseDusti: {
        marginLeft: 0,
    },
    componenteBase: {
        marginLeft: 45,
    },
    buttonList: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    button: {
        width: 200
    },
    column:{
        display:'flex',
        flexDirection:'column',
        flexGrow: 1,
        alignItems: 'flex-start',
    }
});

interface IProps {
    diccionarioComponentes: { [component: string]: IRenderComponent };
    showContinuar: boolean;
    idExpediente?: number;
    onHandleShowContinuar?: () => void;
    handleNext?: () => void;
}

export const DetallePresentacion: FC<IProps & RouteComponentProps<{ idExpediente: string }>> = ({ diccionarioComponentes, showContinuar, idExpediente, onHandleShowContinuar, handleNext, match, ...props }) => {
    const [, pageDispatcher] = usePage();
    const classes = useStyles();
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const gateway: TramitesGateway = ioc.get(TramitesGateway);
    const [, alertsDispatch] = useContext(AlertsContext);

    const [loading, setLoading] = useState(true);
    const [imprimiendo, setImprimiendo] = useState(false);
    const [detalleExpediente, setDetalleExpediente] = useState<IDetalleExpediente | null>(null);
    const [tipoExpediente, setTipoExpediente] = useState<ITipoExpediente | null>(null);
    const [open, setOpen] = useState(false);

    const [localNumeroRegWeb, setLocalNumeroRegWeb]= useState<string|undefined>(undefined)
   
    const isDUSTI = useMemo(() => {
        if(props.location.pathname.toUpperCase().includes('/DUTI/') ){ 
            return true
        } else { 
            return false
        }
    },[props.location])

    const handlePresentacion = (detalle: IDetalleExpediente) => {        
        setDetalleExpediente(detalle);
        setOpen(false);
        if (onHandleShowContinuar) {
            onHandleShowContinuar();
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiShieldPlus,
                title: <Term component="Tramites" text="Presentación de expedientes" />
            },
            menu: true,
        });
    }, [pageDispatcher]);

    useEffect(() => {
        (async () => {
            setLoading(true);

            console.log('match', match);
            const detalleExpedienteTemp = await gateway.getDetalleExpediente(parseInt(match.params.idExpediente, 10));
            setDetalleExpediente(detalleExpedienteTemp);

            if (detalleExpedienteTemp !== null) {
                const detalleTipoExpedienteTemp = await gateway.getDetalleTipoExpediente(detalleExpedienteTemp.tipo.id);

                setTipoExpediente(detalleTipoExpedienteTemp);
            }

            setLoading(false);
        })();
    }, [gateway, match.params.idExpediente, isDUSTI]);

    useEffect(() => {
        if(detalleExpediente) {setLocalNumeroRegWeb(detalleExpediente.numeroRegWeb ?? undefined)}
    }, [detalleExpediente])

    const handleChangeExpediente = (detalle: IDetalleExpediente) => {
        setDetalleExpediente(detalle);
    }

    const handleImprimirJustificante = useCallback(async (detalle?: IDetalleExpediente | null) => {
        setImprimiendo(true);
        try {
            if (!!!detalle) {
                return;
            }
            if (detalle.idDocumentoJustificante !== null) {
                DocumentsUtils.downloadDoc(detalle.idDocumentoJustificante);
                return;
            }

            const idDoc = await gateway.generarJustificante(detalle.idExpediente);
            DocumentsUtils.downloadDoc(idDoc);
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tramites', (error as any).message, terms),
                    variant: 'error',
                }
            });
        } finally {
            setImprimiendo(false);
        }
    }, [alertsDispatch, gateway, terms]);

    // avoiding switch statements / long conditionals in react-ts   ---->   using dictionaries in TS + enum type
    enum ExpedienteSteps {
        INI = 'INI',
        PRE = 'PRE',
        ANU = 'ANU',
    }

    const handleEstadoExpediente = useCallback((detalle?: IDetalleExpediente | null) => {
        if (detalle) {
            switch (detalle.estado.id) {
                case ExpedienteSteps.INI:
                    return (<Button variant="contained" color="primary" className={classes.button} size="small" onClick={handleOpen}>
                        <Term component='Tramites' text='Presentar' />
                    </Button>);
                case ExpedienteSteps.PRE:
                    return (<Button variant="contained" color="primary" className={classes.button} size="small" onClick={() => handleImprimirJustificante(detalle)} disabled={imprimiendo}>
                        {imprimiendo && <CircularProgress size={12} />}
                        <Term component='Tramites' text='Imprimir justificante' />
                    </Button>);
                case ExpedienteSteps.ANU:
                    return (<Typography color="secondary" className={isDUSTI ? classes.componenteBaseDusti : classes.componenteBase} >
                        <Term component='Tramites' text='No se puede presentar, expediente anulado' />
                    </Typography>);
            }
        }
        return null;
    }, [ExpedienteSteps.ANU, ExpedienteSteps.INI, ExpedienteSteps.PRE, classes.button, classes.componenteBase, classes.componenteBaseDusti, handleImprimirJustificante, imprimiendo]);
    
    //-----------------------------------------------------------------------------------------------------------------------------------
    /**DWA-10164: Ofrecer solicitud de aplazamiento */
    const doChangeAplazamiento = async (value: boolean,detalle:IDetalleExpediente, idDocExp: string|undefined) => {
        try {
            let cleanNumRegExp = false
            if(idDocExp){
                await gateway.desvincularDocumentos(detalle.idExpediente, idDocExp.toString())
            }            
            if(value === false && detalleExpediente?.numeroRegWeb){
                cleanNumRegExp = await gateway.indicarNumeroRegWeb(detalle.idExpediente, null)
            }
            const saved = await gateway.indicarConAplazamiento( detalle.idExpediente, value)
            if(saved){ 
                handleChangeExpediente({...detalle, conAplazamiento: value, numeroRegWeb: cleanNumRegExp ? null : detalle.numeroRegWeb}) 
                // Modal de aviso - exp con aplazamientos
                {value ==true && 
                    alertsDispatch({
                        type:'show-alert',
                        payload: {
                            variant: 'info',
                            message: translate('Tramites','info_exp_con_aplazamiento',terms),
                        }
                    })
                }
            }
        } catch (error) {
            //console.log(' Error doChangeAplazamiento ', error)
            alertsDispatch({
                type:'show-alert',
                payload: {
                    variant:'error',
                    message: (error as Error).message
                }
            })
        }
    }
    const doChangeNumeroRegWeb = async (value: string | null,detalle:IDetalleExpediente) => {
        try {
            if(value && value.length> 50){
                throw new Error (translate('Tramites','error_num_reg_web_format',terms))
            }
            const saved = await gateway.indicarNumeroRegWeb( detalle.idExpediente, value)
            if(saved){ handleChangeExpediente({...detalle, numeroRegWeb: value}) }
        } catch (error) {
            //console.log(' Error doChangeNumeroRegWeb ', error)
            alertsDispatch({
                type:'show-alert',
                payload: {
                    variant:'error',
                    message: (error as Error).message
                }
            })
        }
    }
    const handleChangeAplazamiento = async (detalle:IDetalleExpediente, v:boolean) => {
        const docSolAplz = detalle.documentos.find( d => d.tipo === 'Solicitud_Fraccionamiento')
        if( docSolAplz && v ===false){
            alertsDispatch({
                type:'show-alert',
                payload: {
                    variant:'warning',
                    message: translate('Tramites','hay_solicitud_fraccionamiento',terms),
                    hasCustomAction: true,
                    actionTemplateName: translate('Tramites','desvincular',terms),
                    handleCustomAction: async() => {
                        //console.log('custom action', docSolAplz)
                        alertsDispatch({type: 'hide-alert'})
                        await doChangeAplazamiento(v, detalle,docSolAplz.documento.idDocumento)
                    },
                }
            })
        } else {
            await doChangeAplazamiento(v, detalle, undefined)
        }
    }
    //-----------------------------------------------------------------------------------------------------------------------------------

    return loading || !!!tipoExpediente || !!!detalleExpediente || !!!tipoExpediente.componentes || !!!detalleExpediente.tipo
        ? <CircularProgress />
        : <div className={classes.root}>
            <div className={classes.expediente}>
                <ExpedienteCard step={ isDUSTI ? -1 : 1} title={'TipoDocumentoLiquiadcion'} >
                    <div className={classes.column}>
                        <small className={ isDUSTI ? classes.componenteBaseDusti : classes.componenteBase}>
                            <b>
                                <Term component='Tramites' text={detalleExpediente.tipo.nombre} />
                            </b>
                        </small>
                        {tipoExpediente.aplazamiento &&
                            <div style={{paddingLeft: 45}}>
                                {/**DWA-10164: Ofrecer solicitud de aplazamiento */}
                                <GenericCheckbox 
                                    componentIG={'Tramites'}
                                    labelTerm={'aplazamiento'}
                                    value={detalleExpediente.conAplazamiento ? detalleExpediente.conAplazamiento : false }
                                    onChangeValue={(v:boolean ) => handleChangeAplazamiento(detalleExpediente, v)}
                                    moreInfo={{template: 'more_info_aplazamiento', size: 'sm'}}
                                />
                                {detalleExpediente.conAplazamiento &&
                                    <GenericInputText 
                                        componentIG={'Tramites'}
                                        labelTerm = {'numeroRegWeb'}
                                        value={localNumeroRegWeb}
                                        maxLength={50}
                                        onChangeValue= {(v: string|undefined, err: boolean) => setLocalNumeroRegWeb(v)}
                                        onBlur= {() => {
                                            doChangeNumeroRegWeb(localNumeroRegWeb ?? null, detalleExpediente)
                                        }}
                                        required = {detalleExpediente.conAplazamiento === true}
                                    />
                                }

                                
                            </div>
                        }
                    </div>
                </ExpedienteCard>
            </div>
            {
                tipoExpediente.componentes.map((c, index) =>
                (
                    <div key={'expediente_card_' + index} className={classes.expediente}>
                        <ExpedienteCard step={ isDUSTI ? -1 : index + 2} title={c.tipo.nombre} subTitle={c.mensajeOpcional} moreInfo={c.masInfoPlantilla}>
                            { diccionarioComponentes[c.tipo.nombre] &&
                                React.createElement(
                                    diccionarioComponentes[c.tipo.nombre].renderComponent,
                                    { onChange: handleChangeExpediente, detalle: {...detalleExpediente, isDUSTI: isDUSTI}, tipo: tipoExpediente }
                                )
                            }
                        </ExpedienteCard>
                    </div>
                )
                )
            }
            <div className={classes.expediente}>
                <ExpedienteCard step={ isDUSTI ? -1 : tipoExpediente.componentes.length + 2} title={'PresentacionExpediente'}>
                { showContinuar ?
                    <div style={{textAlign: "end", marginRight: 15}}>
                        <Button color="primary" variant="contained" size="medium" onClick={handleNext}>
                            <Term component="Global" text="Continuar" />
                        </Button>
                    </div>
                    :
                    <div className={classes.buttonList}>
                        {
                            handleEstadoExpediente({...detalleExpediente, isDUSTI})
                        }
                    </div>
                }
                </ExpedienteCard>
            </div>
            <PresentacionDialog
                open={open}
                onClose={handleClose}
                onChange={handlePresentacion}
                idExpediente={parseInt(match.params.idExpediente, 10)}
            />
        </div>;

}
