
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import { translate } from "utils/i18n";
import { mdiWalletTravel } from "@mdi/js";
import usePage from "hooks/page.hook";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { RouteComponentProps } from "react-router-dom";
import styles from "./styles";
import GenericStepper from "components/generic-stepper/index";
import DatosIdentificativos from "./steps/datos-identificativos";
import DireccionRepresentante from "./steps/direccion-representante";
import IdentificacionAbono from "./steps/identificacion-abono";
import DeclaracionResponsable from "./steps/declaracion-responsable";
import FinalizacionJustificante from "./steps/fin-justificante";
import Autorizacion from "./steps/autorizacion";

import { StepComponentProps } from "components/generic-stepper/generic-step-component";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import IoC from "contexts/ioc.context";

import FullLoading from "components/full-loading";
import HeaderStepper, { THeaderProps } from "./steps/componentes/headerStepper";
import { TramitesGateway } from "gateways/tramites.gateway";


// Extraer el componente del step
const componentMap: Record<string, (props: StepComponentProps & WithStyles) => JSX.Element> = {
    DatosIdentificativos,
    DireccionRepresentante,
    IdentificacionAbono,
    DeclaracionResponsable,
    FinalizacionJustificante,
    Autorizacion
};


type IProps = WithStyles<typeof styles> & RouteComponentProps<{ action: 'alta-solicitud'| 'reintegro-abonos'}> &  {} 
const AbonoNacimientoAlta : FC<IProps> = ({classes, match, history }) => {

    const terms = useContext(LiteralsContext);
    const isSmall = window.screen.width < 1930

    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]); 
    const tramitesGateway: TramitesGateway = useMemo(() => ioc.get(TramitesGateway), [ioc]);   


    const [pagestate, pageDispatcher] = usePage();
    const [activeStep, setActiveStep] = useState(0);
    const [localIdRef, setLocalIdRef] = useState<string | null>(null);
    const [headerProps, setHeaderProps] = useState<THeaderProps | null>(null);
    const [loading, setLoading] = useState(false)
    const [esRegistradoATIB, setEsRegistradoATIB] = useState(false);


    // -- Memos ----------------------------------------------------------------------------------------------------------------------
    const action= useMemo(() => match.params.action ,[match.params])
    const esRepresentacion= useMemo(() => (pagestate.jwp && pagestate.jwp.representante &&  pagestate.jwp.nifRepresentante ? true : false),[pagestate])

    const STEPS = useMemo(() => {
        let orden = 0
        const out = [
            {idTipoApartado: 'ABONAC_0', nameTermIG: 'datos_identificativos', componentIG: 'AbonoNacimiento',  orden: orden, webOrder: orden+1, component:  componentMap['DatosIdentificativos'] },
        ]
        orden= orden+1

        if(esRepresentacion || esRegistradoATIB){
            out.push({idTipoApartado: 'ABONAC_1', nameTermIG: 'direccion_representante', componentIG: 'AbonoNacimiento', orden: orden, webOrder: orden+1, component: componentMap['DireccionRepresentante'] })
            orden= orden+1
        }

        out.push({idTipoApartado: 'ABONAC_2', nameTermIG: 'declaracion_responsable', componentIG: 'AbonoNacimiento',   orden:orden, webOrder: orden+1, component: componentMap['DeclaracionResponsable']  })
        orden= orden+1
        out.push({idTipoApartado: 'ABONAC_5', nameTermIG: 'autorizacion', componentIG: 'AbonoNacimiento', orden: orden, webOrder: orden+1, component: componentMap['Autorizacion']})
        orden= orden+1
        out.push({idTipoApartado: 'ABONAC_3', nameTermIG: 'finalizacion_justificante', componentIG: 'AbonoNacimiento', orden: orden, webOrder: orden+1, component: componentMap['FinalizacionJustificante']})
        return out
    },[match.params, esRepresentacion, esRegistradoATIB])   

    // -- Functions-------------------------------------------------------------------------------------------------------------------
    const redirectDetalle = (id: number) => {
        history.push(`/tramites/abono-nacimiento/detalle/${id}`)
    } 
    const redirectAbonoNac = () => {
        history.push(`/tramites/abono-nacimiento`)
    } 
    
    const activeNext = (referencia?: string | null, infoHeader?: THeaderProps) => {
        if(referencia && !localIdRef){
            setLocalIdRef(referencia)
        }
        if(infoHeader){
            //console.log('set header props ', infoHeader)
            setHeaderProps(infoHeader)
        }
        setActiveStep(curr => {
            const p = curr+1
            return p < STEPS.length ? p : STEPS.length-1
        })
    }
    
    const activePrev = () => {
        //console.log('PREV ')
        setActiveStep(curr => {
            const p= curr-1
            return p<0 ? 0 : p
        })
    }

    const activeFinish= async () => {
        try {
            setLoading(true)
            //recuperar solicitud por ref
            //console.log('onFinish' )
            const solicitud = await abonoNacimientoGateway.getSolicitudByRef(localIdRef ?? '')
            redirectDetalle(solicitud.idSolicitud)
        } catch(err){
            // TODO complete
        } finally {
            setLoading(false)
        }
    }

    // -- Effects --------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        //console.log('page ', action)
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiWalletTravel,
                title: translate("AbonoNacimiento",(action === 'reintegro-abonos') ? "AbonoNacimiento_reint": "AbonoNacimiento_solic", terms), 
                text: translate("AbonoNacimiento",(action === 'reintegro-abonos') ? "AbonoNacimiento_reintDescripcion":"AbonoNacimiento_solicDescripcion", terms)
                // <div style={{display: 'flex', flexDirection:'column', marginLeft: 35}}>
                //     <span>{translate("AbonoNacimiento","AbonoNacimiento_solicDescripcion", terms)}</span>
                //     <CopyRef referencia={localIdRef}/>
                // </div>
            },
            menu: true,
        });
    }, [pageDispatcher, action]);

    useEffect(() => {
       (async () => {
        try {
            setLoading(true)
            if(pagestate.jwp?.nif && !esRepresentacion){
                const esSujRegistrado = await tramitesGateway.getUsuarioPermitidoConsultasExternas()
                setEsRegistradoATIB(esSujRegistrado)
            }
        } catch (error) {
            console.log('ERR - Consulta externos')
        } finally {
            setLoading(false)
        }
       })()
    }, [pagestate, esRepresentacion]);

    //console.log('STEPS', STEPS)


    return (
        <div style={{display:'flex', flexGrow:1}}>
            <FullLoading loading={loading}/>
            <GenericStepper 
                idRef={localIdRef}
                sujColegATIB={esRegistradoATIB}
                activeStep={activeStep}
                steps={STEPS}
                handleNext={activeNext}
                handlePrev={activePrev}
                handleFinish={activeFinish}
                handleExit={redirectAbonoNac}
                withHeader={true}
                headerProps={headerProps}
                componentHeader={HeaderStepper}
                size={isSmall ? 'small': 'default'}
                
            />
        </div>
       
    )
}


export default  withLiterals(['Global', 'Tramites','AbonoNacimiento'])(withStyles(styles)(AbonoNacimientoAlta)); 
