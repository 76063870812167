import { FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { FormHelperText, Grid } from "@material-ui/core";
import IoC from "contexts/ioc.context";
import ModelGateway  from "gateways/model.new.gateway";
import { TInmuebleCatastro } from "gateways/catastro.interface";
import { REGEX_REF_CATASTRAL_1, REGEX_REF_CATASTRAL_2, REGEX_REF_CATASTRAL_3, REGEX_REF_CATASTRAL_DISEMINADOS, TSelect } from "containers/DUTI/utils";
import { translate } from "utils/i18n";
import CatastroGateway from "gateways/catastro.gateway";
import { AlertsContext } from "contexts/alerts.context";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import InmuebleInfo from "components/DUTI/inmueble-info";
import FullLoading from "components/full-loading";
import InputSearch from "components/DUTI/input-search";
import { TExtraValidation } from "components/DUTI/types";
import InputSelect from "components/DUTI/input-select";
import LabelWithInfo from "components/DUTI/label-with-info";
import { validateRefCatastral } from "utils/validators/referencia-catastral";

type TInfoLabel= {labelTermIg: string,componenteIG:string, infoContent?: string}
type PropLabelWithInfo= Record<'municipio'|'refCatastral', TInfoLabel| null>
export interface IProps {
    selectedMuni: TSelect|undefined
    muniOptions: TSelect[]
    onChangeMuni: (v:TSelect|undefined, err:boolean)=> void
    inmueble: TInmuebleCatastro | null; 
    onChangeInmueble: (inm: TInmuebleCatastro | null) => void;
    getErrDiffMunis: (err:boolean)=> void
    getErrRefCat: (err:boolean)=> void
    labelWithInfo: PropLabelWithInfo
}


const MuniAndRefCatInfo: FC<IProps> = ({ 
    selectedMuni, muniOptions, onChangeMuni, inmueble, onChangeInmueble, getErrDiffMunis, getErrRefCat, labelWithInfo
}) => {
   
    const componentMounted = useRef(true);

    //Gateways
    const ioc = useContext(IoC);
    const modelosGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const catastroGateway: CatastroGateway = useMemo(() => ioc.get(CatastroGateway), [ioc]);
    // Contexts
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [loadingMSG, setLoadingMsg]= useState<string|undefined>(undefined)
    
    
    // Local
    const [loading, setLoading]= useState(false)
    const [refCat, setRefCat]= useState<string|undefined>(undefined)
    const [showMsgRefCatastralNotFound,setShowMsgRefCatastralNotFound] = useState(false);
    
    
    

    /** Funciones --------------------------------------------------------------------------------------------*/
    const validateRefCat = (ref: string) => {
        const res = validateRefCatastral(ref)
        return({error: res.error, error_msg: translate(res.component_ig,res.error_msg_term, terms)} as TExtraValidation)
    }

    const getInfoInmueble = async (ref:string|undefined) => {
        setLoadingMsg(translate('Global', 'consulta_pinbal', terms))
        setLoading(true)
        if(ref){
            
            const response = await catastroGateway.getInmuebleByRefCatastral(ref)
            if(response.error){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: response.error.componentIG 
                            ? translate(response.error.componentIG ,response.error.descripcion, terms)
                            : translate('Global','Error', terms) + `(${response.error.codigo} - ${response.error.descripcion})`,
                        variant: "error"
                    }
                });
                setShowMsgRefCatastralNotFound(true);
            } else {
                if(response.inmueble === null){
                    setShowMsgRefCatastralNotFound(true);
                }
                onChangeInmueble(response.inmueble)
            }
        }
        setLoading(false)
        setLoadingMsg(undefined)

    }
    /** ------------------------------------------------------------------------------------------------------*/
    useEffect(() => {
        const err = ( 
                selectedMuni!== undefined && inmueble && inmueble.refCatastral!== undefined 
                && inmueble.direccion !== undefined 
                && !(inmueble.direccion.normalize('NFD').replace(/[\u0300-\u036f]/g,"").includes(selectedMuni.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g,"")))
            ) ? true : false
         
        getErrDiffMunis(err)
        setRefCat(inmueble?.refCatastral ?? undefined)
    },[selectedMuni, inmueble])

    return(
        <Grid container>
            <FullLoading loading={loading} msg={loadingMSG}/>
            <Grid item xs={12} md={12} lg={6} container direction='column'>
                {labelWithInfo.municipio && 
                    <LabelWithInfo 
                        fieldTerm={labelWithInfo.municipio.labelTermIg} 
                        componentIG={labelWithInfo.municipio.componenteIG} 
                        moreInfo={labelWithInfo.municipio.infoContent ? {template: labelWithInfo.municipio.infoContent, size: 'xs'}: undefined} />
                }
                <InputSelect 
                    fieldTerm ="municipioSelector"
                    componentIG="Global"
                    value={selectedMuni}
                    options={muniOptions}
                    required
                    onChangeValue={(v: TSelect | undefined,err: boolean)=> {
                        onChangeMuni(v,err) 
                        onChangeInmueble(null);
                        setRefCat(undefined)
                    }}
                />
                <>
                    {labelWithInfo.refCatastral && 
                        <LabelWithInfo 
                            fieldTerm={labelWithInfo.refCatastral.labelTermIg} 
                            componentIG={labelWithInfo.refCatastral.componenteIG} 
                            moreInfo={labelWithInfo.refCatastral.infoContent ? {template: labelWithInfo.refCatastral.infoContent, size: 'xs'}: undefined} />
                    }
                    <InputSearch 
                        fieldTerm='refCatastral' 
                        required
                        disabled={!selectedMuni}

                        value={refCat} 
                        onChangeValue={(ref:string|undefined, err:boolean) =>{ 
                            setRefCat(ref); 
                            getErrRefCat(err);
                            onChangeInmueble(null);
                            setShowMsgRefCatastralNotFound(false);
                        }}
                        onSearch={() =>{ 
                            getInfoInmueble(refCat)
                        }}
                        extraValidation={validateRefCat}
                        helperText={translate('Global','helper_text_refCatastral',terms)}
                        error={showMsgRefCatastralNotFound}
                        errorMsgExterno={translate('Global','refCatastro_field_notFound', terms)}
                    />
                </>
            </Grid>
            <Grid item xs={12} md={12} lg={6} style={{alignContent:'center'}}>
                {inmueble && 
                    <InmuebleInfo
                        refCatastral={inmueble.refCatastral} 
                        urlCatastro={inmueble.urlCatastro}
                        urlGoogle={inmueble.urlGoogle}
                        domicilio={inmueble.direccion}
                        tipoBien={inmueble.tipoBien}
                        uso={inmueble.datosEconomicos?.uso}
                    />
                }
            </Grid>
        </Grid>
    )
}

export default MuniAndRefCatInfo;