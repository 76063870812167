import { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, IconButton, Typography, WithStyles, withStyles } from "@material-ui/core";
import { IPlusvalVendedor, IVendedor, TDatosM081, TPlusvOperacion } from "containers/DUTI/steps/types";
import { TSelect } from "containers/DUTI/utils";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import DUTIGateway from "gateways/duti.gateway";
import { translate } from "utils/i18n";
import InputDate from "./input-date";
import InputSelect from "./input-select";
import InputText from "./input-text";
import TypedInputNumber from "./typed-input-number";
import duti_styles from "containers/DUTI/styles";
import ModalPlusvalM081 from "./modal-plusval-m081";
import FullLoading from "../full-loading";
import LabelWithInfo from "./label-with-info";
import { IDocumentoAtib } from "gateways/duti.interface";
import { DocumentsUtils } from "utils/documents";
import CheckBox from "./check-box";
import { Icon } from "@mdi/react";
import { mdiEyeCircleOutline, mdiPaperclip, mdiTrashCan } from "@mdi/js";
import { BLUE } from "containers/shared/colors";
import { CustomError } from "utils/custom.error";
import usePage from "hooks/page.hook";
import AttachFileComponent from "components/attach-file-component";
import { DocumentosGateway } from "gateways/documentos.gateway";
import ModalAdjuntarEscrPublica from "components/modal-ajuntar-escr-publica";
import { curry } from "lodash";

export type TTipoPlusval= 'declaracion' | 'autoliquidacion' | 'comunicacion'
interface ICProps extends WithStyles {
    idDeclaracion: number,
    p_item: IPlusvalVendedor, 
    infoVendedor: IVendedor|undefined,
    tipoPlusval: TTipoPlusval,
    sujetoPasivo:boolean,
    porcentaje: number,
    comprSelect: TSelect[],
    valorDeclarado: number,
    fechaLimite: Date,
    idGestion: number | null,
    idGestionSujeto: number | null,
    onCancel: () => void, 
    onSave: (v:IPlusvalVendedor) => void,
    setIdGestion: (id: number | null) => void,
    setIdGestionSujeto: (id: number | null, nifSujetoPasivo: string) => void,
    setShouldBlock: (v: boolean) => void,
}
const EMPTY_ERROR = {
    nifVendedor: false,
    operaciones: [],
    sujetoPasivo: false,
    nifSujetoPasivo: false,
}

const PlusvalVendedorForm : FC<ICProps>= ({
    classes, idDeclaracion,
    p_item, infoVendedor,
    tipoPlusval, sujetoPasivo,
    porcentaje, comprSelect,
    valorDeclarado, fechaLimite,
    idGestion, idGestionSujeto,
    onCancel, onSave, setShouldBlock,
    setIdGestion, setIdGestionSujeto,    
}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext)
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    
    const componentMounted = useRef(true);
    const[loading, setLoading]= useState(false);
    const[showModalCalculo, setShowModalCalculo]= useState(false);
    const[showMSGPorcentaje, setShowMSGPorcentaje]= useState(false);
    const[showMSGReqValAnt, setShowMSGReqValAnt]= useState(false);
    const[showAddDocs, setShowAddDocs] = useState(false);

    const [modalAdjuntar, setModalAdjuntar] = useState({open:false, docs:[] as IDocumentoAtib[], opIdx: -1});

    const [errAdjuntar, setErrAdjuntar] = useState(false);
    const [, setErrorAttaching] = useState(false);
    const [errorDecl, setErrorDecl] = useState(false);
    //const [attachedDoc, setAttachedDoc]= useState<{opIdx: number, doc: File| null}[]>([]);
    const [atibDoc, setAtibDoc]= useState< {opIdx: number, doc: IDocumentoAtib| null}[]>([]);
    const [readyToSave, setReadyToSave] = useState(false);

    const [ item ,setItem] = useState<IPlusvalVendedor>({...p_item})
    const [ itemError,setItemError] = useState({
        ...EMPTY_ERROR, 
        operaciones: new Array(p_item.operaciones.length).fill({
            fechaAnteriorAdquisicion: false, 
            porcentajeAnteriorAdquisicion: false, 
            importeAnteriorAdquisicion: false})
    })

    const nifSujetoPasivo =useMemo(() => {
        if(infoVendedor && p_item.nifSujetoPasivo === infoVendedor.nif){
            return infoVendedor.nomApe + ' - ' +infoVendedor.nif
        } else {
            return p_item.nifSujetoPasivo
        }
    }, [infoVendedor,p_item]);

    
    const allAttached = useMemo(() => {
        const allTRUE = item.operaciones.map(o => o.idEscrituraAnterior!== undefined)
        return allTRUE.includes(false) ? false : true
    },[item.operaciones])

    const onValidateIfReqDoc = async ( data: IPlusvalVendedor ) => {
        setLoading(true)
        if(
            data.modelo081 !== undefined 
            && data.modelo081.importeMetodoReal !== null 
             && ( data.modelo081.importeMetodoReal < data.modelo081.importeMetodoObjetivo
                 || (data.modelo081.importeMetodoReal === data.modelo081.importeMetodoObjetivo && data.modelo081.importeMetodoObjetivo === 0)
             )
        ){
            // aplica método real
            setReadyToSave(true)
            setShowAddDocs(true)
            const att = data.operaciones.map( (o,idx) => ({opIdx: idx, doc: null}))
            //setAttachedDoc(att)
            setAtibDoc(att)
            setLoading(false)
        } else {
            // not required attach
            await deleteDocsIfChange()
            onSave({...data})
            setShowAddDocs(false)
            setLoading(false)
            
        }
    }

    // Validacion para M081 con método reeal
    // Valida que las operaciones tienen docEscrt adjunto y chcek declResponsable esta activo
    const onConfirmAndSave = async () => {
        // validar attach - es método real
        const oper = item.operaciones.map( o => o.idEscrituraAnterior !== undefined)
        console.log('onConfirmAndSave ', oper)
        if(oper.includes(false)){
            setErrAdjuntar(true)
        } else {
            if(item.modelo081?.declaracionResponsable === true){
                await deleteDocsIfChange()
                onSave({...item})
            } else {
                setErrorDecl(true)
            }
        }
    }

    // Valida que todas las operaciones tienen Fecha y Porcentaje (y no suma más de 100)
    // Valida que todas o ninguna tengan valor anterior adquisición
    const validateDatosOperaciones = () => {
        let isValid = true
        const objErr = {
            nifSujetoPasivo: itemError.nifSujetoPasivo ||(!item.nifSujetoPasivo || item.nifSujetoPasivo === ""), 
            nifVendedor: itemError.nifVendedor, 
            operaciones: (itemError.operaciones.map( oper => Object.values(oper).includes(true))).includes(true)
        }
        
        if((!item.nifSujetoPasivo || item.nifSujetoPasivo === "")){
            setItemError(curr =>({...curr, nifSujetoPasivo: true}))
        }
        
        if(!(Object.values(objErr).includes(true)) ){
            let sumaPorcentaje= 0
            //console.log('validate sum %', porcentaje)
            item.operaciones.forEach(op => {
                //console.log(' -- a sumar: ', op.porcentajeAnteriorAdquisicion ?? 0)
                sumaPorcentaje= sumaPorcentaje+(op.porcentajeAnteriorAdquisicion ?? 0)
            });
            sumaPorcentaje= (Math.round(sumaPorcentaje)*100) / 100
            //console.log('suma total:', sumaPorcentaje)
            setShowMSGPorcentaje(porcentaje !== sumaPorcentaje);

            // validar que operaciones actuales - ok
            // Incluy.Calc real - todos importes anteriores deben estar insertados
            const importeAntAdq = item.operaciones.map( op => (op.importeAnteriorAdquisicion !== undefined && op.importeAnteriorAdquisicion>0  ))
            let newErrOP = itemError.operaciones.map( 
                (op,index) =>{  
                    return ({
                        fechaAnteriorAdquisicion: item.operaciones[index].fechaAnteriorAdquisicion === undefined,
                        porcentajeAnteriorAdquisicion: item.operaciones[index].porcentajeAnteriorAdquisicion === undefined || item.operaciones[index].porcentajeAnteriorAdquisicion === 0 || porcentaje !== sumaPorcentaje, 
                        importeAnteriorAdquisicion: importeAntAdq.includes(true) && importeAntAdq.includes(false) && (
                            item.operaciones[index].importeAnteriorAdquisicion === undefined ||( item.operaciones[index].importeAnteriorAdquisicion !== undefined && item.operaciones[index].importeAnteriorAdquisicion === 0)
                        ),
                    })
                })            
            setItemError({...itemError, operaciones: newErrOP})
            setShowMSGReqValAnt(importeAntAdq.includes(true) && importeAntAdq.includes(false))
            
            if(!(newErrOP.map(oper => Object.values(oper).includes(true))).includes(true) ){
               isValid=true
            } else {
                isValid=false
            }
        } else {
            isValid=false
        }

        return isValid;
    }

    const deleteDocsIfChange = async() => {
        // Comparar si docs actuales son los iniciales
        const currDoc=item.operaciones.map( o => o.idEscrituraAnterior ).filter( o => o!== undefined).filter( o=> o!=='')
        const oldDoc=p_item.operaciones.map( o => o.idEscrituraAnterior ).filter( o => o!== undefined).filter( o=> o!=='')
        const deleteDocs = oldDoc.map( d => currDoc.includes(d) ? null : d).filter(d => d!== null)
        //console.log('deleteDocs',deleteDocs,  oldDoc, currDoc)
        if(deleteDocs.length>0){
            //console.log('delete DOCS ', deleteDocs)
            await Promise.all(
                deleteDocs.map( async id => await dutiGateway.eliminarDocumento(idDeclaracion, id))
            )  
        }
    }
    // Click botón continuar - Autosave si no es M081, sino abre modal calculo M081
    const onClickSave = async () => {
        //console.log('onClickSave ')
        setLoading(true)
        if(tipoPlusval === 'autoliquidacion' || tipoPlusval === 'declaracion'){
            const isOK = validateDatosOperaciones()
            if(isOK && tipoPlusval === 'autoliquidacion'){
               setShowModalCalculo(true)
            }
            if(isOK && tipoPlusval === 'declaracion'){
                const AdqTotal = (item.operaciones.map( op =>  op.importeAnteriorAdquisicion ?? 0)).reduce( (valorAnterior, valorActual) => (valorAnterior + valorActual),0 );
                await deleteDocsIfChange()
                onSave({
                    ...item,
                    modelo080: { 
                        nifSujetoPasivo: item.nifSujetoPasivo,
                        fechaDocAdquisicion: item.operaciones.length === 1 ? item.operaciones[0].fechaAnteriorAdquisicion : undefined,
                        fechaPresentacion: undefined,
                        otrosSupuestos: undefined,
                        valorAdquisicion: AdqTotal,
                        valorTransmision: valorDeclarado,
                        tipoDocAdquisicion: undefined,
                    }
                })
            }
        } else {
            if(!item.nifSujetoPasivo ||item.nifSujetoPasivo === "" ){
                setItemError({...itemError, nifSujetoPasivo: true})
            } else {
                await deleteDocsIfChange()
                onSave({
                    ...item,
                    modelo082: { nifSujetoPasivo: item.nifSujetoPasivo}
                })  
            }
        }
        
        setLoading(false)
    }

    /** ------------------------------------------------------------     */


    const handleDelete = async (opIdx: number) => {
        setLoading(true);
        try {                        
            //const attDoc = attachedDoc.map( a => a.opIdx === opIdx ? {...a, doc:null}: {...a});
            const atib = atibDoc.map( at => at.opIdx === opIdx ? {...at, doc:null}: {...at});
            //setAttachedDoc(attDoc); 
            setAtibDoc(atib); 
            
            const operacion = item.operaciones.find((o,idx) => idx === opIdx);
            let newOP: TPlusvOperacion[] | null = null

            if (operacion && operacion.idEscrituraAnterior) {
                await dutiGateway.eliminarDocumento(idDeclaracion, operacion.idEscrituraAnterior);
                newOP = item.operaciones.map((o,idx) => (
                    idx === opIdx ? {...o,idEscrituraAnterior: undefined }
                    : {...o}
                ));
            }
            setItem(curr => ({
                ...curr,
                operaciones: newOP ?? curr.operaciones,
                modelo081: {...curr.modelo081, declaracionResponsable: false} as TDatosM081
            }));
            
            setErrAdjuntar(false);
        } catch (error) {
            if (error instanceof CustomError && error.code === 403) {
                setShouldBlock(false);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Global', 'sesion_caducada', terms),
                        variant: 'warning',
                        hasCustomAction: true,
                        handleCustomAction: () => { 
                            pageDispatcher({ type: "logout" });
                        }
                    }
                });
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('DUTI','error_dusti', terms),
                        variant: "error"
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const showDoc = async(idDoc:string) => {
        try {
            setLoading(true)
            DocumentsUtils.downloadDoc(idDoc)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'can_not_show_docuemnta', terms),
                    variant: 'error',
                }
            });
        }
    }
    /** ------------------------------------------------------------     */

    return(
        <>
        <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
        <div>
            { sujetoPasivo 
                ? <InputText
                        required 
                        disabled
                        fieldTerm='nifSujetoPasivo'
                        value = {nifSujetoPasivo }// item.nifVendedor}
                        onChangeValue= {(v: TSelect, err: boolean) => {}}
                        className={classes.input545}
                    />
                :  <InputSelect
                        required
                        fieldTerm='nifSujetoPasivo'
                        value={comprSelect.find(c => c.id === item.nifSujetoPasivo)}
                        options={comprSelect}
                        onChangeValue= {(v: TSelect, err: boolean) => {
                            item.nifSujetoPasivo = v.id;
                            item.modelo081?.nifSujetoPasivo && (item.modelo081.nifSujetoPasivo = v.id);
                            setItem(item);
                            setItemError({...itemError, nifSujetoPasivo: err})
                        }}
                        className={classes.input545}
                        error={itemError.nifSujetoPasivo}
                    />
            }
          
            {/** Calculo Real/Objetivo -  operaciones */}
            {tipoPlusval !== 'comunicacion' &&  
                <Grid container direction='row' style={{display:'flex'}} >
                    <Grid item xs={12} md={12} lg={12} xl={9} className={classes.column}>
                        <LabelWithInfo type="text" fieldTerm={'plusvalVendOperaciones'} moreInfo={{template: 'info_field_plusvalVendOperaciones',size:'xs'}} />
                        <div style={{ height: 35 }}>
                        {(showMSGPorcentaje || showMSGReqValAnt) && 
                            <Typography color='error'>{translate('DUTI',showMSGPorcentaje?'operaciones_percentaje_not_sum_100': 'operaciones_not_all_importe_anterior',terms)}</Typography>
                        }
                        </div>

                        { item.operaciones.map( (op, opIdx) => {
                            //console.log('map ', atibDoc)
                            //const map_attach= attachedDoc.find( a => a.opIdx === opIdx)
                            const map_docAtib= atibDoc.find( doc => doc.opIdx === opIdx)
                            //console.log('map_docAtib ', map_docAtib)
                            return(
                                <div key={`edt_${opIdx}`} className={classes.row}>
                                    <InputDate 
                                        key='edt_fechaAnteriorAdquisicion'
                                        fieldTerm={"fechaAnteriorAdquisicion"}
                                        value = {op.fechaAnteriorAdquisicion} 
                                        required = {true}
                                        maxDate={ fechaLimite }
                                        onChangeValue={(v: Date|undefined,err:boolean) => {
                                            const editOp = item.operaciones.map( (i, idx) => idx===opIdx ? ({ ...i, fechaAnteriorAdquisicion:v}): ({...i}))
                                            const editOpErr = itemError?.operaciones.map( (i, idx) => idx===opIdx ? ({ ...i, fechaAnteriorAdquisicion:err}): ({...i}))
                                            setItem({...item, operaciones: editOp})
                                            setItemError({...itemError, operaciones: editOpErr})
                                        }}
                                        error={itemError.operaciones[opIdx].fechaAnteriorAdquisicion}
                                    />
                                    
                                    <TypedInputNumber 
                                        key='edt_porcentajeAnteriorAdquisicion'
                                        type="percentaje"
                                        required={true}
                                        allowZero={false}
                                        maxDecimal={2}
                                        fieldTerm="porcentajeAnteriorAdquisicion"
                                        value={ op.porcentajeAnteriorAdquisicion ?? 0 }
                                        onChangeValue={(v, err) => {
                                            setShowMSGPorcentaje(false)
                                            const editOp = item.operaciones.map( (i, idx) => idx===opIdx ? ({ ...i, porcentajeAnteriorAdquisicion:v}): ({...i}))
                                            const editOpErr = itemError?.operaciones.map( (i, idx) => idx===opIdx ? ({ ...i, porcentajeAnteriorAdquisicion:err}): ({...i, porcentajeAnteriorAdquisicion:false}))
                                            setItem({...item, operaciones: editOp})
                                            setItemError({...itemError, operaciones: editOpErr})
                                        }}
                                        error={itemError.operaciones[opIdx].porcentajeAnteriorAdquisicion}
                                        className={classes.input200} 
                                    />
                                    <TypedInputNumber
                                        key='edt_importeAnteriorAdquisicion'
                                        type="money"
                                        required={false}
                                        allowZero={true}
                                        min={0}
                                        maxDecimal={2}
                                        fieldTerm="importeAnteriorAdquisicion"
                                        value={ op.importeAnteriorAdquisicion ?? 0 }
                                        onChangeValue={(v, err) => {
                                            const editOp = item.operaciones.map( (i, idx) => idx===opIdx ? ({ ...i, importeAnteriorAdquisicion: v && v > 0 ? v : undefined}): ({...i}))
                                            const editOpErr = itemError?.operaciones.map( (i, idx) => idx===opIdx ? ({ ...i, importeAnteriorAdquisicion:err}): ({...i}))
                                            setItem({...item, operaciones: editOp})
                                            setItemError({...itemError, operaciones: editOpErr})
                                        }}
                                        error={itemError.operaciones[opIdx].importeAnteriorAdquisicion}
                                        className={classes.input200} 
                                    />
                                    {showAddDocs && 
 
                                        <Grid item container direction='column' alignItems="flex-start">
                                            <Typography 
                                                variant="body2" 
                                                color={errAdjuntar && !(map_docAtib?.doc) ? 'error': 'inherit'} 
                                                style={{marginRight: 30, fontWeight: 'bolder'}}
                                            >
                                                    {translate('DUTI','attachEscritura',terms).toUpperCase()}
                                            </Typography>
                                            {map_docAtib?.doc ?
                                                <div className={classes.row} style={{ display:'flex', alignItems: 'center'}}>
                                                    <Typography variant="body1" style={{marginRight: 20}}>
                                                        {  map_docAtib ? map_docAtib.doc?.NombreFichero: ' - '}
                                                    </Typography>
                                                    <IconButton onClick={() => op.idEscrituraAnterior && showDoc(op.idEscrituraAnterior)}>
                                                        <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleDelete(opIdx)}
                                                        style={{padding: 5}} 
                                                    >
                                                        <Icon path={mdiTrashCan} size={1} color='red' />
                                                    </IconButton>
                                                </div> 
                                            :  
                                                <Button variant="outlined" 
                                                    color='primary'
                                                    size="small"
                                                    classes={{startIcon: classes.starIcon}}
                                                    style={{marginRight:0}}
                                                    startIcon={ <Icon path={mdiPaperclip} size={1} color={BLUE} /> }
                                                    onClick={() => setModalAdjuntar( curr => ({...curr, open:true, opIdx: opIdx, docs: map_docAtib && map_docAtib.doc ? [map_docAtib.doc] : []}))}
                                                >
                                                    {translate('DUTI','btn_attach',terms)}
                                                </Button>
                                            }
                                        </Grid>
                                       

                                        
                                    }
                                </div>
                            )
                        })}
                    </Grid>
                    {showAddDocs && 
                        <Grid item>
                            <CheckBox disabled={ !allAttached } 
                                fieldTerm={'declaracionResponsable'}
                                value={item.modelo081?.declaracionResponsable} 
                                onChangeValue={(v:boolean)=>{ 
                                    setErrorDecl(false)
                                    setItem(curr => ({
                                        ...curr,
                                        modelo081: {...curr.modelo081, declaracionResponsable: v} as TDatosM081
                                    })) 
                                }}
                                error={errorDecl}
                                errorMsg={translate('DUTI', 'requiere_declaracionResponsable',terms)}
                            />              
                        </Grid>
                    }
                </Grid>
            }

            <>
                <Button onClick={onCancel} variant="contained" style={{marginRight: 20}}>
                    {translate('DUTI', 'btn_cancel',terms)}
                </Button>
                {tipoPlusval ==='autoliquidacion' ? 
                    <>
                        { readyToSave ? 
                            <Button 
                                variant="contained" 
                                color='primary' 
                                onClick={onConfirmAndSave} 
                            >
                                {translate('DUTI', 'btn_save',terms)}
                            </Button>  
                        : 
                            <Button variant="contained" color='primary' onClick={onClickSave} >
                                {translate('DUTI', 'btn_continuar',terms)}
                            </Button>
                     } 
                    </>
                :
                    <Button variant="contained" color='primary'  onClick={onClickSave} >
                        {translate('DUTI', 'btn_save',terms)}
                    </Button>  
                }
            </>
        </div>

        {showModalCalculo && 
            <ModalPlusvalM081
                idDeclaracion={idDeclaracion}
                idGestion={idGestion}
                idGestionSujeto={idGestionSujeto}
                info={item}
                show={showModalCalculo}
                //withCalcReal={ item.modelo081 && item.modelo081.totalIngresar === item.modelo081.importeMetodoReal}
                //showImporteReal={ item.operaciones[0].importeAnteriorAdquisicion !== undefined }
                infoVendedor={ {titularidad:infoVendedor?.porcentaje ?? 0, nif: infoVendedor?.nif ?? ' - ', nomApe: infoVendedor?.nomApe ?? ' - '}}
                //itemModelo={ item.modelo081 }
                onConfirm={ (itemfinal: IPlusvalVendedor) => {
                    //setReadyToSave(true)
                    setItem(curr => ({...itemfinal}))
                    onValidateIfReqDoc({...itemfinal})
                    setShowModalCalculo(false)
                }}
                onCancel={() => setShowModalCalculo(false)}
                setIdGestion={(id: number) => setIdGestion(id)}
                setIdGestionSujeto={(id: number, nifSujetoPasivo: string) => setIdGestionSujeto(id, nifSujetoPasivo)}
            />
        }

        <ModalAdjuntarEscrPublica
            open={modalAdjuntar.open}
            buttonAdjuntar={{componentIG:'DUTI', term:'attachEscritura'}}
            // Props AttachFileComponent
            docs={modalAdjuntar.docs}
            saveDocsAttached={ (savedDocs: IDocumentoAtib[]) => { 
                console.log('saveDocsAttached docs', savedDocs)
                if(savedDocs.length>0 ){
                    const newAttach = atibDoc.map((o,idx) => idx===modalAdjuntar.opIdx ? ({...o, doc: savedDocs[0]}): ({...o}));
                    const newOP = item.operaciones.map((o,idx) => idx===modalAdjuntar.opIdx ? ({...o, idEscrituraAnterior: savedDocs[0].IdDocumento}): ({...o}));
                    console.log('newOP1', newOP, 'newAttach1', newAttach)
                    setItem({...item, 
                        operaciones: newOP,
                    });
                    setAtibDoc(newAttach);
                } else {  
                    const newAttach = atibDoc.map((o,idx) => idx===modalAdjuntar.opIdx ? {...o, doc: null}: {...o});
                    const newOP = item.operaciones.map((o,idx) => idx===modalAdjuntar.opIdx ? {...o, idEscrituraAnterior: undefined}: {...o});
                    console.log('newOP', newOP, 'newAttach', newAttach)  
                    setItem({...item, 
                        operaciones: newOP,
                        modelo081: {...item.modelo081, declaracionResponsable: false} as TDatosM081
                    });
                    setAtibDoc(newAttach);
                }
            }}
            docSource='duti'
            idDeclaracion={idDeclaracion}
            tipoDocDUTI={"Escritura_Publica_anterior"}
            tipoModeloDUTI={'081'}
            onClose={()=> setModalAdjuntar(curr => ({...curr, open:false, opIdx:-1, docs: []}))}
        />
        </>
    )
}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(PlusvalVendedorForm));
