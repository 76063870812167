// LIBRARY IMPORTS
import { FC, useEffect, useContext, useMemo, useState } from 'react';
import { Button, Card, CircularProgress, Typography, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { mdiBank } from '@mdi/js';
import * as Sentry from '@sentry/browser';
// LOCAL IMPORTS
import { IParamsPagoCarta, IStateHistory } from 'hooks/domiciliaciones/altas-domiciliacion.hook';
import ConfirmDialog from 'containers/perfil/modals/confirmacion.modal';
import { LiteralsContext } from 'containers/shared/literals';
import {IPagoCartaAlta } from 'gateways/tributos.interfaces';
import { TributosGateway } from 'gateways/tributos.gateway';
import { AlertsContext } from 'contexts/alerts.context';
import { StaticContext } from 'react-router';
import { download } from 'utils/download';
import { translate } from 'utils/i18n';
import useLoadContentHook from 'hooks/load-content.hook';
import ConceptoIcon from 'components/concepto.icon';
import useSetHeader from 'hooks/set-header.hook';
import IoC from 'contexts/ioc.context';
import usePage from 'hooks/page.hook';
import Term from 'components/term';
// STYLES IMPORTS
import styles from './domiciliacion.styles';

interface IStatePagoCartaHistory extends IStateHistory {
    pagoCarta: true,
    pagoCartaInfo: IParamsPagoCarta 
}

const PagoCartaConfirmacion: FC<WithStyles<typeof styles> & RouteComponentProps<{},StaticContext,IStatePagoCartaHistory>> = ({ history, classes, location }) => {
    const state  = location.state;
    // HOOKS
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    const [pageState, pageDispatcher] = usePage();
    const { body } = useLoadContentHook({ contentName: 'confirmacion-pago-carta', lang: pageState.lang });
    // GATEWAYS 
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    // STATES
    const [loading, setLoading] = useState(false);
    const [pendiente, setPendiente] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    // HEADER
    useSetHeader({ title: <Term component="Domiciliaciones" text="Confirmación pago a la carta" />, icon: mdiBank, menu: true });

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const pendienteDto: boolean = await gateway.checkPendientes(state.pagoCartaInfo.tributos.map((x) => x.idMuni));
                setPendiente(pendienteDto)
            }
            catch (e) {
                pageDispatcher({
                    type: 'show-notification',
                    payload: {
                        message: (e as any).message,
                        variant: 'error',
                    }
                });
            }
            finally { setLoading(false); }
        })();
    }, []);

    const handleOpenDialog = () => {
        setDialogOpen(true)
    };

    const handleCloseDialog = () => {
        setDialogOpen(false)
    };

    const handleCancelar = () => {
        history.push('/cartero-virtual/domiciliaciones/domiciliables')
    };

    const handleFinalizar = async () => {
        try {
            if(state.pagoCartaInfo.convenio === undefined){
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Domiciliaciones', 'Error convenio no encontrado', terms),
                        variant: 'error',
                    }
                })
            } else {
                let dtoPagoCarta: IPagoCartaAlta = {
                    idConvenio: state.pagoCartaInfo.convenio.ID_CONV,
                    iban: state.pagoCartaInfo.iban,
                    idRecibo: state.tributos.map(x => { return x.idRecibo }),
                    isExtranjero: state.pagoCartaInfo.isExtranjero,
                    datosExtranjero: state.pagoCartaInfo.datosExtranjero ?? undefined
                }
                await gateway.altaPagoCarta(dtoPagoCarta)
    
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Domiciliaciones', 'Finalizado pago a la carta', terms),
                        variant: 'success',
                        hasCustomAction: true,
                        actionTemplateName: "Descargar justificante",
                        handleCustomAction: handleDownloadJustificante
                    }
                });
                history.push('/cartero-virtual/domiciliaciones/domiciliados/pago-carta')

            }
        } catch (e) {
            Sentry.captureException(e);
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Domiciliaciones', 'Error inesperado', terms),
                    variant: 'error',

                }
            });
        }
    };

    const handleDownloadJustificante = async () => {
        if (!state.tributos || state.tributos.length < 1) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Domiciliaciones', 'Ha ocurrido un error al generar el justificante de pago a la carta', terms),
                    variant: 'error',
                }
            });
            return;
        }

        const result = await gateway.getJustificantePagoCarta(state.tributos[0].idRecibo);

        download(result, alertsDispatch,
            translate('Domiciliaciones', 'Ha ocurrido un error al generar el justificante de pago a la carta', terms),
            translate('Global', 'BloqueoPantalla', terms)
        );

        history.push('/cartero-virtual/domiciliaciones/domiciliados/pago-carta')
    };

    if (loading) return (<CircularProgress size={40} />);

    return (
        <>
            <div className={classes.w100}>
                <Card className={classes.cardSpacing}>
                    <>
                        <Typography className={[classes.titleTextCard, classes.bold, classes.mBottom40].join(' ')}>
                            <Term component={"Global"} text={"Resumen pago a la carta"} />
                        </Typography>

                        <div className={[classes.gap20, classes.grid].join(' ')} style={{ gridTemplateRows: '30px auto' }}>
                            <div className={[classes.borderBottomGrey, classes.flexRow].join(' ')}>
                                <Typography className={[classes.subtitleText, classes.flex1].join(' ')}>
                                    <Term component="Domiciliaciones" text="Datos del sujeto" />
                                </Typography>
                                <Typography className={[classes.subtitleText,classes.flex1].join(' ')}>
                                    <Term component="Domiciliaciones" text="Datos de la domiciliación" />
                                </Typography>
                            </div>

                            <div className={classes.flexRow}>
                                <div className={[classes.bold, classes.flex1, classes.flexColumn].join(' ')}>
                                    <Typography > D.N.I / N.I.F / N.I.E: <b>{pageState?.jwp?.nif}</b> </Typography>
                                    <Typography > <Term component="Global" text="Nombre" />: <b>{pageState?.jwp?.fullname}</b> </Typography>
                                </div>
                                <div className={[classes.bold, classes.flex1, classes.flexColumn].join(' ')}>
                                    <Typography > D.N.I / N.I.F / N.I.E: <b>{state.pagoCartaInfo.nif}</b> </Typography>
                                    <Typography >  <Term component="Notificaciones" text="Titular" />: <b>{state.pagoCartaInfo.nombre}</b> </Typography>
                                    <Typography > IBAN: <b>{state.pagoCartaInfo.iban}</b> </Typography>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.borderBottomGrey, classes.flexRow, classes.mTop40].join(' ')}>
                            <Typography className={[classes.subtitleText,classes.bold, classes.flex1].join(' ')}>
                                <Term component="Domiciliaciones" text="Deuda seleccionada" />
                            </Typography>

                        </div>
                        {state.pagoCartaInfo.tributos.map((tributo) => {
                            return (
                                <div key={tributo.idRecibo} className={[classes.flexRow, classes.alignCenter, classes.flexNoWrap, classes.m20, classes.relative].join(' ')}>
                                    <div className={classes.imagenContainer}>
                                        <ConceptoIcon concepto={tributo.idTipo}></ConceptoIcon>
                                    </div>
                                    <div className={[classes.flexColumn, classes.m20].join(' ')}>
                                        <Typography style={{ fontWeight: 'bold' }}>{tributo.muni}</Typography>
                                        <Typography style={{ fontWeight: 'bold' }}>{tributo.descri}</Typography>
                                        <Typography style={{ fontWeight: 'bold' }}>{tributo.Obj}</Typography>
                                        <Typography style={{ fontWeight: 'bold' }}>{tributo.DireccionTributaria}</Typography>
                                    </div>
                                </div>
                            )
                        })}

                        <div className={classes.mBottom40}>
                            <div className={[classes.borderBottomGrey, classes.flexRow, classes.mTop40].join(' ')}>
                                <Typography className={[classes.subtitleText,classes.bold, classes.flex1].join(' ')}>
                                    <Term component="Domiciliaciones" text="Detalles pago" />
                                </Typography>

                            </div>
                            <div className={[classes.bold, classes.flex1, classes.flexColumn].join(' ')}>
                                <Typography className={classes.mTop20}>
                                    <Term component="Domiciliaciones" text="Modalidad de pago a la carta" />: <b>{state.pagoCartaInfo.convenio?.PLAZOS}</b>
                                </Typography>
                            </div>
                        </div>
                        {pendiente &&
                            <div className={[classes.bold, classes.flex1, classes.flexColumn].join(' ')}>
                                <Typography style={{ marginTop: 20, color: 'red' }}>
                                    <Term component="Domiciliaciones" text="Aviso recibos pendientes" />
                                </Typography>
                            </div>
                        }
                        <div className={[classes.w100, classes.flexRow, classes.justifyEnd, classes.gap20].join(' ')}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonCancel}
                                onClick={handleCancelar}
                            >
                                <Term component="Global" text={"Cancelar"} />
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonSuccess}
                                onClick={handleOpenDialog}
                            >
                                <Term component="Global" text={"Finalizar"} />
                            </Button>
                        </div>
                    </>
                </Card>
            </div>
            <ConfirmDialog
                open={dialogOpen}
                text={body}
                type="H"
                onClose={handleCloseDialog}
                onConfirm={handleFinalizar}
                notificado={false}
            />
        </>
    )
}

export default withStyles(styles)(PagoCartaConfirmacion);