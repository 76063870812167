import { Reducer } from "react";

export interface IFiltersState {
    matricula?: string;
    fechaLimite?: Date;
    gravedad?: string[];
    estadoIdentificacion?: string[];
    estado?: string[];
    municipios?: string[];
    obligatoriedad?: string[] | null;
    idRemesa?: string | null;
    fechaRemesa?: Date | null;
    fechaLimiteDesde?: Date | null;
    fechaLimiteHasta?: Date | null;
    fechaMultaDesde?: Date | null;
    fechaMultaHasta?: Date | null;
    fileExtension?: 'csv' | 'xlsx';
    nifSujeto?: string;
}

export type FiltersAction =
    | { type: 'update-any', filters: Partial<IFiltersState> }
    | { type: 'update', filter: keyof IFiltersState, value: any }
    | { type: 'clear' }

const FiltersReducer: Reducer<IFiltersState, FiltersAction> = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case 'update-any':
            return { ...state, ...action.filters };
        case 'update':
            newState[action.filter] = action.value;
            return newState;
        case 'clear':
            for (const prop in newState) {
                if (prop in newState) {
                    (newState as any)[prop] = undefined;
                }
            }
            return newState;
        default: throw new Error('Unexpected action');
    }
};

export default FiltersReducer;