import React, { FC, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { DescripcioLiquidIVTM, ICampo, ICodConstruccionIVTM, ICodUsoVehicIVTM, IMarcaVehicIVTM, IModeloVehicIVTM, ISubUsosIVTM, TipoCampoWeb } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, IconButton, TextField, Tooltip, Typography} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, renderCampos,  identificativoFormatter, formatOptions, SelectoresInfo, GenericObject, getInitialObject, isSameData, mixData, TPeriodo, OPTIONS_trimestre, Option} from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { ISujeto } from 'gateways/perfil.interfaces';
import { GeoGateway } from 'gateways/geo.gateway';
import { Municipio, Provincia, Isla, Sigla} from 'gateways/geo.interface';
import { Alert } from 'react-bootstrap';
import usePage from 'hooks/page.hook';
import { cloneDeep } from 'lodash';
import { finished } from 'stream';
import Icon from '@mdi/react';
import { mdiMagnify, mdiSearchWeb } from '@mdi/js';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import { BLUE } from 'containers/shared/colors';



const useStyles = makeStyles(styles);

// campos según uso:
enum USOS {
    'AUTOBUSOS'= '344',
    'CAMIONS'= '345',
    'CICLOMOTORS'= '349',
    'MOTOCICLETES'= '348',
    'REMOLCS'= '347',
    'TRACTORS'= '346',
    'TURISMES' = '343'
}

const getCamposUSO = (uso: string |null | undefined): (keyof TGenericObjectVehiculo071)[] => {
    const out: (keyof TGenericObjectVehiculo071)[] = ['bastidor','marca','modeloVeh']
    switch (uso) {
        case  'AUTOBUSOS':
            out.push('plazas');
            break
        case 'CAMIONS': //return ['masaMaxCargVehi', 'masaVehiculo','carga'];
        case 'REMOLCS':
            out.push('masaMaxCargVehi');
            out.push('masaVehiculo');
            out.push('carga');
            break;
            //return ['masaMaxCargVehi', 'masaVehiculo','carga'];
        case 'CICLOMOTORS'://return ['cilindrada'];
        case 'MOTOCICLETES':
            out.push('cilindrada');
            break;
            //return ['cilindrada'];
        case 'TRACTORS': //return ['potenciaFi'];
        case 'TURISMES':
            out.push('potenciaFi');
            break;
            //return ['potenciaFi']; 
        default:
            break;
    }

    return out
}
const CAMPOS_MATRICULACION: (keyof TGenericObjectVehiculo071)[]= ['CL','codConstrucVehi','usoVehiculo','clase']
const CAMPOS_MATRICULA: (keyof TGenericObjectVehiculo071)[]= ['clase', 'bastidor','marca','modeloVeh', 'plazas', 'carga','potenciaFi','cilindrada']
const FORMULA_CARGA = '[37]-[38]-75'

type TClaseSubuso = ISubUsosIVTM & {label: string}

type TGenericObjectVehiculo071 = Record<
    'descripcio' |'CL'|'codConstrucVehi'|'clase'|'usoVehiculo'|'bastidor'|'marca'|'modeloVeh'|'potenciaFi'|'cilindrada'|'plazas' |'masaMaxCargVehi'|'masaVehiculo'|'carga'|
    'cuota'|'idImporte'|'baseISMM'|'acodmunige'|'ejercicio'|'matricula',
    { value: any; casilla: number; formula: string; tipo: TipoCampoWeb, valid?: boolean }
>; 

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXmlApartadosPrevios: GenericObject,
    datosXml: string | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
    withEjercicioPeriodo: boolean
}

const Vehiculo071: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXmlApartadosPrevios,
        datosXml,
        setFinished,
        handleSave,
        withEjercicioPeriodo
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    const [pageState, pageDispatcher] = usePage();

   
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<TGenericObjectVehiculo071| null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | undefined>(undefined);
    const [EMPTY_Object,setEmptyObject] = useState<TGenericObjectVehiculo071| null>(null);
    // const [camposRequiredKeys, setCamposRequiredKeys] = useState<(keyof TGenericObjectVehiculo071)[]>([]);
    const [selectoresInfo, setSelectoresInfo] = useState<SelectoresInfo| null>(null);
    const [marca, setMarca] = useState<Option | undefined>(undefined);
    // CL => CodContruccion + CodUso => define la clase (subuso)
    const [claseSubusos, setClaseSubusos] = useState([] as ISubUsosIVTM[])
    const [codContruccion, setCodConstruccion] = useState<ICodConstruccionIVTM[]>()
    const [codUso, setCodUso] = useState<ICodUsoVehicIVTM[]>([])
    // Habilita la consulta DGT => solo certificado o clave
    const isLogged = useMemo(() => {
        // console.log(pageState.jwp?.level)
        return pageState.jwp !== null && pageState.jwp.level>1
    },[pageState]);

    const {showCamposAltaMatric, showCampoMatricula, showCLCampos} = useMemo(() => {
        //console.log('memo data', data)
        if(!data || (data &&  (!data.descripcio.value || data.descripcio.value === ''))){
            return ({ showCamposAltaMatric:false, showCampoMatricula: false, showCLCampos: false}) 
        }
        if(data.descripcio.value === DescripcioLiquidIVTM.REHABILITACION || data.descripcio.value === DescripcioLiquidIVTM.ALTA_BAJA_TEMP){
            // Gestionar modalidad Auth/NoAuth para ofrecer buscador Matrícula
            return ({ showCamposAltaMatric:false, showCampoMatricula: true, showCLCampos: false})
        } else {
            let show = false;
            if(data.CL.value && data.CL.value.length === 4 && data.clase.value && data.usoVehiculo.value && data.codConstrucVehi.value){
                show=true
            }
            //console.log('memo ', show, data.CL.value.length )
            return ({ showCamposAltaMatric:true, showCampoMatricula: false, showCLCampos: show})
        }
    },[data])
     
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const evalCL = (code: string) => {
        //console.log( code,' => ', code.slice(0,2), code.slice(2,4) )
        const constr = codContruccion?.find( cd => cd.codigo === code.slice(0,2))
        const uso = codUso?.find( cd => cd.codigo === code.slice(2,4))
        //console.log('eval CL ', constr, uso)
        let clase = constr && constr.idSubuso ? claseSubusos?.find(c => c.idSubuso === constr.idSubuso): undefined
        if(constr && uso && (Number(constr.codigo)>19 && Number(constr.codigo)<44 ) && uso.codigo === '48'){
            // Exepcion camiones y remolques (codContruc: 20-43 ) y uso = 48 => se getsionan como TURISMOS 
            clase = claseSubusos?.find(c => c.nombre === "TURISMES")
        }
        return {
            codConstrucVehi: constr?.descripcion,
            usoVehiculo: uso?.descripcion,
            clase: clase?.nombre
        }
    };

    const cleanFields = (currData:TGenericObjectVehiculo071, fields: (keyof TGenericObjectVehiculo071)[]) => {
        let updated = {...currData}
        fields.forEach(k => {
            updated = {
                ...updated,
                [k]: {...updated[k], value: undefined}
            }

        });
        return updated
    };

    const updateVisibleCampos= (fields: (keyof TGenericObjectVehiculo071)[], visibleValue: boolean) => {
        setCampos(curr => (curr?.map(c => ({
            ...c, 
            visible: fields.includes(c.codigo as keyof TGenericObjectVehiculo071) ? visibleValue : c.visible,
            textActionProps: c.tipoCampoWeb === 'textAction' && (c.codigo as keyof TGenericObjectVehiculo071) ==='matricula' && visibleValue 
                ? {customAction: (v) =>onSearchMatricula(v), mdiIconAction: mdiMagnify, color: BLUE}
                : undefined
        }))) )
    }

    const updateOtherCampos= (field: keyof TGenericObjectVehiculo071, campoChanges: {key: keyof ICampo ,value: any}[]) => {
        setCampos(curr => (curr?.map(c => { 
            if(c.codigo ===field){
                let newC ={...c}
                campoChanges.forEach(el => {
                    if(el.key === 'formula'){
                        setData(curr => curr && ({
                            ...curr, 
                            [field]: {...curr[field], formula: el.value},
                            //value: field === 'carga' && curr['carga'].value <0 ? undefined : curr['carga'].value -----
                        }))
                    }
                    newC = {...newC, [el.key]: el.value }
                });
                // console.log('newcC ', newC)
                return newC
            } else {
                return c
            } 
        })))
    }

    const updateData = ( p_name: string, value: any) => {
        const FieldsCL :(keyof TGenericObjectVehiculo071)[]= ['bastidor','marca','modeloVeh','carga','cilindrada','plazas','potenciaFi','masaMaxCargVehi','masaVehiculo']
        const FieldsDescr :(keyof TGenericObjectVehiculo071)[]= ['clase','usoVehiculo','CL','codConstrucVehi']
        const FieldsAll:(keyof TGenericObjectVehiculo071)[]= FieldsDescr.concat(FieldsCL);
        FieldsAll.push('matricula')
        const name = p_name as keyof TGenericObjectVehiculo071;
        setChanges(true)
        let newData = {} as TGenericObjectVehiculo071;
        if(EMPTY_Object){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_Object,[name]: {...EMPTY_Object[name], value: value }}
        }

        if(newData && newData[name].tipo === 'exclusiveBool' && value === true){
            // 1. Obtener casillas que intervienen en la formula
            const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
            const casillas = newData[name].formula.match(myRegExp);
            //2. setear a falso todos los valores de la formula
            if(casillas){
                for (const key in newData) {
                    if (Object.prototype.hasOwnProperty.call(newData, key)) {
                        const element = newData[key as keyof TGenericObjectVehiculo071] ;
                        if(element && casillas.includes(element.casilla.toString())){
                            newData[key as keyof TGenericObjectVehiculo071] = {...newData[key as keyof TGenericObjectVehiculo071], value: false}
                        }
                        
                    }
                }
            }
        } 

        //Dependencia Marca/Modelo
        if( p_name === 'marca' && value){
            //console.log(' marca ', selectoresInfo?.marcaVehic?.find(m => m.nombre === value))
            setMarca(selectoresInfo?.marcaVehic?.find(m => m.nombre === value))
            newData= {...newData, modeloVeh: {...newData['modeloVeh'], value: undefined}}
        } 

        // Análisis CL 
        if( p_name === 'CL' ){
            // clean or update if CL completed (4dígitos)
            const result = value && value.length === 4 ? evalCL(value): undefined;
            if(value && value.length === 4 && (!result || !result.clase)){
                alertsDispatch({
                    type: 'show-alert',
                    payload:{
                        variant: 'warning',
                        message: translate('Tributos','warn_CL_not_subuso',terms)
                    }
                });
            }
            newData = cleanFields(newData,FieldsCL)
            newData = {
                ...newData,
                codConstrucVehi: {...newData['codConstrucVehi'], value: result?.codConstrucVehi ?? ''},
                usoVehiculo:{...newData['usoVehiculo'], value: result?.usoVehiculo ?? '' },
                clase:{...newData['clase'], value: result?.clase ?? ''}
            }

            
            
            // clean visibilidad campos
            if(!value || (value && value.length < 4)){ updateVisibleCampos(FieldsCL, false) }
        }
        // Cambio tipo de trámite
        if( p_name === 'descripcio'){
            newData = cleanFields(newData,FieldsAll)
            if(value === DescripcioLiquidIVTM.MATRICULACION){ 
                updateVisibleCampos(FieldsCL, false)
            } else{
                updateVisibleCampos(['masaMaxCargVehi', 'masaVehiculo'], false)
            }
        }
        
        setData(newData)
    }

    const onSave = ()=> { 
        (async() => {
            try {
                setLoading(true);
                // comprobar campos required 
                let valid= true;
                const currRequiredKeys= getKeysRequiredCampos( campos ?? []) as (keyof TGenericObjectVehiculo071)[]
                //console.log('on save currRequiredKeys', currRequiredKeys)
                if(currRequiredKeys && currRequiredKeys.length>0 ){
                    if(data){
                        currRequiredKeys.forEach( element => {
                            switch (data[element].tipo) {
                                case 'number':
                                    if(Number(data[element].value) === 0){
                                        valid=false
                                    }
                                    break;
                                case 'decimal':
                                case'decimal2':
                                case'decimal3':
                                    if(parseFloat(data[element].value) === 0){
                                        valid=false
                                    }
                                    break;
                                default:
                                    if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                        valid=false
                                    }
                                    break;
                            }
                        })
                    } else {
                        valid = false;
                    }
                }
                if (valid) {
                    valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
                }
                
                if(valid && data){
                    // Cálcular tasa y añadir campos (NO VISIBLES)
                    const claseItem= claseSubusos.find( c=> c.nombre === data?.clase.value)
                    const result= await modelGateway.calcular071(
                        data?.acodmunige.value ?? '', 
                        claseItem?.idSubuso ?? -1,
                        data?.ejercicio.value ?? new Date().getFullYear(),
                        data?.potenciaFi.value && data?.potenciaFi.value>0 ? data.potenciaFi.value: 0,
                        data?.cilindrada.value && data.cilindrada.value >0 ? data.cilindrada.value : 0,
                        data?.plazas.value && data.plazas.value>0 ? data.plazas.value : 0,
                        data?.carga.value && data.carga.value>0 ? data?.carga.value : 0,
                        translate('Tributos','error_calcular_071',terms)
                    )
                    // console.log(result)
                    const finalData= {...data, 
                        cuota: {...data['cuota'], value: result.importe}, 
                        idImporte: {...data['idImporte'], value: result.idImporte}, 
                        baseISMM: {...data['baseISMM'], value: result.valorSinMinMax},
                        matricula: {...data['matricula'], // solo si el ususario lo inserta
                            value: campos?.find(c => c.codigo === 'matricula')?.visible === false ? undefined: data['matricula'].value
                        }
                    }
                    const savedTemp = await handleSave(finalData); 
                    //setSaved(savedTemp);
                    setChanges(!savedTemp);
                } else {
                    setChanges(true);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Tributos','FormularioError', terms),
                            variant: 'error',
                        }
                    });

                }

            } catch (error) {
                const msg= (error as Error).message
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: msg,
                        variant: 'error',
                    }
                });

            } finally{
                setLoading(false)
            }
            

        })();
    }


    const onSearchMatricula = async( localMatricula: string) => {
        try {
            const result = await modelGateway.validateConsultaDGT(localMatricula, pageState.jwp?.apellidos ?? '', pageState.jwp?.nif  ?? '', true)
            if(result.ok && data && selectoresInfo?.marcaVehic){
                console.log('ConsultaDGT ', result.data)
                const marca= selectoresInfo.marcaVehic.find(m => m.nombre === result.data.datosGenerales.descripcionVehiculo.marca.descripcion)
                console.log('marca ', marca, selectoresInfo.marcaVehic.length)

                let modelo = undefined
                if(marca){
                    const modelosBBDD = await modelGateway.getModelosVehiculoByMarca(marca.id)
                    const modelos = modelosBBDD.length>0 ? formatOptions<IModeloVehicIVTM>('idModelo','modelo', modelosBBDD) : null;
                    setSelectoresInfo(s => ({...s , modeloVehic: modelos}))
                    modelo = modelos?.find( m => m.nombre.replace(/\s/g, '') == result.data.datosGenerales.descripcionVehiculo.modelo)
                    console.log('modelo ',modelosBBDD.length, modelo)

                }
                const plazas = result.data.datosTecnicos.plazas.mixtas+result.data.datosTecnicos.plazas.normales+result.data.datosTecnicos.plazas.numPlazasPie
                const clase = claseSubusos.find(c => {
                    //console.log(
                    //     c.nombre.slice(0,5) , 
                    //     result.data.datosGenerales.descripcionVehiculo.tipoVehiculo.descripcion, 
                    //     result.data.datosGenerales.descripcionVehiculo.tipoVehiculo.descripcion.includes(c.nombre.slice(0,5))
                    // )
                    //Comparando 5 primeras letras evitamos errores idioma
                    return (
                        c.nombre=== result.data.datosGenerales.descripcionVehiculo.tipoVehiculo.descripcion 
                        || result.data.datosGenerales.descripcionVehiculo.tipoVehiculo.descripcion.includes(c.nombre.slice(0,5))
                    )
                })
                //console.log(claseSubusos, result.data.datosGenerales.descripcionVehiculo.tipoVehiculo.descripcion)

                setData( curr => curr && ({
                    ...curr,
                    bastidor: {...curr['bastidor'], value: result.data.datosGenerales.descripcionVehiculo.bastidor},
                    marca: {...curr['marca'], value: marca?.nombre},
                    modeloVeh: {...curr['modeloVeh'], value: modelo?.nombre},
                    potenciaFi: {...curr['potenciaFi'], value: result.data.datosTecnicos.potencias.potenciaFiscal},
                    cilindrada: {...curr['cilindrada'], value: result.data.datosTecnicos.potencias.cilindrada},
                    carga: {...curr['carga'], value: result.data.datosTecnicos.masas.pesoMaximo},
                    plazas: {...curr['plazas'], value: plazas},
                    clase: {...curr['clase'], value: clase?.nombre}
                }))
                setChanges(true)

                // AVISOS
                if(!selectoresInfo?.marcaVehic){
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            isHtml: true,
                            message: translate('Tributos','serachMatricula_notEnoughtInfoToCompleteMarca', terms),
                            variant: 'warning',
                        }
                    });
                }

                if(data.descripcio.value === DescripcioLiquidIVTM.ALTA_BAJA_TEMP && (
                    result.data.datosGenerales.indicadores.bajaDefinitiva
                    || !result.data.datosGenerales.indicadores.bajaTemporal
                )){
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            isHtml: true,
                            message: result.data.datosGenerales.indicadores.bajaDefinitiva 
                                ? translate('Tributos','vehiculoBajaDefinitiva', terms)
                                : translate('Tributos','vehiculoNoBajaTemporal', terms),
                            variant: 'warning',
                        }
                    });
                }

                // TODO Existe algun control Re-matriculacion ?¿?¿
                // if(data.descripcio.value === DescripcioLiquidIVTM.REHABILITACION){}

            } else {
                const bodyAux = await contenidoGateway.getContent(result.errorMessage, {});
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        isHtml: true,
                        message: bodyAux[0].contenido,
                        variant: 'error',
                    }
                });
            }
        } catch (error) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Tributos','errorConsultaDGT', terms),
                    variant: 'error',
                }
            });
        }
    }


    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                //console.log('idTipoApartado', idTipoApartado)
                const liquidacionesIVTM = await modelGateway.getTiposLiquidacionIVTM();
                const usos = await modelGateway.getSubUsosIVTM();
                const codes = await modelGateway.getCodesClasificVehicIVTM();
                
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                let changes= false;
                //console.log('condicion', camposApartado && camposApartado.length>0 )
                if(camposApartado && camposApartado.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    // console.log('emptyObjt', emptyObjt)
                    setEmptyObject(emptyObjt as TGenericObjectVehiculo071);
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
                    // console.log('datosXml',datosXml,'  --- datosPrevString ', datosPrevString)
                    if(datosXml){
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                        //console.log('USE datosXML', dataXML)
                    } 
                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                    }
                    //console.log('isSameData:', isSameData(dataXML, dataPrevios))
                    if(isSameData(dataXML, dataPrevios) /*|| saved*/){
                        setChanges(false)
                        newData= dataXML
                    } else {
                        const mixDataObjt = mixData(dataXML, dataPrevios, idTipoApartado)
                        // console.log('mixDataObjt:', mixDataObjt)
                        setChanges(!isSameData(newData, mixDataObjt))
                        newData = mixDataObjt ?? newData
                    }

                }


                let finalData = newData as TGenericObjectVehiculo071 || emptyObjt
                // Estado inicial campos
                let visible: (keyof TGenericObjectVehiculo071)[]=['descripcio']
                let soloLect: (keyof TGenericObjectVehiculo071)[]=[]
                if(finalData.descripcio.value === DescripcioLiquidIVTM.REHABILITACION || finalData.descripcio.value === DescripcioLiquidIVTM.ALTA_BAJA_TEMP){
                    visible=visible.concat(CAMPOS_MATRICULA) 
                    if(isLogged){ 
                        visible.push('matricula')
                    }
                    finalData = {...finalData, carga: {...finalData['carga'], formula: ''}}
                    soloLect.push('carga')
                    soloLect.push('clase')
                }
                if(finalData.descripcio.value === DescripcioLiquidIVTM.MATRICULACION){
                    visible= visible.concat(CAMPOS_MATRICULACION)
                }
                if(finalData.clase.value){
                    const add = getCamposUSO(finalData.clase.value)
                    visible= visible.concat(add)
                }
                // console.log('visible:', visible, '  soloLect:', soloLect)
                const newC = camposApartado.map(c =>  ({...c, 
                    visible: visible.includes(c.codigo as keyof TGenericObjectVehiculo071) ? true : false,
                    soloLectura: soloLect.includes(c.codigo as keyof TGenericObjectVehiculo071) ? false : c.soloLectura,
                    textActionProps: c.tipoCampoWeb === 'textAction' && (c.codigo as keyof TGenericObjectVehiculo071) ==='matricula' && visible.includes('matricula') 
                        ? {customAction: (v:string) =>onSearchMatricula(v), mdiIconAction: mdiMagnify, color: BLUE}
                        : undefined
                }))
                

                //Empty Selectores data - si es necesario
                const optLiquidIVTM = liquidacionesIVTM.map(t => ({id: t.idTipoLiqui.toString() , nombre: translate('Tributos', t.Nombre,terms)} as Option))
                const provinciaBaleares = '07'
                const optionsTV = await geoGateway.getSiglas()
                const optionsProv = await geoGateway.getProvincias('')
                const selected: Provincia|null|undefined = optionsProv && newData?.provincia?.value 
                    ? optionsProv.find(opt => (opt.nombre === newData?.provincia?.value.toString() || opt.idProvincia === newData?.provincia?.value.toString())) 
                    : null
            
                const optionsMuni = await geoGateway.getMunicipios(selected?.idProvincia || provinciaBaleares) 
                const marcas = await modelGateway.getMarcasVehiculo(); 
                let modelos: Option[] | null = null
                if(finalData.marca.value && finalData.marca.value !== ''){
                    const m = marcas.find( itm => itm.marca === finalData.marca.value )
                    setMarca( m ? {id: m.idMarca, nombre: m.marca} : undefined)
                    if(m){
                        const modelosBBDD = await modelGateway.getModelosVehiculoByMarca(m.idMarca)
                        modelos = modelosBBDD.length>0 ? formatOptions<IModeloVehicIVTM>('idModelo','modelo', modelosBBDD) : null
                    }
                }  

                // SET de DATOS
                setSelectoresInfo({
                    municipio: optionsMuni && optionsMuni.length>0 ? formatOptions<Municipio>('idMunicipio','nombre',optionsMuni) : null,
                    provincia: optionsProv.length>0 ? formatOptions<Provincia>('idProvincia','nombre', optionsProv) : null,
                    tipoVia: optionsTV.length>0 ? formatOptions<Sigla>('idSiglas','nombre', optionsTV) : null,
                    tipoLiqIVTM: optLiquidIVTM,
                    marcaVehic: marcas.length>0 ? formatOptions<IMarcaVehicIVTM>('idMarca','marca', marcas) : null,
                    modeloVehic: modelos,
                    codeUtilidITVM: codes.utilidad.length>0 ? formatOptions<ICodUsoVehicIVTM>('codigo','descripcion', codes.utilidad) : null,
                    codeConstrITVM: codes.construccion.length>0 ? formatOptions<ICodConstruccionIVTM>('codigo','descripcion', codes.construccion) : null,
                    claseVehiIVTM: usos.length>0 ? formatOptions<ISubUsosIVTM>('idSubuso','nombre', usos) : null,
                })
                setClaseSubusos(usos);
                setCodConstruccion(codes.construccion);
                setCodConstruccion(codes.construccion);
                setCodUso(codes.utilidad);
                // console.log('newC', newC)
                setCampos(newC);

                //Por último DATA - dispara los effects 
                //console.log('finalData', finalData)
                setData(finalData);
                setChanges(changes);


            } catch (error) {
                const result = (error as Error).message;
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            } finally {
                setLoading(false)
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);

    useEffect(() => {
        if(!marca){ return; }
        (async () => {
            const modelosBBDD = await modelGateway.getModelosVehiculoByMarca(marca.id)
            const modelos = modelosBBDD.length>0 ? formatOptions<IModeloVehicIVTM>('idModelo','modelo', modelosBBDD) : null
            setSelectoresInfo( curr => ({...curr, modeloVehic: modelos}))
        })()
    }, [marca])

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])

    useEffect(() => {
        //console.log('effect showCamposAltaMatric ', showCamposAltaMatric)
        //if(showCamposAltaMatric){
            updateVisibleCampos(CAMPOS_MATRICULACION,showCamposAltaMatric)
        //}
    },[showCamposAltaMatric])

    useEffect(() => {
        //console.log('effect showCLCampos ', showCLCampos)
        if(showCLCampos){
            const visible2: (keyof TGenericObjectVehiculo071)[]= getCamposUSO(data?.clase.value) 
            updateVisibleCampos(visible2, true)
        }
    },[showCLCampos])

    useEffect(() => {
        console.log('effect showCampoMatricula ', showCampoMatricula, isLogged)
        if(showCampoMatricula){
            const CMFinal = showCampoMatricula && isLogged ? CAMPOS_MATRICULA.concat(['matricula']) : CAMPOS_MATRICULA 
            updateVisibleCampos(CMFinal, true)
            updateOtherCampos('clase', [{key:'soloLectura', value: false}])
            updateOtherCampos('carga', [{key:'soloLectura', value: false}, {key:'formula', value: null}])
        } else {
            updateVisibleCampos(['matricula'], false)
            updateOtherCampos('clase', [{key:'soloLectura', value: true}])
            updateOtherCampos('carga', [{key:'soloLectura', value: true}, {key:'formula', value: FORMULA_CARGA /*campos?.find(c=> c.codigo==='carga')?.formula */}])

        }
    },[ showCampoMatricula])

  

  
    // console.log('data', data)
    return(
        <div> 
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>

            {campos && campos.length>0 && /*camposRequiredKeys !== null &&*/ data && selectoresInfo &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, selectoresInfo, undefined, classes )
            }

            <div className={classes.rowAlignRight}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div> 
        </div>
    )
}
export default withLiterals(['Tributos'])(Vehiculo071);



