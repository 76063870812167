// LIBRARY IMPORTS
import { FC, useCallback, useContext, Fragment, useState, useMemo, useEffect } from "react";
import { Card, makeStyles, CardContent } from "@material-ui/core";

// LOCAL IMPORTS
import { TramitesGateway } from "gateways/tramites.gateway";
import { FilterDataContext } from "./filter.data.context";
import { AlertsContext } from "contexts/alerts.context";
import { SelectionContext } from "./selection.context";
import { IRecibo } from "gateways/recibo.interfaces";
import { ScreenType } from "./filters.form";
import ReciboDetail from "./recibo.detail";
import ReciboGrid from "./recibo.grid";
import IoC from "contexts/ioc.context";

// STYLES
import styles from '../shared/tributos.styles';

const useStyles = makeStyles(styles);

interface IReciboCardProps {
    recibo: IRecibo;
    selectable: boolean;
    mensajeAclaratorio: string;
    isFromConsultasExternas: boolean;
    bodyAvisoPagoCartaPalma: string;
    bodyAvisoProcedimientoEmbargo?: string;
    screen?: ScreenType;
}

const ReciboCard: FC<IReciboCardProps> = (props) => {
    // PROPS
    const { recibo, selectable, mensajeAclaratorio, isFromConsultasExternas, bodyAvisoPagoCartaPalma, screen, bodyAvisoProcedimientoEmbargo } = props;

    // HOOKS
    const classes = useStyles();

    // CONTEXT
    const [, alertsDispatch] = useContext(AlertsContext);
    const [selection, selectionDispatch] = useContext(SelectionContext);
    const filterData = useContext(FilterDataContext);
    const ioc = useContext(IoC);

    // GATEWAYS
    const tramitesGateway: TramitesGateway = ioc.get(TramitesGateway);

    // STATES
    const [estadoIdentificacion, setEstadoIdentificacion] = useState('');
    const [identificacionObligatoria, setIdentificacionObligatoria] = useState(false);
    const [esIdentificable, setEsIdentificable] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);

    // MEMOS
    const selected = useMemo(() => selection.selected.findIndex((x: IRecibo) => x.id === recibo.id) >= 0, [selection, recibo]);
    const esMulta = useMemo(() => recibo.concepto.idTipo === 7, [recibo]);



    // HANDLERS

    const handleOpenDetail = useCallback(() => setOpenDetail(true), []);
    const handleCloseDetail = useCallback(() => setOpenDetail(false), []);
    const handleUnselect = useCallback(() => selectionDispatch({ type: 'unselect', item: recibo }), [selectionDispatch, recibo]);

    const handleSelect = useCallback(() => {
        if (recibo.domiciliacion.pagoALaCartaPalma && recibo.organismo.codigo === "040" && recibo.situacionCobro === 2) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message:
                        <div>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: bodyAvisoPagoCartaPalma }} />
                            </p>
                        </div>,
                    variant: 'warning',
                }
            });
        } if (recibo.situacionCobro === 8) {
            // Mostrar el mensaje de alerta si situacionCobro es 8
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message:
                        <div>
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: bodyAvisoProcedimientoEmbargo? bodyAvisoProcedimientoEmbargo : "" }} />
                            </p>
                        </div>,
                    variant: 'warning',
                }
            });
        } else {
            selectionDispatch({ type: 'select', item: recibo })
        }
    }, [recibo, alertsDispatch, bodyAvisoPagoCartaPalma, selectionDispatch]);


    // USE EFFECTS
    useEffect(() => {
        // TODO: duplicidad de llamadas, puede sobrecargar el servidor, se debería refactorizar 
        const asyncEffect = async () => {
            if (!esMulta) {
                setIdentificacionObligatoria(false);
                setEsIdentificable(false);
                return;
            }

            const obligatoria = await tramitesGateway.identificacionObligatoria(recibo.id);
            const identificable = await tramitesGateway.puedeIdentificar(recibo.id);
            const estado = await tramitesGateway.getEstadoIdentificacion(recibo.id);

            setIdentificacionObligatoria(obligatoria);
            setEstadoIdentificacion(estado);
            setEsIdentificable(identificable);
        };
        asyncEffect();
    }, [tramitesGateway, esMulta, recibo.id]);



    return (
        <Fragment>
            <Card
                className={[classes.mainButton, classes.recibo].join(' ')}
                onClick={handleOpenDetail}>
                <CardContent>
                    <ReciboGrid
                        selectable={selectable}
                        recibo={recibo}
                        filterData={filterData}
                        moreInfo={handleOpenDetail}
                        selected={selected}
                        onSelect={handleSelect}
                        onUnselect={handleUnselect}
                        showIdentificacion={esMulta && identificacionObligatoria}
                        estadoIdentificacion={estadoIdentificacion}
                        screen={screen}
                    />
                </CardContent>
            </Card>

            <ReciboDetail
                selectable={selectable}
                selected={selected}
                onClose={handleCloseDetail}
                onSelect={handleSelect}
                onUnselect={handleUnselect}
                puedeIdentificar={esIdentificable}
                open={openDetail}
                recibo={recibo}
                mensajeAclaratorio={mensajeAclaratorio}
                isFromConsultasExternas={isFromConsultasExternas}
                bodyAvisoPagoCartaPalma={bodyAvisoPagoCartaPalma}
                screen={screen}
            />
        </Fragment>
    );
};


export default ReciboCard;