import { Button, Grid, IconButton, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { mdiEyeCircleOutline, mdiPaperclip, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import InputDate from "components/DUTI/input-date";
import InputNumber from "components/DUTI/input-number";
import InputSelect from "components/DUTI/input-select";
import TypedInputNumber from "components/DUTI/typed-input-number";
import AttachFileComponent from "components/attach-file-component";
import FullLoading from "components/full-loading";
import ModalAdjuntarEscrPublica from "components/modal-ajuntar-escr-publica";
import { TSelect } from "containers/DUTI/utils";
import { BLUE } from "containers/shared/colors";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { TOperacion, TOperacionErr } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import { getTiposDerechoTransmsisionByTipoAsistente } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/utils";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import { DocumentosGateway } from "gateways/documentos.gateway";
import { IDocumentoAtib } from "gateways/documentos.interface";
import ModelGateway from "gateways/model.new.gateway";
import React, {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { DocumentsUtils } from "utils/documents";
import { translate } from "utils/i18n";


interface IPropsOperacionPLV extends WithStyles<typeof styles>{
    initialValue: TOperacion;
    errors: TOperacionErr
    onChange: (v: TOperacion, vErr: TOperacionErr,reset:boolean) => void
    maxDate: Date
    renderType: "form"|"detail"
    showAttach: boolean
    idReferencia:string
    apartado:string,
    hideImporte:boolean
}
const OperacionPLV : FC<IPropsOperacionPLV>= ({classes,initialValue, errors, onChange, maxDate, renderType, showAttach, idReferencia,apartado, hideImporte}) => {
    const componentMounted= useRef(true)
    const terms= useContext(LiteralsContext)
    const [, alertsDispatch] = useContext(AlertsContext);
    
    //Gateways
    const ioc = useContext(IoC);
    const documentosGateway: DocumentosGateway = useMemo(() => ioc.get(DocumentosGateway), [ioc]);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    
    const [doc, setDoc]= useState<IDocumentoAtib| undefined>(undefined)
    const [openModalAdjuntar, setOpenModalAdjuntar] = useState(false);

    const [loading, setLoading]= useState(false);

    const deleteDoc = async (id:string) => {
        try { 
            setLoading(true);
            const done = await modelGateway.deleteDocModelo(idReferencia, id, apartado);
            if(done){
                setDoc(undefined)
                onChange({...initialValue, idDocAnteriorAdquisicion: undefined},{...errors, idDocAnteriorAdquisicion: showAttach },false )
            }
        } catch(error){
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Global', 'can_not_delete_doc', terms),
                    variant: 'error',
                }
            });
        } finally {
            setLoading(false);
        }
    }

    const showDoc = async(idDoc:string) => {
        try {
            setLoading(true)
            DocumentsUtils.downloadDoc(idDoc)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'can_not_show_docuemnta', terms),
                    variant: 'error',
                }
            });
        }
    }

    useEffect(()=> {
        if(showAttach){
            (async () => {
                const doc = initialValue.idDocAnteriorAdquisicion? await documentosGateway.getDocAtib(initialValue.idDocAnteriorAdquisicion): undefined
                setDoc(doc)
            })();
        }
        
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }

    },[initialValue, showAttach])

    return(
        <>
            <FullLoading loading={loading}/>
            <Grid container direction="row">
                
                <InputDate 
                    key='fechaAnteriorAdquisicion'
                    fieldTerm={"fechaAnteriorAdquisicion"}
                    value = {initialValue?.fechaAnteriorAdquisicion} 
                    required
                    maxDate={ maxDate }
                    onChangeValue={(v: Date|undefined,err:boolean) => {
                        onChange({...initialValue, fechaAnteriorAdquisicion: v },{...errors, fechaAnteriorAdquisicion: err } ,true)
                    }}
                    error={errors.fechaAnteriorAdquisicion}
                    disabled={renderType ==="detail"}
                />
                
                <TypedInputNumber 
                    key='porcentajeAnteriorAdquisicion'
                    type="percentaje"
                    required={true}
                    allowZero={false}
                    maxDecimal={2}
                    fieldTerm="porcentajeAnteriorAdquisicion"
                    value={ initialValue?.porcentajeAnteriorAdquisicion }
                    onChangeValue={(v, err) => {
                        onChange({...initialValue, porcentajeAnteriorAdquisicion: v },{...errors, porcentajeAnteriorAdquisicion: err } ,true)
                    }}
                    error={errors.porcentajeAnteriorAdquisicion}
                    className={classes.input200} 
                    disabled={renderType ==="detail"}

                />

                {hideImporte 
                    ? null
                    :
                    <TypedInputNumber
                        key='importeAnteriorAdquisicion'
                        type="money"
                        required={false}
                        allowZero={true}
                        min={0}
                        maxDecimal={2}
                        fieldTerm="importeAnteriorAdquisicion"
                        value={ initialValue?.importeAnteriorAdquisicion}
                        onChangeValue={(v:number|undefined, err) => {
                            if( !v|| v===0 ){
                                setDoc(undefined)
                            }
                            onChange(
                                {...initialValue, importeAnteriorAdquisicion: v,idDocAnteriorAdquisicion: !v|| v===0 ? undefined: initialValue.idDocAnteriorAdquisicion},
                                {...errors, importeAnteriorAdquisicion: err },
                                true 
                            )
                        }}
                        error={errors.importeAnteriorAdquisicion}
                        className={classes.input200} 
                        disabled={renderType ==="detail"}
                    />
                }
                {showAttach && 
                    <Grid item container direction='column' alignItems="flex-start">
                        <Typography 
                            variant="body2" 
                            style={{marginRight: 30, fontWeight: 'bolder'}}
                        >
                                {translate('DUTI','attachEscritura',terms).toUpperCase()}
                        </Typography>
                        {doc ?
                            <div className={classes.row} style={{ display:'flex', alignItems: 'center'}}>
                                <Typography variant="body1" style={{marginRight: 20}}>
                                    {  doc ? doc.NombreFichero: ' - '}
                                </Typography>
                                <IconButton onClick={() => showDoc(doc.IdDocumento)}>
                                    <Icon path={mdiEyeCircleOutline} size={1} color={BLUE} />
                                </IconButton>
                                <IconButton onClick={() => deleteDoc(doc.IdDocumento)}
                                    style={{padding: 5}} 
                                >
                                    <Icon path={mdiTrashCan} size={1} color='red' />
                                </IconButton>
                            </div> 
                        :  
                            <Button variant="outlined" 
                                color='primary'
                                size="small"
                                classes={{startIcon: classes.starIcon}}
                                style={{marginRight:0}}
                                startIcon={ <Icon path={mdiPaperclip} size={1} color={BLUE} /> }
                                onClick={() => setOpenModalAdjuntar(true)}
                            >
                                {translate('DUTI','btn_attach',terms)}
                            </Button>
                        }
                    </Grid>
                }

                <ModalAdjuntarEscrPublica
                    open={openModalAdjuntar}
                    buttonAdjuntar={{componentIG:'DUTI', term:'attachEscritura'}}
                    // Props AttachFileComponent
                    docs={doc ? [doc] : []}
                    saveDocsAttached={ (docs: IDocumentoAtib[]) => { 
                        if(docs.length>0 ){
                            setDoc(docs[0])
                            onChange({...initialValue, idDocAnteriorAdquisicion: docs[0].IdDocumento },{...errors, idDocAnteriorAdquisicion: false},false )
                        } else {    
                            setDoc(undefined)                           
                            onChange({...initialValue, idDocAnteriorAdquisicion: undefined},{...errors, idDocAnteriorAdquisicion: showAttach },false )
                        }
                    }}
                    docSource="sede-plusvalias"
                    idTipoApartado={apartado}
                    idReferencia={idReferencia}
                    onClose={()=>setOpenModalAdjuntar(false)}
                />
            </Grid>
        </>
    )

}
const styles =  (theme: Theme): StyleRules => ({
    input200: { 
        width: 200
    },
})
export default withLiterals(['DUTI','Plusvalias', 'Global'])(withStyles(styles)(OperacionPLV))