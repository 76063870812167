import { IConvenioPlazos, IDatosIban, ITributo, ITributoRelacionado } from "gateways/tributos.interfaces";
import { ALTA_DOMICILIACION_Y_PAGO_CARTA_PATH } from "constants/domiciliaciones";
import { IDatosDomiciliacionExtranjero } from "gateways/datos.domiciliacion.interfaces";
import { IRecibo } from "gateways/recibo.interfaces";
import { History } from 'history';

export interface IParamsPagoCarta {
    tributos: ITributoRelacionado[];
    convenio: IConvenioPlazos | undefined;
    iban: string;
    isExtranjero: boolean;
    nombre: string;
    nif: string;
    datosExtranjero: IDatosDomiciliacionExtranjero | null;
}

export interface IStateHistory {
    tributos: ITributo[],
    detalleDomiciliacion?: IDatosIban | null,
    domiciliables: boolean,
    modifFechaVol: boolean,
    isSelectedAllActivated: boolean;
    domiciliadoPc: boolean
    idOrden?: number;
    pagoCarta: boolean,
    pagoCartaInfo?: IParamsPagoCarta;
    recibo?: IRecibo;
}

interface IProps {
    history: History<IStateHistory>;
    domiciliables: boolean;
}

const useAltasDomicilaicion = (props: IProps) => {
    const { history, domiciliables } = props;



    // -------- ALTAS --------

    const onAltaDomiciliaciones = (
        tributosSeleccionados: ITributo[],
        isSelectedAllActivated: boolean = false
    ) => {
        if (tributosSeleccionados.length < 1) return;

        history.push({
            pathname: ALTA_DOMICILIACION_Y_PAGO_CARTA_PATH,
            state: {
                tributos: tributosSeleccionados,
                domiciliables,
                pagoCarta: false,
                modifFechaVol: false,
                isSelectedAllActivated: isSelectedAllActivated,
                domiciliadoPc: false
            },
        });
    };

    const onAltaPagoCarta = (
        tributosSeleccionado: ITributo[],
    ) => {
        history.push({
            pathname: ALTA_DOMICILIACION_Y_PAGO_CARTA_PATH,
            state: {
                tributos: tributosSeleccionado,
                domiciliables,
                pagoCarta: true,
                domiciliadoPc: false,
                modifFechaVol: false,
                isSelectedAllActivated: false
            },

        });
    };



    // -------- MODIFICACIONES --------

    const onModificacionPagoCarta = (
        tributosSeleccionado: ITributo[],
        isSelectedAllActivated: boolean = false
    ) => {
        history.push({
            pathname: ALTA_DOMICILIACION_Y_PAGO_CARTA_PATH,
            state: {
                tributos: tributosSeleccionado,
                domiciliables,
                pagoCarta: true,
                domiciliadoPc: true,
                modifFechaVol: false,
                isSelectedAllActivated: isSelectedAllActivated
            },
        });
    }

    const onModificacionFechaCargo = (tributosSeleccionado: ITributo[], domiciliacion: IDatosIban | null) => {
        history.push({
            pathname: ALTA_DOMICILIACION_Y_PAGO_CARTA_PATH,
            state: {
                tributos: tributosSeleccionado,
                detalleDomiciliacion: domiciliacion,
                domiciliables,
                pagoCarta: false,
                modifFechaVol: true,
                domiciliadoPc: false,
                isSelectedAllActivated: false
            },
        });
    }



    // -------- RETURN SECTION --------

    return {
        // ALTAS
        onAltaDomiciliaciones,
        onAltaPagoCarta,

        // MODIFICACIONES
        onModificacionPagoCarta,
        onModificacionFechaCargo
    }
};

export default useAltasDomicilaicion;