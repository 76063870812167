import React, { FC, useContext } from "react";
import Term from "components/term";
import SelectMultiple from '../../../components/select-multiple';
import { FormControl, InputLabel, makeStyles, TextField } from "@material-ui/core";
import { FiltersContext } from "./filters.context";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { FilterDataContext } from "./filter-data.context";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    formControl: {
        margin: `30px 0`,
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    group: {
        margin: `10px 0`,
    },
    block: {
        display: `inline-block`
    },
    hidden: {
        display: `none`
    },
    middle: {
        verticalAlign: `middle`
    },
}));

export type ScreenType = 'conductor';

const FiltersRemesasForm: FC = () => {

    const [filters, filtersDispatch] = useContext(FiltersContext);
    const filterData = useContext(FilterDataContext);
    const handleIdRemesaChange = (event: React.ChangeEvent<HTMLInputElement>) => filtersDispatch({ type: 'update', filter: 'idRemesa', value: event.target.value && event.target.value != '' ?event.target.value.toUpperCase().replace(/\s+|-+/g, ''): null });
    const handleFechaRemesaChange = (fechaRemesa?: Date | null) => filtersDispatch({ type: 'update', filter: 'fechaRemesa', value: fechaRemesa ? fechaRemesa : null });
    const handleEstadoChange = (estado: string[]) => filtersDispatch({ type: 'update', filter: 'estado', value: estado });

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FormControl key='idRemesa' className={classes.formControl} fullWidth>
                <TextField
                    key='idRemesa'
                    value={filters.idRemesa || ''}
                    label={<Term component="Tramites" text="idRemesa" />}
                    onChange={handleIdRemesaChange}
                />
            </FormControl>
            <FormControl key='gravedad' className={classes.formControl} fullWidth>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    format="dd/MM/yyyy"
                    id="fechaRemesa"
                    label={<Term component="Tramites" text="FechaRemesa" />}
                    value={filters.fechaRemesa || null}
                    onChange={(fecha) => { 
                        if(fecha && fecha instanceof Date && !isNaN(fecha.getTime())) {
                            handleFechaRemesaChange(fecha)
                        } 
                    }}
                />
            </FormControl>
            <FormControl key='estado' className={filterData && filterData.estado ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-estado' htmlFor="select-multiple-chip">
                    <Term component="Global" text="Estado" />
                </InputLabel>
                <SelectMultiple
                    key='estados'
                    options={filterData && filterData.estado ? filterData.estado : []} value={filters.estado || []}
                    onChange={(values) => handleEstadoChange(values)}
                />
            </FormControl>
        </div>
    );
}

export default FiltersRemesasForm;