import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";

import { withStyles, WithStyles, TextField, TextFieldProps, IconButton } from "@material-ui/core";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import styles, { colors } from "./styles";

import { TInfoTemplateSize } from "contexts/more_info.reducer";
import { MoreInfoContext } from "contexts/more_info.context";
import Icon from "atomic/atoms/icon";

import PhoneInput from "react-phone-input-2";
import es from 'react-phone-input-2/lang/es.json';
import { ICountryObjectPhone } from "gateways/perfil.interfaces";
import Term from "components/term";
import { count } from "console";

export type TExtraValidation = {
    error:boolean, 
    error_msg: string | undefined,
    componentIG?:string
}

type TMSGError = 'outOfRange_max'|'outOfRange_min' | 'err_required' |'notAllowDecimal'| 'manyDecimals'
type TInput = Pick<TextFieldProps, 'className' | 'variant' | 'size' | 'margin'| 'InputProps' |'error' >  & WithStyles

export interface IInputNumber extends TInput {
    componentIG: string
    labelTerm: string,
    value: number| undefined,
    onChangeValue: (v: string|undefined, err: boolean) => void,
    max?: number;
    min?: number ;
    maxDecimal?: number;
    required: boolean,
    extraValidation?: (v: number|undefined) => TExtraValidation
    disabled?:boolean
    disableDropdown?: boolean
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    errorMsgExterno?:string
    isMoney?:boolean
    helperText?: string // Por defeceto tiene un espacio y se reserva el espacio que ocupa (helperText y msgError). Poner a '' si no se desea reservar el espacio.
    
};


const PhonePrefixedNumber : FC<IInputNumber>= ({
    classes, labelTerm, value, onChangeValue, // onChangeError,
    max, min, maxDecimal=2, required=false, disabled,
    variant='outlined', size='small', margin='dense', isMoney=false,helperText=' ',
    className, extraValidation, error ,errorMsgExterno, moreInfo,componentIG, disableDropdown=false, ...props
}) => {
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);


    const [err, setErr] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState< string|undefined>(undefined)


    const [focussed, setFoccused] = useState(false)
    const [country, setCountry]= useState('34')


    const validate = (phone: string | undefined, withExtravalidation: boolean) => {
        let msg : TMSGError|undefined = undefined
        let msgComplement= ''
        let errDecimal=false
        let errManyDecimal=false
        let maxErr=false;
        let minErr =false;
        let prevError = false
        let result: TExtraValidation= {error: false,error_msg: undefined,}
        // console.log('validatee ', country)
        const requiredErr = required && (phone === undefined
                || phone === country
            ) ? true : false
        if(requiredErr){ 
            msg='err_required'
            prevError=true
        }

        if(phone){
            const value = Number(phone.replace(country,'')) // : phone.substring(-6))
            //const prefix =  phone.replace(country.toString(), '')
            // console.log(value)
            maxErr = !prevError && max!== undefined && value ? value > max : false;
            minErr =!prevError &&  min!== undefined && value  ? value < min : false;

            if(maxErr || minErr){ 
                msg = maxErr ? 'outOfRange_max': 'outOfRange_min';
                msgComplement = maxErr ? max?.toString()?? '' : min?.toString() ??''
                prevError=true
            }

            if(!prevError){
                const valSTR = value ? value.toString().split('.') : []
                if(maxDecimal === 0 && valSTR[1]){
                    errDecimal=true
                    msg = 'notAllowDecimal'
                } else if(valSTR[1] && valSTR[1].length > maxDecimal){
                    errManyDecimal = true;
                    msg = 'manyDecimals'
                    msgComplement = maxDecimal.toString()
                }
            }


            if(!prevError && extraValidation && withExtravalidation){
                result = extraValidation(value)
                if(result.error){ 
                    onChangeValue(phone, result.error)
                }
            }
        }
            

        setErrMsg(  msg 
            ? translate('GenericComponents', msg,terms) +' '+ msgComplement 
            : translate(result.componentIG ?? 'GenericComponents', result.error_msg ?? 'Oops !!',terms)  
        )
        setErr( maxErr || minErr || requiredErr ||errManyDecimal || errDecimal || result.error)
        return maxErr || minErr || requiredErr ||errManyDecimal || errDecimal || result.error 
    }


    // useEffect(() => {
    //     if(value !== undefined){
    //         const strValue= value.toString().replaceAll(",", ".")
    //         setLocalVal(strValue)
    //     } else {
    //         setLocalVal('')
            
    //     }
    // }, [value, focussed])



    useEffect(() => {
        // setea el error que me envían desde fuera
        if(error !== undefined){ 
            setErr(error)
            if(errorMsgExterno && error === true){ setErrMsg(errorMsgExterno)}
            if(error ===false){ setErr(false) }
        }
        if(value){validate(value.toString(),true)}
    }, [error, errorMsgExterno, value])

    const handleShowMoreInfo = useCallback(() => {
        if (moreInfo) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: moreInfo.template ?? '',
                    size:moreInfo?.size ?? undefined,
                }
            });
        }
    }, [infoDispatch])


    
    return (
        <div style={{ flexDirection: 'row', alignItems:'flex-start', marginRight: 10, marginTop:2}} >
            <div style={{display:'flex', flexDirection: 'column', alignItems:'flex-start'}} >
                <PhoneInput
                    inputProps={{
                        id: "outlined-sms-input",
                        size: 'small',
                        margin: 'dense'
                    }}
                    country={'es'}
                    countryCodeEditable={false}
                    disableDropdown={disableDropdown}
                    specialLabel={translate(componentIG, labelTerm, terms).concat(required ? '*':'') }
                    containerClass={className ? className : classes.inputDefault}
                    localization={es}
                    value={value ? value.toString() : '34'}
                    dropdownStyle={{ maxHeight: '80', top: '-40' }}
                    onChange={(phone: string, country: ICountryObjectPhone) =>{
                        setCountry(country.dialCode ?? '') 
                        const err = validate(phone, true)
                        onChangeValue(phone === country.dialCode? undefined: phone, err)
                    }}
                    isValid={ err ? false: true }
                    onBlur={() => {
                        const err = validate(value?.toString(), true)
                        err && onChangeValue(value?.toString(), err)
                    }}
                    defaultErrorMessage={err ? (errMsg ?? ' ') : helperText}
                    inputStyle={{
                        height: 45,
                        width: '100%'
                    }}
                />
                {err && errMsg &&
                <div style={{ color: '#f44336', fontSize: 12 }}>
                    <Term component={componentIG} text={errMsg} />
                </div>
                }
            </div>

            {moreInfo && 
                <IconButton onClick={handleShowMoreInfo} style={{marginTop: 8}}>
                    <Icon name={'info-outline'} size={1} color={colors.blueIconInfo}/>
                </IconButton>
            }
            
        </div>
           
    )
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(PhonePrefixedNumber));