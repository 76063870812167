import { TSelect } from "components/generic-input-select";
import { IInfoModeloPLV, TCalcPlusvalia, TCalculo, TComprador, TInfoResumen, TInfoTransmPlusvalia, TOperacion, TPago, TPreguntasPlusvalia, TVendedor } from "containers/tributos-autonomicos-locales/componentesAsistente/plusvalias/types";
import { TipoAsistenteStep, TDatosAsistente } from "containers/tributos-autonomicos-locales/componentesAsistente/shared/types";
import { TDataPopUp } from "containers/tributos-autonomicos-locales/modelos-tributos";
import React from "react";


export interface ITipoModelo {
    idTipoModelo: string;
    idTexto: string;
    tipoTributo: string;
    urlReport: string;
    concepto: string;
    visibleWeb: boolean;
    visibleApp: boolean;
    idGrupo?: string;
    valor?: string;
    urlModelo?: string;
}

export interface ISujetoCenso {
    nif: string;
    nombre: string;
    siglas: string;
    calle: string;
    numero: string;
    letra: string;
    escalera: string;
    planta: string;
    puerta: string;
    cp: string;
    acodmuniGest: string;
    poblacion: string;
    codigo: string;
}

export interface IModeloGroup {
    grupo: { idGrupo: string, descripcion: string, iconPath: string },
    modelos: ITipoModelo[]
}

export interface IConcepto {
    id: number;
    idPadre?: number | null;
    codigo?: string | null;
    orden?: number | null;
    identificador: string;
    porUnidades?: string | number | null;
    personaFisOblig: boolean;
    editable: boolean;
    ivaEditable: boolean;
    habitual: boolean;
    idTexto: string;
    texto: string;
    ejercicio: number;
    importe: number | null;
    porcentaje: number | null;
}

/** Descripción de los pasos que componen el Modelo (stepper)*/
export type TipoComponenteStep =
    'DatosPrevios' | 'Identificativo' | 'SujetoPasivo' 
    |'Autoliquidacion' |'AutoliqEjerPeriod' // <-- utilizan el componente Autoliquidacion.tsx
    | 'Liquidacion8xx' 
    | 'DeclarTrimestral' | 'FechaFinalizacion' | 'ResultadoLiquidacion' | 'ApartadoMultiple' | 'PreviosTecnicos' | 'Liquidacion621' 
    | 'Datos' | 'DatosEjerPeriod' | 'DatosContacto' // <-- utilizan el componente DATOS.tsx
    | 'Datos656D'  | 'SujetoLogueado' | 'FixedApartadoMulti'
    | 'Vehiculo071';
export interface IStep {
    idTipoApartado: string;
    letra: string;
    orden: number;
    valor: string;
    formulaRepeticiones: string;
    webOrder: number;
    webComponent: TipoComponenteStep;
    datosXML: string | null;
    icon?: string
}

/** Descripción de un campo de los N que componen un apartado */
export type RenderSize = 'x-small' | 'small' | 'xs-big' | 'big';
export type SelectRef = 'tipoVia' | 'municipio' | 'municipioIVTM' | 'provincia' | 'isla' | 'tipoLiqIVTM'| 'tipoVehiculo' |'marcaVehic' | 'modeloVehic' | 'codeConstrITVM' | 'codeUtilidITVM' | 'claseVehiIVTM'
export type TipoCampoWeb = 'number' | 'date' | 'text' | 'select' | 'decimal' | 'decimal2' | 'boolean'| 'decimal3' | 'municipioId'|'boolean'|'exclusiveBool' | 'textAction'
export interface ICampo {
    esRepetible: boolean;
    incrementoCasilla: number;
    idCampo: number;
    codigo: string;
    idRegex: number | null;
    campoDescription: string;
    regex: RegExp | null;
    regexDescription: string;
    casilla: number;
    visible: boolean;
    obligatorio: boolean;
    soloLectura: boolean;
    formula: string;
    foraneo: boolean;
    Extraer: boolean;
    tipoCampo: string | null;
    tipoCampoWeb: TipoCampoWeb | null;
    selectRef: SelectRef | null;
    renderSize: RenderSize | null; // null = normal size
    renderGroup: number;
    orderWeb: number | null;
    dateRange: string | null;
    isValid: boolean | undefined;
    textActionProps?: {customAction: (v:string) => void, mdiIconAction: any, color?:string};
    iniBoolValue:boolean | null;
    porLotes: boolean | null;
}

export interface IApartado {
    idReferencia: string,
    orden: number,
    idTipoApartado: string,
    datosXML: string
}

export interface ISaveModelo {
    datos: TDataPopUp[],
    referencia: string,
    idTipoModelo: string,
    tipoTributo: 'AU' | 'RE'
}

export interface IModelo {
    idModelo: string;
    idTipoModelo: string;
    idReferencia: string;
    idUsuario: string;
    fechaCreacion: Date,
    nifSujetoPasivo: string;
    importeAIngresar: number;
    datosXML: string;
    idExpediente: number | null;
    idEstado: string;
    idOrigenMod: number | null;
}

export interface IRespuestaConfirmarPagoFict {
    token: string;
    localizador: string;
    estadoPago?: string | null;
    fechaPago?: string | null;
    firma?: string | null;
    importe: string;
    email: string;
    codigoError?: string | null;
    textoError?: string | null;
}

export interface IUpdateEstadoModelo {
    idModelo: string,
    estado: string
}

export interface IFuncionesValidar {
    [modelo: string]: () => void
}
export interface IOriginalShared {
    [modelo: string]: any
}
export interface IExtraButtonsModelo {
    [modelo: string]: IExtraButton[]
}

export interface IExtraButton {
    label: string;
    func: () => void;
}

export interface IShared {
    [modelo: string]: {
        FOREIGN_CONSUMERS: string[]
        FOREIGN_REVERSED_CONSUMERS: string[]
    }
}
export interface IModeloVehiculo {
    idModelo: number | undefined;
    idMarca: number;
    nombre: string;
    anyoComercial: string;
    kw: number;
    cv: number;
    cvf: number;
    cc: number;
    casco: string;
    eslora: number;
    manga: number;
    motores: number;
    superficie: number;
    importe: number;
    anyoCargo: number;
    carburanteOriginal: number;
}

export interface DatosModeloSujetoLiqTrimestral {
    nif: string;
    nima: string;
    cir8: string;
    periodo: string;
    ejercicio: string;
}

export interface IReturnModeloLiqTrimestral {
    exists: boolean;
    reference: string;
}

export interface ITiposLiquidIVTM {
    idTipoLiqui: number;
    Nombre: string;
    idTipoGestion: string;
    operacion: string;
}

/** DWA-16006: No aplicar tras revisión -- Modificación 2 */
export interface ISujetoIVTMPorValidar_new {
    nif: string;
    apeNom: string;
    locaMuni: string;
    locaMuniNombre: string
    //isCIF: boolean;
    //idRef: string
}
export interface ISujetoIVTMPorValidar {
    nif: string;
    apeNom: string;
    locaMuni: string;
}
export interface IContribuyenteDireccion{
    siglas:string, calle:string, numero:number, letra: string, escalera:string, planta:string, puerta:string, cp:string, acodmunigest:string, poblacion:string
}

export interface IContribuyente {
    nif: string, 
    apeNom: string,
    direccion: IContribuyenteDireccion,
    direcciones: IContribuyenteDireccion[]
}
/** DWA-16006: No aplicar tras revisión -- Modificación 2 */
export interface IContribuyente_NEW {
    nif: string, 
    apeNom: string,
    direccion: IContribuyenteDireccion | null,
    direcciones: IContribuyenteDireccion[]
}
export enum DescripcioLiquidIVTM {
    MATRICULACION= 'Matriculación',
    ALTA_BAJA_TEMP= 'A. tras baja T.',
    REHABILITACION= 'Rematriculación',
}

export interface ISubUsosIVTM {
    idSubuso: number;
    nombre: string;
}

export interface IMarcaVehicIVTM { 
    idMarca: string; 
    marca: string;
}
export interface IModeloVehicIVTM { 
    idModelo: string;
    modelo: string;
}

export interface ICodUsoVehicIVTM{
    codigo: string,
    descripcion: string,
    texto_ES: string,
    texto_CA: string
}

export interface ICodConstruccionIVTM extends ICodUsoVehicIVTM {
    idSubuso: number | null,
}

export interface ICalculo071 {
    idImporte: number,
    valorSinMinMax: number,
    importe: number
}

export interface IMunicipioIVTM {
    idMunicipio:number,
    acodmunigest: string,
    nombre: string
}

/** Modelos - Asistente ----------------------------------------------------------------- */
export type TModeloDocSource= 'sede-plusvalias'
export interface IAsistenteStep {
    idTipoApartado: string;
    orden: number;
    valor: string;
    webOrder: number;
    webComponent: TipoAsistenteStep;
    datos: TDatosAsistente | null;
}

export interface IAsistentePLVObj {
    preguntasPlusvalia: TPreguntasPlusvalia | undefined;
    infoTransmPlusvalia: TInfoTransmPlusvalia | undefined;
    compradores: TComprador[]| undefined;
    vendedores: TVendedor[]| undefined;
    calcPlusvalia: TCalcPlusvalia[] | undefined;
    infoResumen: TInfoResumen | undefined;
    pago: TPago | undefined;
}

export interface IMunicipioPLV {
    idMunicipio:string,
    Nombre: string
}

export interface ICalculoPlusvaliaResponse{
    ok: boolean;
    idGestion: number;
    idGestionSujeto:number;
    data: TCalculo;
    error?: string;
}

export interface IRespuestaEstadoAuloquidacion {
    literal: string;
    idModelo?: string;
    ok: boolean;
}

export interface ICalculoPlusvaliaSimuladorResponse{
    ok: boolean;
    data: {
        tipoDerecho: TSelect| undefined
        edadUsufructuaria: number | undefined
        porcentaje: number,
        numOperaciones: number
        operaciones: TOperacion[];
        modelo: IInfoModeloPLV | undefined;
    };
    error?: string;
}


export interface IFinPlusvaliaResponse{
    ok: boolean,
    pago: boolean, // indica si requiere pago
    localizadores: string[],
}

export type TEstadoModelo = 'INI'|'FIN'|'ENV'|'IMP'|'CAN'|'COK'|'CNK'|'ERR'
export interface IInfoStepPagoModelos {
    estado: TEstadoModelo
    localizador: string,
    fechaCreacion: Date,
    importeAIngresar: number
}

export interface ITipoTransmision {
    idTipoTransmision: string;
    tipoTransmision: string;
    orden: number;    
}

export type TTipoTransmision = 'CE' | 'CV' | 'DD' | 'DO' | 'DP' | 'EH' | 'EX' | 'OS' | 'SH';
export type TTipoDerechoTransmision= 'pleno-dominio'|'usufructo'|'nuda-propiedad'|'usufructo-temporal'|'usufructo-vitalicio';
export type TTipoDocTransmision = 'publico-notarial' |'privado' |'publico-administrativo' | 'publico-judicial' |'publico-otros';

export interface IBonificacion {
    idTarifa: number,
    valor?: number | null, 
    valorCatastralMin?: number | null, 
    valorCatastralMax?: number | null, 
    componenteIG: string, term: string
}


export interface IMotivosExencNoSuj { 
    exencion: {id: number, term: string}[]
    noSujecion: {id: number, term: string}[]
}

/** ------------------------------------------------------------------------------------- */



/*
export type TDatosApartadoToXML = {
    apartado: string;
    campos: {
        value: any;
        casilla: number;
    }[];
} 

export interface IGetXmlModelo { 
    datos: TDatosApartadoToXML[]
    idModelo: string;
}


*/