// LIBRARY IMPORTS
import React, { FC, useMemo } from 'react';
import { Grid, Typography, ButtonBase, Button, makeStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { mdiCheck } from '@mdi/js';
import { Icon } from '@mdi/react';
import moment from 'moment';

// LOCAL IMPORTS
import { IRecibo, IFilterData } from 'gateways/recibo.interfaces';
import { esConceptoVivienda } from 'utils/concepto.vivienda';
import { IFilterOption } from 'utils/interfaces';
import { ScreenType } from './filters.form';
import ConceptoIcon from '../../components/concepto.icon';
import TextNumber from '../../components/text-number';
import Term from '../../components/term';

// STYLES
import styles from '../shared/tributos.grid.styles';

type InheritsProps = RouteComponentProps;
interface IProps extends InheritsProps {
    selectable: boolean;
    recibo: IRecibo;
    selected: boolean;
    filterData: IFilterData | null;
    showIdentificacion: boolean;
    estadoIdentificacion: string;

    screen?: ScreenType;

    onUnselect: (recibo: IRecibo) => void;
    onSelect: (recibo: IRecibo) => void;
    moreInfo: (recibo: IRecibo) => void;
}

const useStyles = makeStyles(styles);

const ReciboGrid: FC<IProps> = (props) => {
    const {
        selectable,
        recibo,
        selected,
        filterData,
        showIdentificacion,
        history,
        estadoIdentificacion,

        screen,

        onUnselect,
        onSelect,
        moreInfo
    } = props;

    // HOOKS
    const classes = useStyles();

    // CONSTANTS
    const pendiente = recibo.desglose.pendiente > 0;
    const organismo = filterData && filterData.organismos ? filterData.organismos.find((x: IFilterOption<string>) => x.value === recibo.organismo.codigo) : null;

    // MEMO
    const esPagoACuenta = useMemo(() => recibo.estadoEtiqueta === 'pago_a_cuenta_domiciliado', [recibo]);



    // HANDLERS

    const handleSelect = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        (selected ? onUnselect : onSelect)(recibo);
    };

    const handleMoreInfo = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        moreInfo(recibo);
    };

    const handleIdentificar = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        history.push({ pathname: '/tramites/conductor', state: recibo.id });
    }

    const handleImporte = () => {
        let importe;

        if (recibo.organismo.codigo === "040" && recibo.domiciliacion.pagoALaCartaPalma)
            importe = recibo.desglose.principal;
        else if (screen === 'cartero-virtual-pagado' && recibo.domiciliacion.pagoCarta)
            importe = recibo.desglose.principal + recibo.desglose.pendiente;
        else
            importe = recibo.desglose.pendiente > 0 ? recibo.desglose.pendiente : recibo.desglose.pagado;

        return importe;
    }



    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.imageGrid}>
                {
                    selectable
                        ? (
                            <ButtonBase
                                onClick={handleSelect}
                                className={[classes.imagenContainer, selected ? classes.checked : ''].join(' ')}>
                                {selected ? <Icon path={mdiCheck} title="Seleccionado" size={1} /> : <ConceptoIcon concepto={recibo.concepto.idTipo} codigo={recibo.concepto.codigo} />}
                            </ButtonBase>
                        )
                        : (
                            <div className={classes.imagenContainer}>
                                <ConceptoIcon concepto={recibo.concepto.idTipo} codigo={recibo.concepto.codigo} />
                            </div>
                        )
                }
            </Grid>
            <Grid item className={classes.grow}>
                <Grid container className={classes.contentRoot}>
                    <Grid className={[classes.grow, classes.width0].join(' ')}>
                        <Typography className={classes.highlightText}>
                            {organismo ? organismo.label : recibo.organismo.nombre} | {recibo.ejercicio}  {recibo.periodo !== '' ? '| ' + recibo.periodo : null}
                        </Typography>
                        <Typography className={classes.highlightText}>
                            <Term component="CarteroVirtual" text={recibo.concepto.nombre} />
                        </Typography>
                        <Typography className={classes.highlightText}>
                            {recibo.referencia}
                        </Typography>
                    </Grid>
                    <Grid>
                        {esPagoACuenta && screen === 'cartero-virtual-pendiente' ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <Typography component="span" className={[classes.price, classes.priceLabel].join(' ')}>
                                        <Term component="CarteroVirtual" text="Importe" />
                                    </Typography>
                                    <TextNumber
                                        component="span"
                                        className={[classes.price, recibo.desglose.recargo > 0 && selectable ? classes.priceEjecutiva : ''].join(' ')}
                                        value={recibo.desglose.pendiente}
                                        options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                                </div>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item >
                                        <Typography component="span" className={[classes.priceNotResalted, classes.priceLabel].join(' ')}>
                                            <Term component="CarteroVirtual" text="Importe principal" />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextNumber
                                            component="span"
                                            className={classes.priceNotResalted}
                                            value={recibo.desglose.principal + recibo.desglose.pendiente} // TODO DWA-15099 revisar no es corresponde con => recibo.deglose.principal ?¿
                                            options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item >
                                        <Typography component="span" className={[classes.priceNotResalted, classes.priceLabel].join(' ')}>
                                            <Term component="CarteroVirtual" text="Importe pagado" />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextNumber
                                            component="span"
                                            className={classes.priceNotResalted}
                                            value={recibo.desglose.principal} // TODO DWA-15099 revisar no es corresponde con => recibo.deglose.pagado ?¿
                                            options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                                    </Grid>
                                </Grid>


                            </div>
                            :
                            <div>
                                <Typography component="span" className={[classes.price, classes.priceLabel].join(' ')}>
                                    {
                                        pendiente || recibo.organismo.codigo === "040"
                                            ?
                                            <Term component="CarteroVirtual" text="Importe" />
                                            :
                                            <Term component="CarteroVirtual" text="Importe pagado" />
                                    }
                                </Typography>
                                <TextNumber
                                    component="span"
                                    className={[classes.price, recibo.desglose.recargo > 0 && selectable ? classes.priceEjecutiva : ''].join(' ')}
                                    value={handleImporte()}
                                    options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                            </div>
                        }
                    </Grid>
                </Grid>
                <Typography className={classes.detailText}>
                    {
                        esConceptoVivienda(recibo.concepto.idTipo) ? recibo.direccionTributaria : recibo.objeto
                    }
                </Typography>

                {
                    recibo.fechaInicio && recibo.fechaFin && recibo.fechaFin > recibo.fechaInicio
                        ? <Typography className={classes.detailText}>del {moment(recibo.fechaInicio).format('DD/MM/YYYY')} al {moment(recibo.fechaFin).format('DD/MM/YYYY')}</Typography>
                        : ''
                }


                {/* ETIQUETA */}

                {
                    recibo.estadoEtiqueta &&
                    <Typography className={[classes.etiquetaText].join(' ')}>
                        <Term component="Global" text={recibo.estadoEtiqueta} />
                    </Typography>

                }
                {
                    recibo.estadoEtiqueta && !recibo.domiciliable && screen === 'cartero-virtual-pagado'
                        ? <Typography className={[classes.etiquetaText].join(' ')}>
                            <Term component="Domiciliaciones" text="Domiciliación no gestionada" />
                        </Typography>
                        : null
                }
                {
                    recibo.estadoEtiqueta === 'Domiciliación devuelta' && recibo.fechaDevolucion
                        ? <Typography className={classes.detailText}>
                            <Term component="Global" text='Fecha devolución' />: {moment(new Date(recibo.fechaDevolucion)).format('DD/MM/YYYY')}
                        </Typography>
                        : null
                }
                {
                    recibo.concursalVigente
                        ? <Typography className={[classes.etiquetaText].join(' ')}>
                            <Term component="Global" text='Deuda en expediente concursal' />
                        </Typography>
                        : null
                }

                <div className={classes.bannerBtn}>
                    {recibo.desglose.pendiente >= 1000000 ?
                        <Alert severity="info"><Term component="CarteroVirtual" text="ContactarAtib" /></Alert>
                        : ''
                    }
                    <Button variant="contained" color="default" size="small" className={classes.btnMoreInfo} onClick={handleMoreInfo}>
                        <Term component="CarteroVirtual" text="+ INFO" />
                    </Button>
                    {
                        pendiente && selectable && !showIdentificacion && screen === 'cartero-virtual-pendiente'
                            ?
                            <Button variant="contained" color="primary" size="small" className={classes.btnAddPay} onClick={handleSelect}>
                                <Term component="CarteroVirtual" text={selected ? "Quitar" : "Pagar"} />
                            </Button>
                            :
                            ''
                    }
                    {
                        (!pendiente || recibo.domiciliacion.pagoCarta && screen === 'cartero-virtual-pagado') && selectable && !showIdentificacion
                            ?
                            <Button variant="contained" color="primary" size="small" className={classes.btnAddPay} onClick={handleSelect}>
                                <Term component="CarteroVirtual" text={selected ? "Quitar" : "Imprimir"} />
                            </Button>
                            :
                            ''
                    }
                    {
                        pendiente && showIdentificacion && estadoIdentificacion === 'Identificable'
                            ?
                            <Button variant="contained" color="primary" size="small" className={classes.btnAddPay} onClick={handleIdentificar}>
                                <Term component="CarteroVirtual" text={"Identificar"} />
                            </Button>
                            :
                            ''
                    }
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(ReciboGrid);