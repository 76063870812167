import {FC, useContext, useEffect, useMemo, useState} from "react";
import { Typography, Grid, withStyles, WithStyles, Button } from "@material-ui/core";
import { translate } from "utils/i18n";
import duti_styles from "containers/DUTI/styles";
import { LiteralsContext, withLiterals } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import { CustomError } from "utils/custom.error";
import usePage from "hooks/page.hook";
import Modal from "components/modal";
import FullLoading from "components/full-loading";
import InputDate from "components/DUTI/input-date";
import InputSearch from "components/DUTI/input-search";
import InputText from "components/DUTI/input-text";
import TypedInputNumber from "components/DUTI/typed-input-number";
import ModelGateway from "gateways/model.new.gateway";
import { IDocumentoAtib } from "gateways/documentos.interface";

export interface IInfo {
    fecha?: Date | undefined;
    anyo: number| undefined;
    numeroProtocolo: string |undefined;
    nifNotario: string | undefined;
    notario: string | undefined;
    nrd: string | undefined;
    idDocEscritura: string
}

export interface IModalSearchByNRDProps extends WithStyles {
    show: boolean,
    info: IInfo,
    onConfirm: (doc: IDocumentoAtib|undefined, newInfo: IInfo, nrd:string|undefined) => void,
    onClose: () => void,
    setShouldBlock: (v: boolean) => void,
    onChangeOrigenDoc: (v: number) => void,
}

const ModalSearchByNRD: FC<IModalSearchByNRDProps> = ({classes, show, /*currentNRD,*/ info, onConfirm, onClose, setShouldBlock, onChangeOrigenDoc}) => {
    const terms = useContext(LiteralsContext);
    const [,alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);

    const [loading, setLoading] = useState(false)
    const [newInfo, setNewInfo]=useState<IInfo| null>(null)
    const [localNRD, setLocalNRD]= useState<string|undefined>(undefined)
    const [doc, setDoc] = useState<undefined|IDocumentoAtib>(undefined)

    const searchByNRD = async () => {
        try {
            if (localNRD && localNRD !== "") {
                setLoading(true);
                try {
                    const result = await modelGateway.getEscrituraPublicaByNRD(localNRD);
                    if (result.info && result.escritura) {
                        setNewInfo({
                            nifNotario: result.info.nifNotario.toUpperCase(),
                            notario: result.info.notario.toUpperCase(),
                            fecha: result.info.fecha,
                            anyo: result.info.anyo,
                            numeroProtocolo: result.info.protocolo,
                            nrd: localNRD,
                            idDocEscritura:result.escritura.IdDocumento
                        });
                        setDoc(result.escritura);
                    } else {
                        setNewInfo(null);
                        setDoc(undefined);
                        onChangeOrigenDoc(2);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Plusvalias', 'search_by_nrd_not_found', terms),
                                variant: 'error',
                            }
                        });
                    }
                } catch (error) {
                    if (error instanceof CustomError && error.code === 403) {
                        setShouldBlock(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Global', 'sesion_caducada', terms),
                                variant: 'warning',
                                hasCustomAction: true,
                                handleCustomAction: () => { 
                                    pageDispatcher({ type: "logout" });
                                }
                            }
                        });
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI', 'search_by_nrd_err', terms),
                                variant: "error"
                            }
                        });
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                setNewInfo(null)
                setDoc(undefined)
            }
        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'search_by_nrd_err', terms),
                    variant: 'error',
                }
            });
        }
        
    }

    useEffect(() => {
        if(show){
            setLocalNRD(undefined)
            setNewInfo(null)
        }
    }, [show])

    return (   
        <Modal open={show} fullWidth maxWidth='md'
        title={translate('DUTI', 'title_modal_nrd', terms)}
        renderContent={ () => (
            <>
            <FullLoading loading={loading} animation={{type:'intricom',name:'DUSTI'}}/>
            <Grid container direction='column' spacing={1} style={{paddingRight: 20, paddingLeft: 20}} >
                    <Grid item>
                    <Typography >{translate('DUTI', 'text_modal_nrd', terms)}</Typography>
                        <div style={{marginTop: 10}}>
                            
                            <InputSearch
                                fieldTerm='search_nrd'
                                value={localNRD}
                                //required
                                forceUpperCase={true}
                                className={classes.input360}
                                onChangeValue={(v:string|undefined, err: boolean) => {
                                    setLocalNRD(v)
                                }}                        
                                onSearch={searchByNRD } //() => searchByNRD(localNRD)}
                            />

                        </div>
                    </Grid>
                    <Grid item>
                        {newInfo &&
                            <div className={classes.column}>
                                <div className={classes.row}>
                                    <InputDate 
                                        fieldTerm="fecha"
                                        value={newInfo.fecha}
                                        onChangeValue={(v: Date| undefined, err:boolean) =>{}}
                                        disabled={true}
                                    />
                                    <TypedInputNumber
                                        type="year"
                                        fieldTerm={'anyo'}
                                        value={newInfo.anyo}
                                        onChangeValue={(v:number|undefined, err:boolean) =>{}}
                                        disabled={true}
                                        required={false}
                                        //className={classes.input200}
                                    />
                                    <InputText
                                        fieldTerm={'numeroProtocolo'}
                                        value={newInfo.numeroProtocolo}
                                        onChangeValue={(v:string|undefined, err: boolean) =>{ }}
                                        disabled={true}
                                        //className={classes.input200}

                                    />
                                </div>
                                <div style={{width: (260+10)*3}}>
                                    <InputText fullWidth
                                        fieldTerm={'notario'}
                                        value={newInfo.notario}
                                        onChangeValue={(v:string|undefined, err: boolean) =>{ }}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        }
                        
                    </Grid>
            </Grid>
            </>
        )}
        renderActions={() => (
            <div >
                <Button style={{marginRight: 15}}
                        variant="contained" color="inherit"
                        onClick={onClose} 
                        disabled={loading}
                    >
                    {translate('DUTI', 'btn_close',terms)}
                </Button>
                <Button
                    disabled={!newInfo}
                    variant="contained" color="primary"
                    onClick={() => newInfo && onConfirm(doc, newInfo, localNRD ) }
                >
                    {translate('DUTI', 'btn_continuar',terms)}
                </Button>
            </div>
        )}
        /> 
    )   

}

export default  withLiterals(['DUTI'])(withStyles(duti_styles)(ModalSearchByNRD));