import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import duti_styles from "../styles";
import { TCausaUserChoice, TDTDatosComprador, TDTDatosOperacionM600, TipoM600_OPTIONS } from "./types";
import FullLoading from "components/full-loading";
import { LiteralsContext } from "containers/shared/literals";
import { translate } from "utils/i18n";
import LabelWithInfo from "components/DUTI/label-with-info";
import InputSelect from "components/DUTI/input-select";
import { TSelect, TSelectExtended, calcularEdad } from "../utils";
import InputText from "components/DUTI/input-text";
import DUTIGateway from "gateways/duti.gateway";
import IoC from "contexts/ioc.context";
import { IConceptoM600 } from "gateways/duti.interface";
import usePage from "hooks/page.hook";
import TypedInputNumber from "components/DUTI/typed-input-number";
import { AlertsContext } from "contexts/alerts.context";
import CheckBoxGroup from "components/DUTI/check-box-group";
import ModalConceptosSugeridosM600 from "components/DUTI/modal-conceptos-sugeridos-m600";

import axios from "axios";
import { CustomError } from "utils/custom.error";
import { TTipoInmueble, TUsoInmueble } from "gateways/catastro.interface";
import { isCIF } from "utils/validateNif";
import SearchIcon from '@material-ui/icons/Search';
import { ContenidosGateway } from "gateways/contenido.gateway";

const useStyles = makeStyles(duti_styles);
const CONCEPT_DIABLED_NoSuj_Exent = ['TP10']
interface IProps {
    idDeclaracion: number | null,
    ejercicio: string;
    initialDatosOperacion: TDTDatosOperacionM600,
    fechaDevengo: Date;
    //porcentaje: number,
    inmueble: {tipoBien: TTipoInmueble|undefined, uso:TUsoInmueble|undefined, refCat: string|undefined }
    compradores: TDTDatosComprador
    nifSujeto: string
    handleContinuar: (v:TDTDatosOperacionM600,haveChanges:boolean,notClean: boolean) => void
    handleBack: () => void,
    handleCancelDUTI: (c: TCausaUserChoice, v:TDTDatosOperacionM600 ) => void,
    setShouldBlock: (v: boolean) => void,
}

const DatosTransmisionDatosOperacionM600: FC<IProps> = ({
    idDeclaracion,
    ejercicio,
    initialDatosOperacion,
    fechaDevengo,
    //porcentaje,
    inmueble,
    compradores,
    nifSujeto,
    handleContinuar,
    handleBack,
    handleCancelDUTI,
    setShouldBlock,
}) => {
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();
    const classes= useStyles();
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);

    const IMPORTE_TP = 270151.20 //4,5,6,8,9,10)
    const IMPORTE_TP7= 350000.00

    const esCIF = useMemo(() => { 
        const out = isCIF(nifSujeto) 
        //console.log('es CIF ', nifSujeto, out)
        return out
    },[nifSujeto])
   
    
    const [loading, setLoading] = useState(false);
    const idConceptoactosJurDoc =3801  // ACTOS JURÍDICOS DOCUMENTADOS
    const Tipo_OPTIONS = TipoM600_OPTIONS.map( item => ({id: item, nombre: translate('DUTI',item,terms)}) ) //
    const [conceptoOPTIONS, setConceptoOPTIONS] = useState<TSelectExtended[]>([])
    const [conceptos, setConceptos] = useState<IConceptoM600[] | null>(null)
    const [datosOperacion, setDatosOperacion] = useState({} as TDTDatosOperacionM600)
    const [datosOperacionErr, setDatosOperacionErr] = useState<Record<keyof TDTDatosOperacionM600, boolean>>({
        concepto: false, exento: false, noSujeto: false, observaciones:false, baseImponible: false, valorTotalInmueble: false, 
        tipo: false, tipoAplicable: false, cuotaIntegra: false, interesesDemora: false, recargo: false,
        pagaRecargo: false, reduccion:false, reduccionSobreImponible: false, bonificacion: false,
        bonificacionSobreCuota: false, totalIngresar: false, idExpedientePresentado: false, 
        aIngresar:false, baseLiquidable:false
    })
    const [haveChanges, setHaveChanges] = useState(false);
    const [showConceptosModal, setShowConceptosModal] = useState(false);
    const [showConceptos, setShowConceptos] = useState(false);
    const [valorReferenciaInm, setValorReferenciaInm]= useState<number | undefined>(undefined);
    const [MSGValorDeclarado, setMSGValorDeclarado] = useState<string>('');
    const [doneValidateConcepto, setDoneValidateConcepto] = useState(false);
    const [doneValidateValTotInm, setDoneValidateValTotInm] = useState(false);

    const componentMounted= useRef(true);
    //console.log('valorReferenciaInm', valorReferenciaInm)

    
    const checkEdadCompradores = async (maxEdad:number) : Promise<{
        ok: boolean;
        sujErrEdad:{c_nif: string; c_nom: string; edad: boolean;fechaValida: boolean; }[]
        sujErrFecha:{c_nif: string; c_nom: string; edad: boolean;fechaValida: boolean; }[]
        notFechaNacieminto: boolean
    }> => {
        try {
            let genErr=false;
            let notfecha=false
            const check = await Promise.all(
                compradores.map( async (c)=> {
                    let out = {c_nif: c.nif , c_nom: c.nomApe, edad:true, fechaValida:true}
                    if(c.fechaNacimiento){
                        const edad = calcularEdad(c.fechaNacimiento)
                        // console.log('edad', edad)
                        if(edad<=maxEdad){
                            const valid = await dutiGateway.validarFechaNacimiento(c.nif, c.fechaNacimiento)
                            out={...out,edad: true}
                            if(valid === false){ genErr=true}
                            out={...out,fechaValida: valid}
                        } else{
                            out= {...out,edad: false}
                            genErr=true
                        }
                    } else {
                        out={...out, fechaValida: false}
                        notfecha=true
                    }
                    return out
                })
            )

            const edad = check.filter( c => c.edad === false)
            const fecha = check.filter( c => c.fechaValida === false)
            
            return { 
                ok: (edad.length===0 && fecha.length===0) ? true : false, 
                sujErrEdad: edad, 
                sujErrFecha: fecha,
                notFechaNacieminto: notfecha 
            };

        } catch (error){
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: axios.isAxiosError(error) ? error.message : error,
                    variant: 'error'
                }
            })
            return { 
                ok: false, 
                sujErrEdad: [], 
                sujErrFecha: [],
                notFechaNacieminto: false
            };
        }
    }

    const validateConcepto = async (codigo:string) => {
        const maxImporte = ['TP4','TP5','TP6','TP8','TP9','TP10'].includes(codigo) 
            ? IMPORTE_TP
            : codigo === "TP7" ? IMPORTE_TP7: -1;
        const maxEdad= (codigo === "TP5") ? 36 : (codigo === "TP9") ? 30: -1
        //const vivHab = ["TP4","TP5","TP6","TP7","TP8","TP9","TP10"].includes(codigo)
        let isOk = true;
        if(maxEdad !== -1){
            const comprEval = await checkEdadCompradores(maxEdad)
            //console.log('comprEval', comprEval)
            isOk=(comprEval.ok === false || comprEval.notFechaNacieminto)? false : true
            if(comprEval.ok === false && !comprEval.notFechaNacieminto && (comprEval.sujErrEdad.length>0 || comprEval.sujErrFecha.length>0) ){
                //isOk=false
                // Hay compradores que no cumplen
                const MSGErrorEdad =comprEval.sujErrEdad.length>0 
                    ? translate('DUTI','error_concepto_edad', terms, [codigo, maxEdad])
                    : translate('DUTI','error_concepto_fecha_nacimiento', terms);

                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message:`
                            <p>${MSGErrorEdad}<p>
                            ${comprEval.sujErrEdad.length>0 
                                ? 
                                comprEval.sujErrEdad.map( c => {
                                    return `<p> &nbsp; &nbsp; &nbsp; ${c.c_nom} - ${c.c_nif}<p>`
                                }).join()
                                : 
                                comprEval.sujErrFecha.map( cf => {
                                    return `<p> &nbsp; &nbsp; &nbsp; ${cf.c_nom} - ${cf.c_nif}<p>`
                                }).join()
                            }
                        `,
                        isHtml: true,
                        variant: 'error'
                    }
                })
            }
            if(comprEval.notFechaNacieminto){
                //isOk=false
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message:`
                            <p>${translate('DUTI','error_concepto_no_fecha_nacimiento', terms)}<p>
                            ${comprEval.sujErrFecha.length>0 &&
                                comprEval.sujErrFecha.map( cf => {
                                    return `<p> &nbsp; &nbsp; &nbsp; ${cf.c_nom} - ${cf.c_nif}<p>`
                                }).join()
                            }
                        `,
                        isHtml: true,
                        variant: 'error',
                    }
                })
            }
        }

        // Evaluar importe
        if(isOk && maxImporte !== -1){
            if(datosOperacion.valorTotalInmueble > maxImporte){
                isOk = false
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message:translate('DUTI', 'error_concepto_importe', terms,[codigo, maxImporte, datosOperacion.valorTotalInmueble]),
                        variant: 'error',
                    }
                })
            }
        }
        // Evaluar Viv.Hab
        if(isOk && inmueble.uso?.includes('Almacen-Estacionamiento') && codigo !== 'TGA'){
            isOk = false;
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message:translate('DUTI', 'error_concepto_garaje', terms,[codigo]),
                    variant: 'error',
                }
            })
            
        }
        if(!isOk){
            setDoneValidateConcepto(true)
        }
        return isOk
    }

    const onValidate = async () => {
        try {
            setLoading(true)
            if(datosOperacion.tipo && datosOperacion.tipo.id && datosOperacion.concepto && datosOperacion.concepto.id 
                && datosOperacion.baseImponible <= datosOperacion.valorTotalInmueble
                && ( 
                    (( datosOperacion.noSujeto || datosOperacion.exento ) && datosOperacion.observaciones && datosOperacion.observaciones !== "" )
                    || (!datosOperacion.noSujeto && !datosOperacion.exento )
                )
            ){
                
                // Validar edad en conceptos TP5 menores 36, TP9 menores 30
                // Validar importe inmueble TP 4,5,6,8,9,10 <=270.151,20 , tp7<=350000
                // Validat TGA en caso de uso 'Almacen-Estacionamiento'
                const isOK = !doneValidateConcepto ?  await validateConcepto(datosOperacion.concepto.id): true

                if(isOK){
                    if(!haveChanges){
                        handleContinuar({...datosOperacion},false,true)
                        setHaveChanges(false)
                    } else {
                        try {
                            const response = await dutiGateway.calcularModelo('600', idDeclaracion, null, null, {
                                ...datosOperacion, 
                                pagaRecargo: true,
                                tipoAplicable: undefined,
                                interesesDemora: undefined,
                                recargo: 0,
                                baseLiquidable: undefined,
                                bonificacion: 0,
                                bonificacionSobreCuota: undefined,
                                cuotaIntegra: undefined,
                                aIngresar: undefined,
                                totalIngresar: undefined,
                                reduccion: 0,
                                reduccionSobreImponible: undefined,
                            });
                            //console.log('response calc. M600', response)
                            if(response && response.ok){
                                const datosFinal : TDTDatosOperacionM600= {
                                    ...datosOperacion,
                                    tipoAplicable: (response.data as TDTDatosOperacionM600).tipoAplicable,
                                    cuotaIntegra: (response.data as TDTDatosOperacionM600).cuotaIntegra , 
                                    baseImponible: (response.data as TDTDatosOperacionM600).baseImponible , 
                                    interesesDemora: (response.data as TDTDatosOperacionM600).interesesDemora , 
                                    recargo: (response.data as TDTDatosOperacionM600).recargo,
                                    reduccion: (response.data as TDTDatosOperacionM600).reduccion,
                                    reduccionSobreImponible: (response.data as TDTDatosOperacionM600).reduccionSobreImponible,
                                    bonificacion: (response.data as TDTDatosOperacionM600).bonificacion,
                                    bonificacionSobreCuota: (response.data as TDTDatosOperacionM600).bonificacionSobreCuota,
                                    totalIngresar: (response.data as TDTDatosOperacionM600).totalIngresar, 
                                }
                                //console.log('Add calculados', datosFinal)
                                //setChanges()
                                handleContinuar(datosFinal,true,false)
                                setHaveChanges(false)
                            }
                        } catch (error) {
                            if (error instanceof CustomError && error.code === 403) {
                                setShouldBlock(false);
                                alertsDispatch({
                                    type: 'show-alert',
                                    payload: {
                                        message: translate('Global', 'sesion_caducada', terms),
                                        variant: 'warning',
                                        hasCustomAction: true,
                                        handleCustomAction: () => { 
                                            pageDispatcher({ type: "logout" });
                                        }
                                    }
                                });
                            } else {
                                alertsDispatch({
                                    type: 'show-alert',
                                    payload: {
                                        message: translate('DUTI','error_dusti', terms),
                                        variant: "error"
                                    }
                                });
                            }
                        }
                    }
                }
                
            } else {
                setDatosOperacionErr(curr => ({
                    ...curr,
                    tipo: (datosOperacion.tipo && datosOperacion.tipo.id) ? false : true,
                    concepto: (datosOperacion.concepto && datosOperacion.concepto.id ) ? false : true,
                    observaciones: (( datosOperacion.noSujeto || datosOperacion.exento ) && datosOperacion.observaciones && datosOperacion.observaciones !== "" ) ? false :true,
                    baseImponible: datosOperacion.baseImponible <= datosOperacion.valorTotalInmueble ? false : true
                }))
               
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI','error',terms),
                    variant: 'error',
                }
           })

        }
    }

    const validateValorReferenciaRef = (vRef: number | undefined, vInm: number, currVal: boolean,openMSug: boolean, msg?: string) => {
        if(vRef && vRef > vInm && !currVal){
            setDoneValidateValTotInm(true)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: MSGValorDeclarado !== '' ? MSGValorDeclarado : msg,
                    isHtml: true,
                    variant: 'warning',
                    hasCustomAction: openMSug ? true :false,
                    defaultCloseActionTemplateName: translate("DUTI", "btn_close", terms).toUpperCase(),
                    actionTemplateName: translate("DUTI", "btn_continuar", terms).toUpperCase(),
                    handleCustomAction: () => { 
                        // Abrir moda conceptos
                        setShowConceptosModal(true);
                        alertsDispatch({type: 'hide-alert'}); 
                    }
                }
            });
            return false
        } else {
            // Abrir moda conceptos
            openMSug && setShowConceptosModal(true);
            return true
        }
    }

    useEffect(() => {
        (async() => {
            setLoading(true);

            try {
                const response = await dutiGateway.getConceptosM600(ejercicio);
                const valRef = await dutiGateway.getValorReferencia(inmueble.refCat ?? '', fechaDevengo);
                const MSGValorDecl = await contenidoGateway.getContent( 'info_modal_valorReferencia_Declarado', {});
                if (componentMounted.current) {
                    setValorReferenciaInm(valRef)
                    setConceptos(response);
                    const conceptosOpt = response.map(c => ({id: c.idConcepto.toString(), nombre: c.codigo.concat(` -  ${translate('DUTI', `600_concepto_${c.codigo}`,terms)}`), param: c.idConceptoPadre}))
                    setConceptoOPTIONS(conceptosOpt);
                    setDatosOperacion({...initialDatosOperacion});
                    setMSGValorDeclarado(MSGValorDecl[0].contenido);
                    setShowConceptos(initialDatosOperacion.tipo && initialDatosOperacion.tipo.id ? true: false)
             
                    if(initialDatosOperacion.valorTotalInmueble>0 && valRef){
                        validateValorReferenciaRef(valRef, initialDatosOperacion.valorTotalInmueble, doneValidateValTotInm,false, MSGValorDecl[0].contenido)
                    }
                }
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            } finally {
                setLoading(false);
            }
            
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    }, [])



    return (
        <Grid container direction='column' className={classes.componenteContainer}>
            <FullLoading loading={ loading  } animation={{type:'intricom',name:'DUSTI'}} />
            <Grid item container className={classes.columnContainer}  >
                <LabelWithInfo type="title" fieldTerm="DT_IntroduccionDatosM600_title" moreInfo= {{template: 'info_title_DT_IntroduccionDatosM600_title', size: 'xs'}}/>  
                <div style={{flex:1, flexGrow:1, overflowY: 'auto'}}>
                    

                    <Grid item container direction="row" alignItems="flex-start">
                        {/**Valor total del inmueble Casilla 63 - M600 */}
                        <Grid item>
                            <TypedInputNumber 
                                focused = {true}
                                type="money"
                                min={0}
                                allowZero={false}
                                maxDecimal={2}
                                //max={//valorDeclarado}
                                fieldTerm={'valorTotalInmueble'}
                                value={datosOperacion.valorTotalInmueble}
                                onChangeValue={(v: number|undefined, err: boolean) => {
                                    setDatosOperacion(curr => ({...curr, valorTotalInmueble: v ?? 0}))
                                    setHaveChanges(true)
                                    setDatosOperacionErr(prev => ({...prev,valorTotalInmueble:err}))
                                    setHaveChanges(true)
                                    setDoneValidateValTotInm(false)
                                }}
                                required={true} 
                                error={datosOperacionErr.valorTotalInmueble}
                                moreInfo={{
                                    template:'info_field_valor_total_inmueble', size: 'sm'
                                }}
                                className={classes.input285}
                            /> 
                        </Grid>
                        {/**Base imponible Casilla 70 - M600 */}
                        <Grid item style={{marginRight: 20}}>
                            <TypedInputNumber
                                type="money"
                                disabled={datosOperacion.valorTotalInmueble === 0 }// !datosOperacion.concepto }
                                min={0}
                                allowZero={true}
                                maxDecimal={2}
                                max={datosOperacion.valorTotalInmueble}
                                fieldTerm={'baseImponible'}
                                value={datosOperacion.baseImponible}
                                onChangeValue={(v: number|undefined, err: boolean) => {
                                    setDatosOperacion(curr => ({...curr, baseImponible: v ?? 0}))
                                    setHaveChanges(true)
                                    setDatosOperacionErr(prev => ({...prev,baseImponible:err}))
                                    setHaveChanges(true)
                                }}
                                required={true} 
                                error={datosOperacionErr.baseImponible}
                                errorMsgExterno={translate('DUTI','error_baseImponible',terms, [(datosOperacion.valorTotalInmueble ?? 0).toLocaleString('es',{maximumFractionDigits:2, minimumFractionDigits:0} )])}
                                moreInfo={{
                                    template:'info_field_base_imponible', size: 'sm'
                                }}
                                className={classes.input285}
                            /> 
                        </Grid>
                        <Grid item style={{marginTop:5}}>
                            {/**BOTóN par avalidar valorTotalInmueble con valor de referencia + gestión modal conceptos sugeridos*/}
                            <Button onClick={() => validateValorReferenciaRef(valorReferenciaInm, datosOperacion.valorTotalInmueble, doneValidateValTotInm, true)}
                                variant="contained" 
                                color="primary"
                                disabled={datosOperacion.valorTotalInmueble === 0 || datosOperacionErr.baseImponible}
                                endIcon={ <SearchIcon/> }
                            >
                                {translate('DUTI', 'select_concepto', terms)}
                            </Button>
                        </Grid>
                    </Grid>

                    {/** Exenciones y No sujeciones */}
                    <Grid item container direction="row">
                        <Grid item className={classes.row} style={{marginBottom: 28, alignItems:'flex-start', marginRight: 5}}>
                            <div className={classes.borderM600} style={{alignContent: 'center'}}>
                                <CheckBoxGroup 
                                    disabled={ datosOperacion.concepto !== undefined && CONCEPT_DIABLED_NoSuj_Exent.includes(datosOperacion.concepto.id) }
                                    fieldTerm=''
                                    labelAs='notLabel'
                                    row
                                    value= {datosOperacion.exento === true
                                        ? 'exento'
                                        : datosOperacion.noSujeto === true ? 'noSujeto': undefined
                                    }
                                    options={[
                                        {id: 'noSujeto', term:'noSujeto' },
                                        {id: 'exento', term:'exento' }
                                    ]}
                                    onChangeValue={(key:string,v:boolean) => {
                                        setHaveChanges(true)
                                        //setChanges()
                                        setDatosOperacion(curr => ({...curr,
                                            exento: key === 'exento'? v : false,
                                            noSujeto: key === 'noSujeto' ? v : false,
                                            observaciones: (key === 'exento'? v : false) === false && (key === 'noSujeto' ? v : false) === false 
                                                ? undefined
                                                : curr.observaciones
                                        }))
                                        
                                    }}
                                />
                            </div>
                            <div style={{marginTop: 2}}>
                                <LabelWithInfo fieldTerm={' '} moreInfo={{template: 'info_field_exento_noSujeto', size: 'xs'}}/>
                            </div>
                        </Grid>
                        <Grid item style={{display:'flex', flexGrow:1}} >
                            { (datosOperacion.noSujeto || datosOperacion.exento) && 
                            <InputText
                                    fieldTerm='observaciones'
                                    value={datosOperacion.observaciones}
                                    multiline
                                    rows={4}
                                    required={datosOperacion.noSujeto || datosOperacion.exento}
                                    onChangeValue={ (v:string,err: boolean) => {
                                        setDatosOperacion(curr => ({...curr, observaciones: v}))
                                        setDatosOperacionErr(curr => ({...curr, observaciones: err}))
                                        setHaveChanges(true)
                                    }}
                                    fullWidth
                                />
                            }
                        </Grid>
                    </Grid>
                    
                    
                    { showConceptos && 
                    <>
                        <LabelWithInfo type="field" fieldTerm="DT_IntroduccionDatosM600Conceptos_title" /> 
                        <Grid item container direction="row">
                            <Grid item style={{marginRight: 8}}>
                                <InputSelect disabled={loading || datosOperacion.valorTotalInmueble === 0 || datosOperacionErr.baseImponible}
                                    fieldTerm='tipo'
                                    value={datosOperacion.tipo}
                                    options={Tipo_OPTIONS}
                                    onChangeValue={(v: TSelect, err: boolean) => {
                                        setDatosOperacion(curr => ({...curr, 
                                            tipo: v, 
                                            concepto: undefined,
                                        }));
                                        setDatosOperacionErr(curr => ({...curr, tipo: err}))
                                        setHaveChanges(true)
                                        //setChanges()
                                    }}
                                    required
                                    error={datosOperacionErr.tipo}
                                    className={classes.input300}
                                />
                            </Grid>
                            <Grid item style={{display:"flex", flexGrow: 1}}>
                            <InputSelect
                                disabled={datosOperacion.tipo === undefined || datosOperacion.valorTotalInmueble === 0 || datosOperacionErr.baseImponible}
                                fieldTerm='concepto'
                                fullWidth
                                value={datosOperacion.concepto}
                                options={
                                    datosOperacion.tipo?.id 
                                        ? conceptoOPTIONS.filter( c => (
                                            datosOperacion.tipo?.id  === 'acto-juridico-docum' 
                                            ? c.param === idConceptoactosJurDoc 
                                            : c.param !== idConceptoactosJurDoc )) 
                    
                                        : conceptoOPTIONS
                                }
                                onChangeValue={(v: TSelect, err: boolean) =>{ 
                                    setDatosOperacion(curr => ({...curr, concepto: v}));
                                    setDatosOperacionErr(curr => ({...curr, concepto: err}));
                                    setHaveChanges(true);
                                    setDoneValidateConcepto(false)
                                }}
                                required
                                error={datosOperacionErr.concepto}
                                className={classes.inputFullWidth}
                            />
                            </Grid>
                        </Grid>
                    </>
                        
                    }

                </div>
            </Grid>
            <Grid item container direction="row" justify="space-between" alignItems="flex-end" className={classes.footerColumnContainer}>
                <Button variant="contained" onClick={handleBack} color="inherit">
                    {translate('DUTI', 'btn_back',terms)}
                </Button>
                <div>
                    <Button style={{marginRight: 10}}
                            variant="outlined" color="primary"
                        onClick={() => {
                            handleCancelDUTI('continuar-mas-tarde', datosOperacion ) 
                            // Modal que muestra el useBlocker - DutiComponent
                            // alertsDispatch({
                            //     type: 'show-alert',
                            //     payload: {
                            //         message: translate('DUTI', 'confirm_cancelDUTI_user_choice', terms),
                            //         variant: 'warning',
                            //         hasCustomAction: true,
                            //         defaultCloseActionTemplateName: translate("DUTI", "no", terms).toUpperCase(),
                            //         actionTemplateName: translate("DUTI", "si", terms).toUpperCase(),
                            //         handleCustomAction: () => { 
                            //             handleCancelDUTI('continuar-mas-tarde', datosOperacion ) 
                            //             alertsDispatch({type: 'hide-alert'})
                            //         }
                            //     }
                            // });
                        }} 
                        
                    >
                        {translate('DUTI', 'btn_pause_duti',terms)}
                    </Button>
                    <Button 
                        variant="contained"  
                        color="primary"
                        onClick={() =>{ 
                            let ok = true
                            if(!doneValidateValTotInm){
                                ok = validateValorReferenciaRef(valorReferenciaInm, datosOperacion.valorTotalInmueble, doneValidateValTotInm, false)
                            } 
                            ok && onValidate()
   
                        }}
                        disabled={!datosOperacion.tipo || !datosOperacion.tipo.id || !datosOperacion.concepto || !datosOperacion.concepto.id}
                    >
                        {translate('DUTI', 'btn_continuar',terms)}
                    </Button>
                    </div>
                
            </Grid>

            <ModalConceptosSugeridosM600
                show={showConceptosModal}
                conceptos={conceptos}
                onSelected={(c: IConceptoM600) => {
                    const formatConcept = {id: c.idConcepto.toString(), nombre: c.codigo.concat(` -  ${translate('DUTI', `600_concepto_${c.codigo}`,terms)}`), param: c.idConceptoPadre}
                    const tipo = Tipo_OPTIONS.find(t =>  c.idConceptoPadre === idConceptoactosJurDoc ? t.id === 'acto-juridico-docum': t.id === 'transm-patrimonial')
                    if(tipo){
                        setDatosOperacion(curr => ({...curr, tipo: {...tipo},concepto: {...formatConcept}}) );
                        setShowConceptosModal(false);
                        setHaveChanges(true);
                        setShowConceptos(true);
                        setDoneValidateConcepto(false);
                    }
                }}
                tipoBien= {inmueble.tipoBien}
                uso={inmueble.uso}
                viviendaHabitual = {compradores.map(c => (c.viviendaHabitual))}
                edad={ compradores.map(c => (c.fechaNacimiento ? calcularEdad(c.fechaNacimiento) : -1))}
                valorInmueble= {datosOperacion.valorTotalInmueble}
                esCIF={esCIF}
                onClose={() => {
                    setShowConceptosModal(false);
                    setShowConceptos(true)
                }}
            />
        </Grid>
    )
}

export default DatosTransmisionDatosOperacionM600;


