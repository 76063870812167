import React, { FC, useContext } from "react";
import Term from "components/term";
import SelectMultiple from '../../../components/select-multiple';
import { FormControl, InputLabel, makeStyles, TextField } from "@material-ui/core";
import { FiltersContext } from "./filters.context";
import { FilterDataContext } from "./filter-data.context";
import { dateFormatToUTCDate } from "utils/dates";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    formControl: {
        margin: `30px 0`,
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    formDateControl: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            margin: `10px 5px`,
        }
    },
    group: {
        margin: `10px 0`,
    },
    block: {
        display: `inline-block`
    },
    hidden: {
        display: `none`
    },
    middle: {
        verticalAlign: `middle`
    },
}));

export type ScreenType = 'conductor';

export interface IFiltersFormProps {
    screen: ScreenType;
}

const FiltersForm: FC<IFiltersFormProps> = ({ screen }) => {

    const [filters, filtersDispatch] = useContext(FiltersContext);
    const filterData = useContext(FilterDataContext);

    const handleMatriculaChange = (event: React.ChangeEvent<HTMLInputElement>) => filtersDispatch({ type: 'update', filter: 'matricula', value: event.target.value ? event.target.value.toUpperCase().replace(/\s+|-+/g, '') : null });
    const handleGravedadChange = (gravedad: string[]) => filtersDispatch({ type: 'update', filter: 'gravedad', value: gravedad });
    const handleMunicipioChange = (municipio: string[]) => filtersDispatch({ type: 'update', filter: 'municipios', value: municipio });
    const handleEstadoChange = (estado: string[]) => filtersDispatch({ type: 'update', filter: 'estadoIdentificacion', value: estado });
    const handleObligatoriedadChange = (opcional: string[] | null) => filtersDispatch({ type: 'update', filter: 'obligatoriedad', value: opcional });
    const handleFechaLimiteDesdeChange = (startDate: Date | null) => startDate !== null &&  moment(startDate).isValid() ? filtersDispatch({ type: 'update', filter: 'fechaLimiteDesde', value: dateFormatToUTCDate(startDate) }) : () => {};
    const handleFechaLimiteHastaChange = (endDate: Date | null) => endDate !== null && moment(endDate).isValid() ? filtersDispatch({ type: 'update', filter: 'fechaLimiteHasta', value: dateFormatToUTCDate(endDate) }) : () => {};
    const handleFechaMultaDesdeChange = (startDate: Date | null) => startDate !==null && moment(startDate).isValid() ? filtersDispatch({ type: 'update', filter: 'fechaMultaDesde', value: dateFormatToUTCDate(startDate) }) : () => {};
    const handleFechaMultaHastaChange = (endDate: Date | null) => endDate !==null && moment(endDate).isValid() ? filtersDispatch({ type: 'update', filter: 'fechaMultaHasta', value: dateFormatToUTCDate(endDate) }) : () => {};

    const classes = useStyles();

    return (
        <div className={classes.root} style={{ display: 'contents' }}>
            <FormControl key='matricula' className={classes.formControl} fullWidth>
                <TextField
                    key='matricula'
                    value={filters.matricula || ''}
                    label={<Term component="Global" text="Matricula" />}
                    onChange={handleMatriculaChange}
                />
            </FormControl>
            <FormControl key='fechaDesde' className={filterData ? classes.formDateControl : classes.hidden} fullWidth>
                <KeyboardDatePicker
                    label={<Term component="Tramites" text="fechalimitedesde" />}
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    format="dd/MM/yyyy"
                    value={filters.fechaLimiteDesde}
                    maxDate={filters.fechaLimiteHasta ? filters.fechaLimiteHasta : undefined}
                    onChange={handleFechaLimiteDesdeChange}
                />
                <KeyboardDatePicker
                    label={<Term component="Tramites" text="fechalimitehasta" />}
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    format="dd/MM/yyyy"
                    value={filters.fechaLimiteHasta}
                    minDate={filters.fechaLimiteDesde ? filters.fechaLimiteDesde : undefined}
                    onChange={handleFechaLimiteHastaChange}
                />
             </FormControl>
            <FormControl key='fechaMulta' className={filterData ? classes.formDateControl : classes.hidden} fullWidth>
                <KeyboardDatePicker
                    label={<Term component="Tramites" text="Fecha Multa Desde" />}
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    format="dd/MM/yyyy"
                    value={filters.fechaMultaDesde}
                    maxDate={filters.fechaMultaHasta ? filters.fechaMultaHasta : undefined}
                    onChange={handleFechaMultaDesdeChange}
                />
                <KeyboardDatePicker
                    label={<Term component="Tramites" text="Fecha Multa Hasta" />}
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    format="dd/MM/yyyy"
                    value={filters.fechaMultaHasta}
                    minDate={filters.fechaMultaDesde ? filters.fechaMultaDesde : undefined}
                    onChange={handleFechaMultaHastaChange}
                />
            </FormControl>

            <FormControl key='gravedad' className={filterData && filterData.gravedad ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-gravedad' htmlFor="select-multiple-chip">
                    <Term component="Global" text="Gravedad" />
                </InputLabel>
                <SelectMultiple
                    key='gravedades'
                    className={filterData && filterData.gravedad ? '' : classes.hidden}
                    options={filterData && filterData.gravedad ? filterData.gravedad : []} value={filters.gravedad || []}
                    onChange={(values) => handleGravedadChange(values)}
                />
            </FormControl>
            <FormControl key='municipio' className={filterData && filterData.municipios ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-municipio' htmlFor="select-multiple-chip">
                    <Term component="Global" text="Municipio / Organismo" />
                </InputLabel>
                <SelectMultiple
                    key='municipios'
                    options={filterData && filterData.municipios ? filterData.municipios : []} value={filters.municipios || []}
                    onChange={(values) => handleMunicipioChange(values)}
                />
            </FormControl>
            <FormControl key='estadoIdentificacion' className={filterData && filterData.estadoIdentificacion ? classes.formControl : classes.hidden} fullWidth>
                <InputLabel key='label-estado' htmlFor="select-multiple-chip">
                    <Term component="Global" text="EstadoIdentificacion" />
                </InputLabel>
                <SelectMultiple
                    key='estados'
                    options={filterData && filterData.estadoIdentificacion ? filterData.estadoIdentificacion : []} value={filters.estadoIdentificacion || []}
                    onChange={(values) => handleEstadoChange(values)}
                />
            </FormControl>
            <FormControl key='opcional' className={classes.formControl} fullWidth>
                <InputLabel key='label-estado' htmlFor="select-multiple-chip">
                    <Term component="Global" text="obligatoriedad" />
                </InputLabel>
                <SelectMultiple
                    key='obligatoriedad'
                    options={filterData && filterData.obligatoriedad ? filterData.obligatoriedad : []} value={filters.obligatoriedad || []}
                    onChange={(values) => handleObligatoriedadChange(values)}
                />
            </FormControl>
        </div>
    );
}

export default FiltersForm;