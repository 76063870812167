import React, { FC, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import FiltersReducer, { IFiltersState } from "../filters.reducer";
import { styles } from "../conductor";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, WithStyles } from "@material-ui/core";
import NoContent from "components/no-content";
import Term from "components/term";
import { IRemesaMultas, IMultas } from "gateways/tramites.interfaces";
import image from '../../../../resources/no-deuda.png';
import InfiniteScroll from "react-infinite-scroller";
import MultaRemesaCard from "../multa-remesa.card";
import MultaCard from "../multas.card";
import { TramitesGateway } from "gateways/tramites.gateway";
import { LiteralsContext } from "containers/shared/literals";
import usePage from "hooks/page.hook";
import { useLocation } from "react-router";
import IoC from "contexts/ioc.context";
import OrderReducer, { IOrderState } from "../order.reducer";
import { translate } from "utils/i18n";
import { ImporterGateway } from "gateways/importer.gateway";
import { IMessageResponse, IResult } from "gateways/importer.interfaces";
import Icon from "@mdi/react";
import { mdiFileDownload, mdiFileExportOutline, mdiFileImportOutline } from "@mdi/js";
import WizardImporter from '../../../../components/wizard';
import TableWizard from "components/table-wizard";
import TableWizardResult from "components/table-wizard-result";
import { FILE_EXTENSION_XLSX, FILE_EXTENSION_CSV } from "constants/file-extension-types";


interface IProps extends WithStyles<typeof styles> { 
    isRentACar:boolean;
    filters: IFiltersState,
    showExportImportDialog: boolean
    handleRemesasMultasCount: (count:number) => void
    handleTotalRemesasMultasCount: (count:number) => void
    handleSelectRemesa: (remesa: IRemesaMultas) => void
    handleCloseExportImportDialog: () => void
    handleDownloadDatos: () => void
    handlePrintJustificante: (list: any) => Promise<boolean>
    handleExportPlantillaNotificaciones: (fileExtension: 'csv' | 'xlsx')=> void
}
const Remesas : FC<IProps>= ({
    classes,isRentACar, filters,showExportImportDialog, handleRemesasMultasCount , handleSelectRemesa,handleCloseExportImportDialog,
    handleDownloadDatos,handleExportPlantillaNotificaciones,handlePrintJustificante,handleTotalRemesasMultasCount
}) => {
    // TAB 1
    const numberElementsPerRemesaPage = 20;
    const numberElementsPerPage = 30;
    
    // Gateways 
    const ioc = useContext(IoC);
    const tramitesG = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);
    const importerG = useMemo(() => ioc.get(ImporterGateway) as ImporterGateway, [ioc]);


    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    const [page, setPage] = useState(0);

    const orderReducer = useReducer(OrderReducer, { orden: 1 });
    const [order, ] = orderReducer;

    const [remesasNoContent, setRemesasNoContent] = useState(false)
    const [multasRemesa, setMultasRemesa] = useState<IRemesaMultas[]>([]);
    const [remesasMultasCount, setRemesasMultasCount] = useState(0);
    const [listaMultaRemesa, setListaMultaRemesa] = useState<IMultas[]>([]);
    const [loadingMultas, setLoadingMultas] = useState(true);
    const [idReciboAIdentificar, setIdReciboAIdentificar] = useState<number | undefined>(undefined);
    
    //Wizard
    const [loadingIdentificacionAccion, setLoadingIdentificacionAccion] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [dataToImport, setDataToImport] = useState<Record<string, any>[] | null>(null);
    const [resultData, setResultData] = useState<IResult[]>([]);
    const [errorsFile, setErrorsFile] = React.useState<IMessageResponse[]>([]);
    const [errorData, setErrorData] = React.useState<string>("");
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [numElementsImported, setNumElementsImported] = useState(1);
    const [importDisabled, setImportDisabled] = useState(false);
    const [listaMultasIdentificadas, setListaMultasIdentificadas] = useState<any[]>();

    const [fileExtension, setFileExtension] = useState<'csv' | 'xlsx'>('xlsx');
    //Memos
    const hasMore = useMemo(() => remesasMultasCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage, [page, remesasMultasCount]);
    const noContent = useMemo(() => !loadingMultas && listaMultaRemesa.length === 0, [loadingMultas, listaMultaRemesa]);
    const hasMoreRemesas = useMemo(() => remesasMultasCount > multasRemesa.length, [multasRemesa, remesasMultasCount]);
    const noContentRemesa = useMemo(() => !loadingMultas && multasRemesa.length == 0, [loadingMultas, multasRemesa]);
    let remesaScrollParentRef: HTMLElement | null;
    let multasRemesaScrollParentRef: HTMLElement | null;

    const buildQueryRequestPorRemesa = useCallback((newFilters: IFiltersState, newOrder: IOrderState, newPage: number, exportData?: boolean) => {
        return Object.assign({}, newFilters, newOrder, {
            skip: exportData ? (0) : (newPage * numberElementsPerRemesaPage),
            take: exportData ? (/*multasCount > 0 ? multasCount :*/ numberElementsPerRemesaPage) : (numberElementsPerRemesaPage)
        });
    }, [remesasMultasCount, numberElementsPerRemesaPage]);

    const buildQueryRequest = useCallback((newFilters: IFiltersState, newOrder: IOrderState, newPage: number, exportData?: boolean) => {
        return Object.assign({}, newFilters, newOrder, {
            skip: exportData ? (0) : (newPage * numberElementsPerPage),
            take: exportData ? (remesasMultasCount > 0 ? remesasMultasCount : numberElementsPerPage) : (numberElementsPerPage)
        });
    }, [remesasMultasCount, numberElementsPerPage]);

    const getListaMultasPorRemesa = async (query?: IFiltersState & IOrderState & { skip: number; take: number; }) => {
        setLoadingMultas(true)
        let queryTemp = buildQueryRequestPorRemesa(filters, order, page);
        if(query){
            queryTemp = query
        }
        const listaMultasPorRemesa = await tramitesG.getMultasIdentificarPorRemesa(queryTemp);
        setListaMultaRemesa(listaMultasPorRemesa);
        setRemesasNoContent(listaMultaRemesa.length === 0)
        setLoadingMultas(false)
    }

    const handleNotAllowedDocuments = useCallback(() => {
        pageDispatcher({
            type: 'show-notification',
            payload: {
                message: translate('Tramites', 'soloPdfs', terms),
                variant: 'warning',
            }
        });
    }, [pageDispatcher, terms]);

    const handleLoadMultas = useCallback(async (newFilters: IFiltersState, newOrder:IOrderState, newPage: number, activeTab: number = 0) => {
        try {
            setRemesasNoContent(false);
            setLoadingMultas(true);
            const query = buildQueryRequestPorRemesa(newFilters, newOrder, newPage);
            const multasPorRemesa = await tramitesG.getRemesasdeMultas(query);
            const multPorRem = newPage === 0 ? multasPorRemesa : multasRemesa.concat(multasPorRemesa)
            setMultasRemesa(multPorRem);
            handleTotalRemesasMultasCount(multPorRem.length)
            if (query['idRemesa'] && multasPorRemesa.length == 1) {
                getListaMultasPorRemesa(query);
            }
            setLoadingMultas(false);
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        }
    }, [buildQueryRequestPorRemesa, tramitesG, multasRemesa,  pageDispatcher]); //buildQueryRequestPorRemesa, multasRemesa,

    const handleLoadMoreMultas = useCallback(() => {
        if (hasMore && !loadingMultas) {
            setPage(page + 1);
            handleLoadMultas(filters, order, page + 1, 1);
        }
    }, [hasMore, loadingMultas, page, handleLoadMultas, filters, order]);

    const handleLoadMoreRemesasMultas = useCallback(() => {
        if (hasMoreRemesas && !loadingMultas) {
            setPage(page + 1);
            handleLoadMultas(filters, order, page + 1, 1);
        }
    }, [hasMoreRemesas, loadingMultas, page, handleLoadMultas, filters, order]);

    const handleIdentificar = useCallback((multa: IMultas) => {
        const multasIdentificadas = listaMultaRemesa.filter(x => x.idIdentificacionWeb !== multa.idIdentificacionWeb);
        setListaMultaRemesa(multasIdentificadas); 
        setRemesasMultasCount(multasIdentificadas.length);
    }, [listaMultaRemesa]);


    // Dialog
    const handleChangeFileExtension = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileExtension((event.target as HTMLInputElement).value as 'csv' | 'xlsx');
    };

    //Wizard
    const handlePrevious = React.useCallback(() => {
        setErrorsFile([]);
        setDataToImport(null);
    }, []);

    const handleNew = React.useCallback(() => {
        setErrorsFile([]);
        setDataToImport(null);
        setHasErrors(false);
        setFile(null);
        setNumElementsImported(1);
    }, []);

    const handleClose = React.useCallback(() => {
        setErrorsFile([]);
        setDataToImport(null);
        setOpen(false);
        setHasErrors(false);
        setFile(null);
        setNumElementsImported(1);
    }, []);

    const handleUpload = React.useCallback(async (file: File) => {
        try {
            setLoadingUpload(true);
            setFile(file);
            const result = await importerG.importXLSX(file, 'conductor');
            if (result && result.data && result.data.length > 0) {
                setDataToImport(result.data);
                setResultData((result.data as any[])
                    .filter(item => !item.errors || item.errors.length === 0)
                    .map(item => ({
                        id: item.idIdentificacionWeb,
                        boletin: item.boletin,
                        refExpediente: item.refExpediente,
                        matricula: item.matricula,
                        nif: item.nif,
                        nombre: item.nombre,
                        apellidos: item.apellidos,
                        result: null,
                    })
                    ));
                setHasErrors((result.data as any[]).some(item => item.errors && item.errors.length > 0));
                setErrorData("");
            } else if (result && result.errors.length > 0) {
                setErrorsFile(result.errors);
            }
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        } finally {
            setLoadingUpload(false);
        }
    }, []);

    const handleIdentificarMultas = useCallback((multasIdentificadas: number[]) => {
        const matchingMultas = listaMultaRemesa.filter(m => {
            return !multasIdentificadas.some(id => m.idIdentificacionWeb === id)
        });
        setListaMultaRemesa(matchingMultas);
    }, [listaMultaRemesa, remesasMultasCount]);

    const handleImport = React.useCallback(async () => {
        try {
            if (!dataToImport || !file) {
                return;
            }
            setImportDisabled(true);
            const idDocumento = await importerG.saveExcel(file);
            if (idDocumento) {
                const multasIdentificadas: number[] = [];
                const listMultasIdent: any[] = [];
                let newResultData = resultData;
                let newNumElementsImported = numElementsImported;
                for (const data of dataToImport) {
                    const resultToIdentificar = resultData.find(item => item.id === data.idIdentificacionWeb);
                    if (resultToIdentificar) {
                        const result = await importerG.identificarConductor(data, idDocumento);
                        newResultData = newResultData.map(item => {
                            if (item.id === resultToIdentificar.id) {
                                return { ...item, result };
                            }

                            return item;
                        });
                        newNumElementsImported++;
                        setNumElementsImported(newNumElementsImported);
                        setResultData(newResultData);
                        multasIdentificadas.push(parseInt(data.idIdentificacionWeb, 10));
                        listMultasIdent.push({
                            idRecibo: listaMultaRemesa.filter((multa) => multa.idIdentificacionWeb === data.idIdentificacionWebinfoDispatchgetMultasIdentificarPorRemesa)[0]?.idRecibo,
                            idIdentificacionWeb: parseInt(data.idIdentificacionWeb, 10)
                        })
                    }
                }
                const result = await tramitesG.asociarJustificanteMasivoARecibos(listMultasIdent);
                setListaMultasIdentificadas(listMultasIdent);
                handleIdentificarMultas(multasIdentificadas);
            }
        } catch (error) {
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        } finally {
            setImportDisabled(false);
        }
    }, [dataToImport, file, importerG, pageDispatcher, resultData]);

    const handlePrint = async() => {
       const done =  await handlePrintJustificante(listaMultasIdentificadas)
        setOpen(!done)
    }

    //Effects
    useEffect(() => {
        
        const asyncEffect = async () => {
            await handleLoadMultas(filters,order,0,1)
        };
        asyncEffect();
    }, []);


    useEffect(() => {
        (async () => {
            setMultasRemesa([]);
            setListaMultaRemesa([]);
            setRemesasMultasCount(0);
            setPage(0);
            await handleLoadMultas(filters,order,0,1)
            const query: any = buildQueryRequest(filters, order, 0);
            delete query.skip;
            delete query.take;
            const countResult = await tramitesG.getCountRemesasDeMulta(query);
            setRemesasMultasCount(countResult);
            handleRemesasMultasCount(countResult);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, order]);

    return (
        <>
            <div>
                <NoContent
                    image={image}
                    visible={noContentRemesa}
                    component='Tramites'
                    text={translate('Tramites','no_remesas_multas_identificables', terms)}
                />
                {
                    multasRemesa.length > 0 ?
                        <>
                            <div style={{ marginTop: '20px', height: '200px', overflowX: 'hidden', overflowY: 'auto', WebkitScrollSnapType: 'none' }} ref={(ref) => remesaScrollParentRef = ref}>
                                <InfiniteScroll
                                    pageStart={0}
                                    initialLoad={false}
                                    loadMore={handleLoadMoreRemesasMultas}
                                    hasMore={hasMoreRemesas}
                                    useWindow={false}
                                    getScrollParent={() => remesaScrollParentRef}
                                    loader={<div key="infiniteProgress" style={{ display: hasMoreRemesas ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress /></div>}
                                >
                                    <>
                                        {multasRemesa.map((multaRemesa: IRemesaMultas, index: number) => (
                                            <MultaRemesaCard multaRemesa={multaRemesa} handleSelectRemesa={(multaRemesa: IRemesaMultas) => handleSelectRemesa(multaRemesa)}></MultaRemesaCard>
                                        ))}
                                    </>
                                </InfiniteScroll>
                            </div>
                            {filters && filters['idRemesa'] && listaMultaRemesa && listaMultaRemesa.length > 0 ?
                                <>
                                    <div style={{ marginTop: '40px', borderBottom: '#888 0.2em solid', color: '#888', fontWeight: 500, scrollbarWidth: 'none' }}>
                                        <Term component="Tramites" text="SancionesRemesa" />
                                    </div>
                                    <div style={{ marginTop: '10px', height: '45vh', overflowX: 'hidden', overflowY: 'auto' }} ref={(ref) => multasRemesaScrollParentRef = ref}>
                                        <InfiniteScroll
                                            pageStart={0}
                                            initialLoad={false}
                                            loadMore={handleLoadMoreMultas}
                                            hasMore={hasMore}
                                            getScrollParent={() => multasRemesaScrollParentRef}
                                            loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.progressContainer}><CircularProgress /></div>}
                                        >
                                            {listaMultaRemesa.map((multa: IMultas, index: number) => (
                                                <MultaCard
                                                    key={'Multa_' + index}
                                                    isRemesa={true}
                                                    handleIdentificar={handleIdentificar}
                                                    multa={multa}
                                                    isRentACar={isRentACar}
                                                    handleNotAllowedDocuments={handleNotAllowedDocuments}
                                                    idReciboAIdentificar={idReciboAIdentificar}
                                                />
                                            ))}
                                        </InfiniteScroll>
                                    </div>
                                </>
                                : filters && filters['idRemesa'] && (!listaMultaRemesa || (listaMultaRemesa && listaMultaRemesa.length == 0)) ?
                                    <div key="progress" className={classes.progressContainer}>
                                    {
                                        !remesasNoContent 
                                        ? <CircularProgress />
                                        : null
                                    }
                                    </div>
                                    : null
                            }
                        </>
                        : noContent
                            ? null
                            : <div key="progress" className={classes.progressContainer}>
                                <CircularProgress />
                            </div>
                }

            </div>
            
            <Dialog
                open={showExportImportDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.identificacionDialog}
            >
                <DialogTitle
                    id="responsive-dialog-title"
                    style={{ marginTop: 20 }}
                    className={classes.content}
                >
                    <span className={classes.title}>
                        <Term component="Tramites" text="TitleIdentificaciónMasiva" />
                    </span>
                </DialogTitle>
                <DialogContent className={classes.content}>
                <div>
                    <span><Term component="Tramites" text={'TextoIdentificacionMasiva'} /></span>
                </div>
                <div className={classes.actionsContainer}>
                    {
                        loadingIdentificacionAccion ?
                            <div className={classes.centerContent}>
                                <CircularProgress size={75} />
                            </div>
                            : 
                            <div className={classes.flexColumn}>
                                {/* Buttons export - import documents */}
                                <div className={classes.flexRow}>
                                    <div className={[classes.actionContent, classes.flexColumn].join(' ')}>
                                        <Button onClick={async() => {
                                                setLoadingIdentificacionAccion(true)
                                                await handleDownloadDatos()
                                                setLoadingIdentificacionAccion(false)
                                            }}
                                            variant='outlined'
                                        >
                                            <Icon path={mdiFileDownload}
                                                className={classes.actionIcon}
                                            />
                                            <span><Term component="Tramites" text={'DesacargarDatosAyuda'} /></span>
                                        </Button>
                                    </div>
                                    <div className={[classes.actionContent, classes.border, classes.flexColumn].join(' ')}>
                                        <Button onClick={async() => {
                                                setLoadingIdentificacionAccion(true)
                                                await handleExportPlantillaNotificaciones(fileExtension)
                                                setLoadingIdentificacionAccion(false)
                                            }}
                                        >
                                            <Icon path={mdiFileExportOutline}
                                                className={classes.actionIcon}
                                            />
                                            <span><Term component="Tramites" text={'ExportarPlantillaIdentificaciones'} /></span>
                                        </Button>

                                        {/* Radio buttons file type */}
                                        <FormControl className={[classes.formControlRadioBtns, classes.flexRow, classes.alignCenter, classes.justifyCenter].join(' ')}>
                                            <FormLabel id="demo-radio-buttons-group-label">
                                                <Term component="Tramites" text={'Format'} />
                                            </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                defaultValue="xlsx"
                                                value={fileExtension}
                                                onChange={handleChangeFileExtension}
                                                className={classes.flexRow}
                                            >
                                                <FormControlLabel value={FILE_EXTENSION_XLSX} control={<Radio color='primary' />} label=".xlsx" />
                                                <FormControlLabel value={FILE_EXTENSION_CSV} control={<Radio color='primary' />} label=".csv" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className={[classes.actionContent, classes.flexColumn].join(' ')}>
                                        <Button onClick={() => setOpen(true)} variant='outlined'>
                                            <Icon path={mdiFileImportOutline}
                                                className={classes.actionIcon}
                                            />
                                            <span><Term component="Tramites" text={'ImportarIdentificaciones'} /></span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                    } 
                </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button
                        onClick={async() => {
                            setLoadingIdentificacionAccion(true)
                            await handleCloseExportImportDialog()
                            setLoadingIdentificacionAccion(false)
                        }}
                        color="primary"
                        autoFocus
                        style={{ width: "100px" }}
                        variant='outlined'
                    >
                        <Term component={"Global"} text={"Cerrar"} />
                    </Button>
                </DialogActions>
            </Dialog>

            <WizardImporter
                open={open}
                title={translate('Wizard', 'identificacion_masiva', terms)}
                showIcon={true}
                dataElement={dataToImport ? <TableWizard data={dataToImport} hiddenCells={['idPais', 'idPoblacion']} /> : null}
                dataElementResult={resultData ? <TableWizardResult data={resultData} numElementsImported={numElementsImported} /> : null}
                disabledStep1={(errorsFile && errorsFile.length > 0) || !dataToImport}
                loadingUpload={loadingUpload}
                hasErrors={hasErrors}
                importDisabled={importDisabled}
                errorsFile={errorsFile}
                errorData={errorData}
                onUpload={handleUpload}
                onSubmit={handleImport}
                onPrevious={handlePrevious}
                onNew={handleNew}
                onClose={handleClose}
                onPrintJustificante={handlePrint} 
            />
        </>
    )
}

export default Remesas