import {FC} from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import ProtectedRoute from "components/protected-route";

import DUTIAlta from "./DUTI-alta";
import DUTIConsulta from "./DUTI-consulta";
import { withLiterals } from "containers/shared/literals";
import DUTIConsultaAction from "./DUTI-consulta-action";
import Contenido from "components/contenido";
import { mdiFileAccount } from "@mdi/js";
import DUTIVueltaEntidad from "./DUTI-vuelta-entidad";
import ContenidoWithCarousel from "components/contenido-with-carousel";
import DUTIHome from "./DUTI-home";

const DUTIIndex : FC<RouteComponentProps> = ({match}) => {
    return (
        <Switch> {/** Switch:  solo busca hasta que uno cumple exactPath - El orden  de las rutas puede afectar */}  
            <Route exact path={`${match.path}/home`} component={DUTIHome} accessLevel={0}/>
            <ProtectedRoute exact path={`${match.path}/alta`} component={DUTIAlta} accessLevel={2} idModulos={[21]}/>
            <ProtectedRoute exact path={`${match.path}/consulta`} component={DUTIConsulta} accessLevel={2} idModulos={[22]} /> 
            <ProtectedRoute exact path={`${match.path}/consulta/action`} component={DUTIConsultaAction} accessLevel={2} idModulos={[22]} /> 
            <ProtectedRoute exact path={`${match.path}/vueltaEntidad`} component={DUTIVueltaEntidad} accessLevel={2} idModulos={[22]} /> 
            <Route exact path={`${match.path}/contenidos`} render={(props) => <ContenidoWithCarousel landingName='mas_informacion_tramite_dusti' icon={mdiFileAccount}  {...props}/>} />



            {/* Por si se debe extraer como un Item de menú independiente
                <Route exact path={`${match.path}`} component={DUTIHome}  />             
            */}
        </Switch>
    );

}

export default withRouter(withLiterals(['Global', 'DUTI', 'PasarelaPago'])(DUTIIndex))