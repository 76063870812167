import {FC, useContext, useEffect, useMemo, useRef, useState} from "react";
import { LiteralsContext } from "containers/shared/literals";
import  DUTIGateway  from "gateways/duti.gateway";
import { ContenidosGateway } from "gateways/contenido.gateway";
import { IDUTIMunicipio } from "gateways/duti.interface";
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";
import {  mdiAccountQuestion, mdiAlertCircleOutline } from "@mdi/js";
import usePage from "hooks/page.hook";
import Icon from "@mdi/react";
import { translate } from "utils/i18n";
import { REGEX_REF_CATASTRAL_1, REGEX_REF_CATASTRAL_2, REGEX_REF_CATASTRAL_3, REGEX_REF_CATASTRAL_DISEMINADOS, TSelectDUTI, formatSelectDUTIOptions } from "../utils";
import duti_styles from "../styles";
import { getFormattedInfoCatastral } from "./formatters";
import { 
    Button, 
    FormHelperText,  
    Grid,  
    Typography, 
    makeStyles 
} from "@material-ui/core";
import { 
    EMPTY_DT_PREVIOS, 
    EMPTY_MUNIGEST, 
    EMPTY_TRAMITES_GEST, 
    TCausaDatosTransmPrevios, 
    TDTPrevios, 
    TMuniGest,  
    TRepresentante,  
    TTipoConceptoGestion,   
    TTipoTransmitente, 
    TTramitesGest, 
    fieldActuaNombrePropio_OPTIONS, 
    fieldSiNo_OPTIONS, 
    fieldTipoTransmitente_OPTIONS 
} from "./types";
import LabelWithInfo from "components/DUTI/label-with-info";
import CheckBox from "components/DUTI/check-box";
import BooleanRadioButton from "components/DUTI/boolean-radio-button";
import RadioButton from "components/DUTI/radio-button";
import InputSelect from "components/DUTI/input-select";
import FullLoading from "components/full-loading";
import InmuebleInfo from "components/DUTI/inmueble-info";
import InputSearch from "components/DUTI/input-search";
import ModalTramitesGest from "components/DUTI/modal-tramites-gest";
import { BLUE } from "containers/shared/colors";
import { TExtraValidation } from "components/DUTI/types";
import { THeaderStepper } from "components/DUTI/stepper-duti";
import { CustomError } from "utils/custom.error";
import { validateRefCatastral } from "utils/validators/referencia-catastral";



const useStyles = makeStyles(duti_styles);

interface IDTPRevios {
    initialDTPrevios: TDTPrevios,
    //setChanges: () => void,
    //setDTPrevios: (v:TDTPrevios) => void,
    handleContinuar: (previos: TDTPrevios, haveChanges:boolean,notClean: boolean) => void,
    handleCancelDUTI: (cause: TCausaDatosTransmPrevios, previos: TDTPrevios) => void,
    updateStepperRefCat: (v: THeaderStepper) => void,
    setShouldBlock: (v: boolean) => void,
}
const DatosTransmisionPrevios: FC<IDTPRevios> = ({ initialDTPrevios,/*setChanges, DTPrevios, setDTPrevios,*/ handleContinuar, handleCancelDUTI, updateStepperRefCat, setShouldBlock }) => {
    const terms = useContext(LiteralsContext);
    const classes= useStyles();
    const [pageState, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);

    const [DTPrevios, setDTPrevios] = useState<TDTPrevios>({} as TDTPrevios)
    
    //Gateways
    const ioc = useContext(IoC);
    const dutiGateway: DUTIGateway = useMemo(() => ioc.get(DUTIGateway), [ioc]);
    const contenidoGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);
    
    
    const [loading, setLoading] = useState(false);
    //const [showInfoModal, setShowInfoModal]  = useState(false);
    // config Trámites
    const [showTramitesModal, setShowTramitesModal]  = useState(false);
   
    const [loadMunigest, setLoadMuniGest] = useState(false);
    const [errActuoNombrePropio, setErrActuoNombrePropio] = useState(false);
    const [municipiosALL, setMunicipiosALL]= useState<IDUTIMunicipio[]>([]);
    const [municipios, setMunicipios]= useState<TSelectDUTI[]>([]);
    const [MSGModal, setMSGModal] = useState<Partial<Record<TTipoConceptoGestion | 'complemtaria' | 'plenoDominio' |'notSameMuni' |'notTramites', string>>>({
        DUSTI: "",
        IBI: "",
        PLUSVAL:"",
        TASAS_MUNI:"", 
        complemtaria:"",
        plenoDominio: "",
        notSameMuni: "",
        notTramites: ""
    })
    const [ msgInfoRepresentar, setMsgInfoRepresentar] = useState('')
    const [ checkRepresentacion,setCheckRepresentacion ] = useState(false)
    const [ representante, setRepresentante] = useState<TRepresentante| undefined>(undefined)

    const [localRef, setLocalRef] = useState<string| undefined>(undefined);
    const [showMsgRefCatastralNotFound,setShowMsgRefCatastralNotFound] = useState(false);
    const componentMounted = useRef(true);
    const [haveChanges, setHaveChanges] = useState(false);
    const [notTramites, setNotTramites] = useState(false);
    const [defaultMuniTrmaiteGest, setDefaultMuniTramiteGest] = useState({...EMPTY_TRAMITES_GEST})

    const [showModalUnicaRefCat, setShowModalUnicaRefCat]  = useState(true);
    // Motivos que nos llevan a finalizar el proceso
    /** EndDUTI[0] - DUSTI no permitida par ael Municipio 
     *  EndDUTI[1] - Municipios distintos
     *  EndDUTI[0] - No es transmisión pleno dominio
    */
    const CauseCancel : TCausaDatosTransmPrevios[]= ['municipio-seleccionado','refCat-diff-muni', 'no-pleno-dominio', 'no-tramites'] // 'es-complemntaria',
    const EndDUTI : boolean[]= useMemo(() =>{ 
        return ([
            (DTPrevios.municipio!== undefined && !loadMunigest && (DTPrevios.muniGest.DUSTI === false || DTPrevios.muniGest.PLUSVAL === false)) ? true : false,
            (DTPrevios.infoCatastral && 
                DTPrevios.infoCatastral.refCatastral!== undefined 
                && DTPrevios.municipio!== undefined  
                && DTPrevios.infoCatastral.direccion !== undefined 
                && !(DTPrevios.infoCatastral.direccion.normalize('NFD').replace(/[\u0300-\u036f]/g,"").includes(DTPrevios.municipio.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g,"")))
            ) ? true : false,
            (DTPrevios.esPlenoDominio!== undefined && DTPrevios.esPlenoDominio === false),
        ])
    }, [DTPrevios, loadMunigest]);

  
    // Funciones

    const getAllMuniContents= async (p_municipio:string) => {
        //console.log('p_municipio', p_municipio)
        const duti = await contenidoGateway.getContent('info_modal_GestRec_noATIB',{muni: p_municipio})
        const plusval = await contenidoGateway.getContent('info_modal_NoGest_Plusvalia',{muni: p_municipio})
        const ibi = await contenidoGateway.getContent('info_modal_NoGest_IBI',{muni: p_municipio})
        const tasas = await contenidoGateway.getContent('info_modal_NoGest_TasaMunicipales',{muni: p_municipio})
        const difMuni= await contenidoGateway.getContent('info_modal_DifMuni_dusti_refCatastral',{muni: p_municipio})
        setMSGModal(prev => ({
            ...prev,
            DUSTI: duti[0].contenido,
            IBI: ibi[0].contenido,
            PLUSVAL:plusval[0].contenido,
            TASASMUNI: tasas[0].contenido,
            notSameMuni: difMuni[0].contenido
        }))
    }

    const getMuniGest = async (muni: TSelectDUTI| undefined ) => {
        setLoadMuniGest(true)
        if(muni === undefined){ 
            setDTPrevios({...EMPTY_DT_PREVIOS, municipio: undefined, muniGest: EMPTY_MUNIGEST}) 
            setDefaultMuniTramiteGest({...EMPTY_TRAMITES_GEST})
        } else {
            const muniInfo = municipiosALL.find(m => m.id === muni.id)
            const gestInfo: TMuniGest = muniInfo 
                ? { 
                    DUSTI: muniInfo.DUSTI, 
                    PLUSVAL: muniInfo.PLUSVAL, 
                    IBI: muniInfo.IBI, 
                    TASAS_MUNI: muniInfo.TASAS_MUNI,
                    COM_DOMFISC: muniInfo.COM_DOMFISC,
                    MODELO600: muniInfo.MODELO600,
                } 
                : EMPTY_MUNIGEST;

            setDTPrevios({...EMPTY_DT_PREVIOS, 
                municipio: muni, 
                muniGest: gestInfo,
                tramitesGest: {
                    ...EMPTY_TRAMITES_GEST,
                    DUSTI: gestInfo.DUSTI, 
                    IBI: gestInfo.IBI, 
                    TASAS_MUNI: gestInfo.TASAS_MUNI,
                    COM_DOMFISC: gestInfo.COM_DOMFISC
                },
                infoCatastral: undefined
             
            }) 
            setDefaultMuniTramiteGest({
                ...EMPTY_TRAMITES_GEST,
                DUSTI: gestInfo.DUSTI, 
                IBI: gestInfo.IBI, 
                TASAS_MUNI: gestInfo.TASAS_MUNI,
                COM_DOMFISC: gestInfo.COM_DOMFISC
            })

            await getAllMuniContents(muni.nombre);
            
            
        }
        setLoadMuniGest(false)
    }

    const getInfoCatastro = async (refCatastral: string | undefined ) => {
        try{
            setLoading(true);
            //console.log('getInfoCatastro => ', refCatastral)
            const clearInfo: TDTPrevios =  {
                ...DTPrevios, 
                infoCatastral: undefined,
                actuaNombrePropio: undefined,
                tipoTransmitente: undefined,
                esPlenoDominio: undefined,  
                tramitesGest: {...defaultMuniTrmaiteGest}             
            }
            if(refCatastral === undefined || refCatastral === ''){ 
                setDTPrevios({...clearInfo})
                setLocalRef(undefined)
            } else {
                try {
                    const info = await dutiGateway.getInfoRefCatastral(refCatastral);
                    if(info && info.ok){
                        const formattedInfo = info && info.ok && info.inmueble ? getFormattedInfoCatastral(refCatastral, info.inmueble): null
                        //console.log('info', info)
                        if(formattedInfo){
                            setDTPrevios({
                                ...DTPrevios, 
                                infoCatastral: {
                                    refCatastral: formattedInfo.refCatastral,
                                    tipoBien: formattedInfo.tipoBien,
                                    direccion: formattedInfo.direccion,
                                    datosEconomicos: formattedInfo.datosEconomicos ? {...formattedInfo.datosEconomicos} : undefined,
                                    urlCatastro: formattedInfo.urlCatastro,
                                    urlGoogle: formattedInfo.urlGoogle
                                },
                                actuaNombrePropio: undefined,
                                tipoTransmitente: undefined,
                                esPlenoDominio: undefined,
                                tramitesGest: {...defaultMuniTrmaiteGest}
                            })
                            setCheckRepresentacion(false);
                            updateStepperRefCat({
                                refCat: formattedInfo.refCatastral ?? ' - ',
                                dir: formattedInfo.direccion ?? ' - ',
                                urlCatastro: formattedInfo.urlCatastro ?? ' - ',
                                muni: DTPrevios.municipio?.code
                            })
                        } else {
                            setDTPrevios({...clearInfo});
                            setLocalRef(undefined);
                            setCheckRepresentacion(false);
                            setShowMsgRefCatastralNotFound(true);
                        }
                    } else {
                        setDTPrevios({...clearInfo});
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message:  translate('DUTI', info.error && info.error.codigo === "9999" ? 'error_pinbal' : (info.error && info.error.codigo === "0017" ? "DT_RefCatastro_field_notFound" : "error_get_refCatastral"), terms),
                                variant: 'error',
                            }
                        });
                    }
                } catch (error) {
                    if (error instanceof CustomError && error.code === 403) {
                        setShouldBlock(false);
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('Global', 'sesion_caducada', terms),
                                variant: 'warning',
                                hasCustomAction: true,
                                handleCustomAction: () => { 
                                    pageDispatcher({ type: "logout" });
                                }
                            }
                        });
                    } else {
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                message: translate('DUTI','error_dusti', terms),
                                variant: "error"
                            }
                        });
                    }
                }
            }
            setLoading(false);
        } catch(err){
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('DUTI', 'error_get_refCatastral', terms),
                    variant: 'error',
                }
            })
            
        }
        
    }

    const onSaveTramitesGest=(newTGest: TTramitesGest, mhaveChanges: boolean) => {
        if(newTGest.PLUSVAL===false && newTGest.MODELO600 === false && !newTGest.IBI && !newTGest.COM_DOMFISC && !newTGest.TASAS_MUNI){
            setNotTramites(true)
            setHaveChanges(mhaveChanges||haveChanges)
            setShowTramitesModal(false)
        } else {
            handleContinuar({...DTPrevios, tramitesGest:{...newTGest}}, mhaveChanges||haveChanges, false)
        }
        
    };

    const validateRefCat = (ref: string) => {
        const res = validateRefCatastral(ref)
        return({error: res.error, error_msg: translate(res.component_ig,res.error_msg_term, terms)} as TExtraValidation)
    }
    

    const onValidateAndContinue = async () => {
        let err = false
        // Validar campos requeridos
        if( DTPrevios.infoCatastral === undefined || 
            ( DTPrevios.infoCatastral && (
                DTPrevios.infoCatastral.refCatastral === '' || 
                DTPrevios.infoCatastral.datosEconomicos === undefined || 
                DTPrevios.infoCatastral.direccion === undefined || DTPrevios.infoCatastral.direccion === '' ||
                DTPrevios.infoCatastral.tipoBien === undefined )
            )
        ){
            err=true
        }
        // Validar que hay representante
        //DTPrevios', DTPrevios, checkRepresentacion)
        if(DTPrevios.actuaNombrePropio === false && ( DTPrevios.representante === undefined || checkRepresentacion !== true)){
            err=true
            setErrActuoNombrePropio(err)
        } 
        //console.log('DTPrevios', DTPrevios, checkRepresentacion)
        if (!err) {
            //console.log('show modal ')
            setShowTramitesModal(true) 
            setNotTramites(false)
        }
    }



    useEffect(() => {
        // inicializamos componente
        (async () => {
            setLoading(true)

            try {
                //initialDTPrevios', initialDTPrevios)
                setDTPrevios(curr => ({...initialDTPrevios}))
                const compl_msg = await contenidoGateway.getContent('info_modal_declar_complementaria', {})
                const plenoDom_msg = await contenidoGateway.getContent('info_modal_no_pleno_dominio', {})
                const info_represent_msg = await contenidoGateway.getContent('info_modal_representar', {})
                const notTramites_msg = await contenidoGateway.getContent('info_modal_not_tramites', {})
                const unicaRefCat_msg = await contenidoGateway.getContent('info_modal_duti_unica_refCat', {})
                const optionsMunicipios= await dutiGateway.getAllMunicipios(); // Baleares
                if(initialDTPrevios.municipio?.nombre){
                    await getAllMuniContents(initialDTPrevios.municipio?.nombre)
                }
                if(componentMounted.current){
                    setMSGModal(prev => ({...prev, 
                        complemtaria: compl_msg[0].contenido, 
                        plenoDominio: plenoDom_msg[0].contenido,
                        notTramites: notTramites_msg[0].contenido,
                    }));
                    setMsgInfoRepresentar(info_represent_msg[0].contenido);
                    
                    setMunicipiosALL(optionsMunicipios);
                    setMunicipios(formatSelectDUTIOptions<IDUTIMunicipio>('id','Codigo','Nombre',optionsMunicipios)) ;
                    if(initialDTPrevios.representante && !initialDTPrevios.actuaNombrePropio){
                        setCheckRepresentacion(true);
                    }
                    setHaveChanges(false);      
                    // Aviso 1 DUTI por cada ref.Catastral
                    if(showModalUnicaRefCat){
                        alertsDispatch({
                            type: 'show-alert',
                            payload: {
                                isHtml: true,
                                message: unicaRefCat_msg[0].contenido ?? '',
                                variant: "warning"
                            }
                        });
                        setShowModalUnicaRefCat(false);
                    }          
                }
                
            } catch (error) {
                if (error instanceof CustomError && error.code === 403) {
                    setShouldBlock(false);
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Global', 'sesion_caducada', terms),
                            variant: 'warning',
                            hasCustomAction: true,
                            handleCustomAction: () => { 
                                pageDispatcher({ type: "logout" });
                            }
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('DUTI','error_dusti', terms),
                            variant: "error"
                        }
                    });
                }
            } finally {
                setLoading(false);
                
            }
        })();
        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // set it to false when we leave the page
        }
    }, [])

    useEffect(() => {

        (async () => {
            if(pageState.jwp && pageState.jwp.nifRepresentante){
                if(!representante || (representante && representante.nif !== pageState.jwp.nifRepresentante)){
                    const suj = await dutiGateway.getSujeto(pageState.jwp.nifRepresentante)
                    if (suj) {
                        setRepresentante({
                            contacto: suj.contacto,
                            direccion: suj.direccion,
                            nif: suj.nif,
                            nomApe: suj.nomApe,
                            personaFisica: suj.personaFisica
                        })
                    }
                }
            }
        })();

    }, [pageState.jwp ]);


    
    return (
        <Grid container direction='column' className={classes.componenteContainer}>
            <FullLoading loading={ loading || loadMunigest } animation={ loading ? {type:'intricom', name:'DUSTI'}: undefined} />
            
            <Grid item container direction='column' className={classes.columnContainer}>
                {/* <LabelWithInfo type="title" fieldTerm="DT_IntroduccionDatosDUTI_title" /> */}
                <div style={{flex:1, flexGrow:1, overflowY: 'auto', marginTop:10}}>
                    <Grid item container >
                        <Grid item xs={12} md={12} lg={5} container direction='column'>
                            <LabelWithInfo  fieldTerm="DT_DatosPrevio_municipio_title"  moreInfo= {{template: 'info_title_DT_DatosPrevio_municipio_title', size: 'xs'}}/>
                            <InputSelect 
                                fieldTerm='municipio'
                                value={DTPrevios.municipio}
                                options={municipios}
                                onChangeValue={(v: TSelectDUTI | undefined,err: boolean) => { 
                                    setLoadMuniGest(true)
                                    getMuniGest(v)
                                    setHaveChanges(true)
                                    setNotTramites(false)
                                }}
                                required
                            />
                            {DTPrevios.municipio && !EndDUTI[0] &&
                                <>
                                    <LabelWithInfo fieldTerm={'refCatastral_label'} moreInfo={{template: "info_field_refCatastral", size: 'xs'}} />
                                    <InputSearch 
                                        fieldTerm='refCatastral' 
                                        required
                                        value={localRef ?? DTPrevios.infoCatastral?.refCatastral} 
                                        onChangeValue={(ref:string) =>{ setLocalRef(ref); setShowMsgRefCatastralNotFound(false);}}
                                        onSearch={() =>{ 
                                            getInfoCatastro(localRef)
                                            setHaveChanges(true)
                                            setNotTramites(false)
                                        }}
                                        extraValidation={validateRefCat}
                                        helperText={translate('DUTI','helper_text_recCatastral',terms)}
                                    />
                                    {showMsgRefCatastralNotFound && 
                                        <FormHelperText error>{translate('DUTI','DT_RefCatastro_field_notFound', terms)}</FormHelperText>
                                    }
                                </>
                            }
                        </Grid>
                        <Grid item xs={12} md={12} lg={7} container direction="column" style={{flex:1, alignItems:'flex-end'}}>
                            
                            { DTPrevios.municipio && !EndDUTI[0] && 
                                DTPrevios.infoCatastral && DTPrevios.infoCatastral.refCatastral !== undefined && DTPrevios.infoCatastral.datosEconomicos &&  
                                <InmuebleInfo 
                                    refCatastral={DTPrevios.infoCatastral?.refCatastral} 
                                    urlCatastro={DTPrevios.infoCatastral?.urlCatastro}
                                    urlGoogle={DTPrevios.infoCatastral?.urlGoogle}
                                    domicilio={DTPrevios.infoCatastral?.direccion}
                                    tipoBien={DTPrevios.infoCatastral?.tipoBien}
                                    uso={DTPrevios.infoCatastral?.datosEconomicos.uso}
            
                                />
                            }
                        </Grid>
                    </Grid>
                    {DTPrevios.infoCatastral && DTPrevios.infoCatastral?.refCatastral && !EndDUTI[1] &&
                        <Grid item>
                        <BooleanRadioButton key='pleno-dominio' row
                            options={fieldSiNo_OPTIONS} 
                            fieldTerm="esPlenoDominio"
                            value={DTPrevios.esPlenoDominio}
                            onChangeValue= { (v: boolean) =>{ 
                                setDTPrevios(curr => ({
                                    ...DTPrevios, 
                                    esPlenoDominio: v, 
                                    actuaNombrePropio: undefined, 
                                    tipoTransmitente: undefined
                                })) 
                                setHaveChanges(true)
                                setCheckRepresentacion(false)
                                setNotTramites(false)
                            }}
                            moreInfo= {{template: 'info_field_esPlenoDominio', size: 'xs'}}
                        />
                    </Grid>
                    }
                    {DTPrevios.esPlenoDominio === true && !EndDUTI[2] &&
                        <Grid item container direction="column">
                            <BooleanRadioButton key='actuoNomProp' row
                                options={fieldActuaNombrePropio_OPTIONS} 
                                fieldTerm="actuaNombrePropio"
                                value={DTPrevios.actuaNombrePropio}
                                onChangeValue = { (v: boolean) => {
                                    setErrActuoNombrePropio(false)
                                    setDTPrevios(curr => ({
                                        ...DTPrevios, 
                                        actuaNombrePropio: v,
                                        nifSujeto: pageState.jwp?.nif,
                                        representante: v===false && representante ? {...representante} : undefined,
                                        //representante: v===false ? InfoRepresentante.fullnameRepresentante : undefined,
                                    }))
                                    setHaveChanges(true)
                                    setNotTramites(false)
                                }}
                                moreInfo= {{template: 'info_field_actuaNombrePropio', size: 'xs'}}
                            />
                            
                            {DTPrevios.actuaNombrePropio !== undefined && DTPrevios.actuaNombrePropio === false &&
                                <div className={classes.textWarningAsCancelDusti}>
                                    <div style={{marginRight: 20}}>
                                        <Icon path={mdiAccountQuestion} color={BLUE} style={{alignSelf:'flex-start'}} size={3} />
                                    </div>
                                    { DTPrevios.representante?.nif === undefined ?
                                        <div style={{display: 'flex', flexDirection:'column', }}>
                                            <Typography style={{fontWeight: 'bold'}} color={errActuoNombrePropio ? "error": "inherit"}>
                                                {translate('DUTI', 'actuaNombrePropio_errRepre', terms)} 
                                            </Typography>
                                                <div dangerouslySetInnerHTML={{ __html: msgInfoRepresentar }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                                        </div>
                                    :
                                        <CheckBox 
                                            value={checkRepresentacion} 
                                            onChangeValue={(v: boolean) => { 
                                                setCheckRepresentacion(p => v); 
                                                setErrActuoNombrePropio(false)
                                                setNotTramites(false)
                                            } } 
                                            fieldTerm="checkRepresentacion" 
                                            error={errActuoNombrePropio}
                                            errorMsg={translate('DUTI', 'confirm_required', terms)}    
                                            addToLabel={`: ${pageState.jwp?.fullname} - ${pageState.jwp?.nif}`}
                                            style={{height: 20}}
                                        />                                
                                    }
                                    
                                </div>
                                    
                            }
                            
                        </Grid>
                    }

                    { DTPrevios.esPlenoDominio && (
                        DTPrevios.actuaNombrePropio !== undefined && ( DTPrevios.actuaNombrePropio || (DTPrevios.actuaNombrePropio === false && DTPrevios.representante?.nif))
                    ) && 
                        <RadioButton key='ttransmitente'  row
                            options={fieldTipoTransmitente_OPTIONS} 
                            fieldTerm="tipoTransmitente"
                            value={DTPrevios.tipoTransmitente}
                            onChangeValue = {(v: TTipoTransmitente) =>{ 
                                setDTPrevios(curr => ( {...DTPrevios, tipoTransmitente: v}))
                                setHaveChanges(true)
                                setNotTramites(false)
                            }}
                            // moreInfo= {{template: 'info_field_tipoTransmitente', size: 'xs'}}
                        />
                    }

                    {( EndDUTI.includes(true) || notTramites) && 
                        <div className={classes.textCancelDusti}>
                            <div style={{marginRight: 10}}>
                                <Icon path={mdiAlertCircleOutline} color={'#ef1111'} style={{alignSelf:'flex-start'}} size={4} />
                            </div>

                            {EndDUTI[0] === true && 
                                <>
                                { DTPrevios.muniGest.DUSTI === false &&
                                    <div dangerouslySetInnerHTML={{ __html:  MSGModal.DUSTI ?? 'Oops ! Lost MSG_DUSTI'}} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                                }
                                { DTPrevios.muniGest.PLUSVAL === false && DTPrevios.muniGest.DUSTI === true &&
                                    <div dangerouslySetInnerHTML={{ __html:  MSGModal.PLUSVAL ?? 'Oops ! Lost MSG_PLUSVAL'}} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                                }
                                </>
                            }
                            {EndDUTI[1] === true &&
                                <div dangerouslySetInnerHTML={{ __html: MSGModal.notSameMuni ?? 'Oops ! Lost MSG_notSameMuni'}} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                            }
                            {EndDUTI[2] === true &&
                                <div dangerouslySetInnerHTML={{ __html: MSGModal.plenoDominio ?? 'Oops ! Lost MSG_complemtaria' }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
                            }
                            {notTramites && <div dangerouslySetInnerHTML={{ __html: MSGModal.notTramites ?? 'Oops ! Lost MSG_notTramites' }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>}
                        </div>
                    }  
 
                </div>     
            </Grid>

            <Grid item container direction="row" justify="flex-end" alignItems="flex-end" className={classes.footerColumnContainer}>
                {/** Mensajes causa FIN dusti */}
                {(EndDUTI.includes(true) || notTramites ) &&  
                    <div style={{alignSelf:'flex-end'}}>
                        <Button variant="contained" 
                            className={classes.btnCancelDusti}
                            onClick={() =>{ 
                                const idx= EndDUTI.includes(true)  ? EndDUTI.findIndex(v => v === true) : (CauseCancel.length-1)
                                handleCancelDUTI(CauseCancel[idx], {...DTPrevios})
                            }} 
                            
                        >
                            {translate('DUTI', 'btn_exit_duti',terms)}
                        </Button>
                    </div>
                }
                { DTPrevios.tipoTransmitente !== undefined && !EndDUTI.includes(true)
                    && DTPrevios.actuaNombrePropio !== undefined && ( DTPrevios.actuaNombrePropio || (DTPrevios.actuaNombrePropio === false && DTPrevios.representante)) 
                    &&
                    <Button variant="contained" onClick={onValidateAndContinue} color="primary" style={{marginLeft: 10}}>
                        {translate('DUTI', 'btn_continuar',terms)}
                    </Button>
                }
            </Grid>


            { showTramitesModal && DTPrevios.municipio && DTPrevios.nifSujeto
                && DTPrevios.actuaNombrePropio !== undefined && DTPrevios.tipoTransmitente && DTPrevios.infoCatastral && 
                <ModalTramitesGest
                    show={showTramitesModal}
                    data={DTPrevios.tramitesGest}
                    muni={ DTPrevios.municipio}
                    actuaNombrePropio= {DTPrevios.actuaNombrePropio}
                    tipoTransmitente= {DTPrevios.tipoTransmitente}
                    infoCatastral={DTPrevios.infoCatastral}
                    nifSuj={DTPrevios.nifSujeto}
                    saveData={onSaveTramitesGest}
                    cancel={() => setShowTramitesModal(false)}
   
                />
            }

        </Grid>
    )
}

export default DatosTransmisionPrevios;



/** Mensajes alertas  según Municipio */
/* 
<Dialog open={showInfoModal}>
    <DialogTitle>
        <div style={{display: 'inline-flex', alignItems: 'center'}}>
            <Typography style={{fontSize: 32, color: "#2196f3",fontWeight: "lighter", fontFamily: 'Roboto'}}>{translate('DUTI', 'attention', terms)}</Typography>
            <Icon path={mdiAlertBoxOutline}
                size={1.6}
                color="#2196f3"
                style={{marginLeft: 10}}
            />
        </div>
    </DialogTitle> 
    {DTPrevios.municipio &&  
        <DialogContent>
            //----------------
            {// {!DTPrevios.muniGest.PLUSVAL && MSGModal.PLUSVAL && 
                <div dangerouslySetInnerHTML={{ __html: MSGModal.PLUSVAL }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
            } 
            {!DTPrevios.muniGest.PLUSVAL && !DTPrevios.muniGest.IBI && <Divider style={{color: "#2196f3", marginBottom: 10}}/>}
            //}
            //----------------
            { !DTPrevios.muniGest.IBI && 
                <div dangerouslySetInnerHTML={{ __html: MSGModal.IBI ??  'Oops ! Lost MSG_IBI' }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
            }
            {(!DTPrevios.muniGest.PLUSVAL || !DTPrevios.muniGest.IBI ) && !DTPrevios.muniGest.TASAS_MUNI && <Divider style={{background: "#2196f3", marginBottom: 15}}/>}
            { !DTPrevios.muniGest.TASAS_MUNI && 
                <div dangerouslySetInnerHTML={{ __html: MSGModal.TASAS_MUNI ?? 'Oops ! Lost MSG_TASAS_MUNI' }} style={{ textAlign: "justify", textJustify: "inter-word" }}></div>
            }
        </DialogContent>
    }
    <DialogActions>
        <div style={{display: 'flex',justifyContent:'flex-end', paddingRight: 10, paddingBottom: 10}}>
            <Button variant="contained" onClick={() => setShowInfoModal(false)} color="primary">
                {translate('DUTI', 'btn_close',terms)}
            </Button>
        </div>
    </DialogActions>
</Dialog> 
*/

