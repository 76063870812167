import React, { FC, useContext, useState, useEffect, useMemo } from 'react';
import { Chip, makeStyles } from '@material-ui/core';

import { LiteralsContext } from '../../shared/literals';
import { FiltersContext } from './filters.context';
import { FilterDataContext } from './filter-data.context';
import { IFiltersState } from './filters.reducer';
import { dateFormat } from 'utils/dates';
import { translate } from 'utils/i18n';

const useStyles = makeStyles((theme) => ({
    chip: {
        marginTop: 5,
        marginRight: 2,
        marginLeft: 2
    },
    filterList: {
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        paddingTop: 5,
        paddingBottom: 15
    }
}));

interface IFiltersActiveProps {
    onChange: (query: IFiltersState) => void;
}

const FiltersActive: FC<IFiltersActiveProps> = ({ onChange }) => {
    const terms = useContext(LiteralsContext);
    const filterData = useContext(FilterDataContext);
    const [allFilters, setAllFilters] = useState<Array<{ type: keyof IFiltersState, value: string | number, label: string }>>([]);
    const anyFilters = useMemo(() => allFilters.length > 0, [allFilters]);
    const [filters, filtersDispatch] = useContext(FiltersContext);
    const classes = useStyles();
    const tiposDeFecha = ['fechaLimiteDesde', 'fechaLimiteHasta', 'fechaMultaDesde', 'fechaMultaHasta'];

    useEffect(() => {
        let allFiltersAux = ([] as Array<{ type: keyof IFiltersState, value: string | number, label: string }>);

        for (const filterKey in filters) {
            if (filterKey in filters) {
                const filtersProp = (filters as any)[filterKey];
                // console.log("filterprops", filtersProp, "filterKey", filterKey, "filters", filters)

                if (filtersProp) {
                    switch (filterKey) {
                        case 'matricula':
                            allFiltersAux.push({
                                type: filterKey as any,
                                value: filtersProp,
                                label: 'Matricula ' + filtersProp
                            });
                            break;
                        case 'gravedad':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: 'Gravedad ' + (filterData.gravedad ? filterData.gravedad.filter(y => y.value === x).map((y) => y.label) || x : x),
                            })));
                            break;
                        case 'municipios':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: 'Municipio ' + (filterData.municipios ? filterData.municipios.filter(y => y.value === x).map((y) => y.label) || x : x)
                            })));
                            break;
                        case 'estadoIdentificacion':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: translate("Global",'EstadoIdentificacion',terms)+' ' + (filterData.estadoIdentificacion ? filterData.estadoIdentificacion.filter(y => y.value === x).map((y) => y.label) || x : x)
                            })));
                            break;
                        case 'estado':
                            allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                type: filterKey,
                                value: x,
                                label: 'Estado ' + (filterData.estado ? filterData.estado.filter(y => y.value === x).map((y) => y.label) || x : x)
                            })));
                            break;
                        case 'fechaRemesa':
                            allFiltersAux.push({
                                type: filterKey as any,
                                value: dateFormat(filtersProp),
                                label: dateFormat(filtersProp)
                            });
                            break;
                        case 'fechaLimiteDesde':
                            allFiltersAux.push({
                                type: filterKey as any,
                                value: dateFormat(filtersProp),
                                label: 'Fecha Límite Desde ' + dateFormat(filtersProp)
                            });
                            break;
                        case 'fechaLimiteHasta':
                            allFiltersAux.push({
                                type: filterKey as any,
                                value: dateFormat(filtersProp),
                                label: 'Fecha Límite Hasta ' + dateFormat(filtersProp)
                            });
                            break;
                        case 'fechaMultaDesde':
                            allFiltersAux.push({
                                type: filterKey as any,
                                value: dateFormat(filtersProp),
                                label: 'Fecha Multa Desde ' + dateFormat(filtersProp)
                            });
                            break;
                        case 'fechaMultaHasta':
                            allFiltersAux.push({
                                type: filterKey as any,
                                value: dateFormat(filtersProp),
                                label: 'Fecha Multa Hasta ' + dateFormat(filtersProp)
                            });
                            break;
                        default:
                            if (filtersProp instanceof Array) {
                                allFiltersAux = allFiltersAux.concat(filtersProp.map((x: any) => ({
                                    type: filterKey as any,
                                    value: x,
                                    label: x
                                })));
                            } else {
                                allFiltersAux.push({
                                    type: filterKey as any,
                                    value: filtersProp,
                                    label: filtersProp
                                });
                            }
                            break;
                    }
                }
            }
        }

        setAllFilters(allFiltersAux);
    }, [filterData.gravedad, filterData.municipios, filterData.estado, filters, terms]);

    const handleDelete = (filter: { type: keyof IFiltersState, value: string | number }) => () => {
        const currentFilterValues: any = (filters as any)[filter.type];
        let valueToAssign: any = filter.value;

        if (currentFilterValues instanceof Array) {
            valueToAssign = (currentFilterValues as any[]).filter(x => x !== filter.value);
            filtersDispatch({ type: 'update', filter: filter.type, value: valueToAssign });
        }
        else if (tiposDeFecha.includes(filter.type)) { 
            filtersDispatch({ type: 'update', filter: filter.type, value: null });
        }
        else {
            valueToAssign = undefined;
            filtersDispatch({ type: 'update', filter: filter.type, value: undefined });
        }

        const newFilters = { ...filters };
        newFilters[filter.type] = valueToAssign;
        onChange(newFilters);
    };

    return anyFilters ? (
        <div className={classes.filterList}>
            {
                allFilters.map(x => (
                    <Chip
                        key={x.value}
                        label={x.label}
                        onDelete={handleDelete(x)}
                        className={classes.chip}
                    />
                ))
            }
        </div>
    ) : null;
}

export default FiltersActive;